import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { LinkButton } from "components/LinkButton";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { useState } from "react";

export function Surgery({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, ,] = useField<PatientEncounterData["surgery"]>({
    name: name.patientEncounterData("surgery"),
  });

  const [hasValue, setHasValue] = useState(() => !!field.value.instructions);

  return (
    <div>
      {!hasValue && (
        <LinkButton
          icon={faPlus}
          text={translate("__addSurgery")}
          onClick={() => {
            setHasValue(true);
          }}
        />
      )}

      {(hasValue || field.value.instructions) && (
        <Field name={name.surgery("instructions", true)}>
          <Label>{translate("__surgeryInstructions")}</Label>
          <TextAreaFormikField autoFocus />
        </Field>
      )}
    </div>
  );
}

import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { MedicalHistoryChoice } from "api/types/medicalHistoryChoice";
import { PatientMedicalHistory } from "api/types/patientMedicalHistory";
import { PatientMedicalHistoryData } from "api/types/patientMedicalHistoryData";
import { Pharmacy } from "api/types/pharmacy";

export const name = {
  pharmacy(key: keyof Pharmacy) {
    return `${nameof<PatientMedicalHistory>("data")}.${nameof<PatientMedicalHistoryData>("pharmacy")}.${key}`;
  },

  medicalHistoryData(medicalHistoryDataKey: keyof PatientMedicalHistoryData) {
    return `${nameof<PatientMedicalHistory>("data")}.${medicalHistoryDataKey}`;
  },

  medicalHistory(medicalHistoryKey: keyof PatientMedicalHistory) {
    return `${medicalHistoryKey}`;
  },

  medicalHistoryChoice(key: keyof MedicalHistoryChoice) {
    return key;
  },
};

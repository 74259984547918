import { PatientRecord } from "./PatientRecord";
import { PatientInfo } from "./PatientInfo";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { EncounterDetails } from "./encounterDetails";

import { useData } from "./useData";

import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { EncountersList } from "./EncountersList";
import { Card } from "components/Card";
import { Results } from "./Results";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { ClosedEncounter } from "./ClosedEncounter";
export function PatientDetails() {
  const data = useData();
  const { translate } = useTranslation();
  if (!data.user) {
    return <ViewPortLoading />;
  }

  return (
    <>
      <PatientInfo patient={data.user} />
      <div className="row">
        <div className="col-lg-2">
          <Accordion className="mt-3" alwaysOpen defaultActiveKey={["PatientRecord"]}>
            <Accordion.Item eventKey="PatientRecord">
              <Accordion.Header>{translate("__patientRecord")}</Accordion.Header>
              <Accordion.Body>
                <PatientRecord
                  medicalHistory={data.medicalHistory}
                  medicalHistoryConfig={data.medicalHistoryConfig}
                  patient={data.user}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="Results">
              <Accordion.Header>{translate("__results")}</Accordion.Header>
              <Accordion.Body>
                <Results results={data.results} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="EncounterList">
              <Accordion.Header>{translate("__encounters")}</Accordion.Header>
              <Accordion.Body>
                <EncountersList data={data} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-lg-10">
          {!isDefined(data.selectedEncounter) && (
            <Card>
              <div className="text-center">{translate("__pleaseSelectEncounterToView")}</div>
            </Card>
          )}
          {data.selectedEncounter?.closed === true && (
            <ClosedEncounter encounter={data.selectedEncounter} data={data} />
          )}
          {data.selectedEncounter?.closed === false && <EncounterDetails data={data} />}
        </div>
      </div>
    </>
  );
}

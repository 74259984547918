import { s1Date } from "@sprint1/pkg/src/date";
import { dateOfBirth } from "@sprint1/pkg/src/date/dateOfBirth";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Loading } from "@sprint1/pkg/src/loading";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { useGetDiagnoses } from "api/client/configuration/getDiagnoses";
import { useGetPatientEncounter } from "api/client/patientEncounter/getPatientEncounter";
import { ImagingTypeHelpers } from "api/types/orderType.helper";
import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { RefillQuantityHelpers } from "api/types/refillQuantity.helpers";
import React from "react";
import { routes } from "routes/routes.config";
import { ProviderInfo } from "components/ProviderInfo";

export function PrintOrders() {
  const data = useData();
  const { translate } = useTranslation();
  const encounter = data.encounterApi.patientEncounter;
  const diagnoses = data.diagnosesApi.diagnoses;
  return (
    <div>
      <div className="container d-print-none my-2">
        <div className="text-end">
          <Loading inline show={data.encounterApi.isRunning} />

          <a className="btn btn-sm btn-outline-primary mx-1" href="/">
            {translate("__home")}
          </a>
          <button
            type="button"
            className="btn btn-sm btn-primary mx-1"
            onClick={() => {
              window.print();
            }}
          >
            {translate("__print")}
          </button>
        </div>
      </div>
      {encounter && (
        <>
          {encounter.data.orders.map((order, index) => {
            return (
              <div
                id="print-content"
                className="container p-3 border my-3"
                key={`imagings-${index}`}
                style={{ pageBreakInside: "avoid" }}
              >
                <ProviderInfo encounter={encounter} />
                <PatientInfo encounter={encounter} />
                <Main>
                  <pre className="mt-4 mx-4">
                    <div>{getDiagnosis(order.icd10Codes, diagnoses)}</div>
                    <div className="mt-2">
                      <div>
                        {ImagingTypeHelpers.toString({
                          type: order.type,
                          otherName: order.other,
                          translate,
                        })}
                      </div>
                      <div className="px-2">
                        <pre>{order.instructions}</pre>
                      </div>
                    </div>
                  </pre>
                </Main>
                <Signature encounter={encounter} />
              </div>
            );
          })}

          {encounter.data.prescriptions.map((prescription, index) => {
            return (
              <div
                id="print-content"
                className="container p-3 border my-3"
                key={`prescription-${index}`}
                style={{ pageBreakInside: "avoid" }}
              >
                <ProviderInfo encounter={encounter} />
                <PatientInfo encounter={encounter} />
                <Main>
                  <pre className="mt-4 mx-4">
                    <div>
                      <span>{translate("__medication/Strength")}: </span>
                      {prescription.medication}
                    </div>
                    <div>
                      <span>{translate("__SIG")}: </span>
                      {prescription.sig}
                    </div>
                    <div>
                      <span>{translate("__dispense")}: </span>
                      {prescription.dispense}
                    </div>
                  </pre>
                </Main>
                <Signature encounter={encounter} refillCount={RefillQuantityHelpers.toNumber(prescription.refill)} />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

function useData() {
  const toast = useToast();
  const encounterApi = useGetPatientEncounter();
  const diagnosesApi = useGetDiagnoses();
  useRunOnMount(() => {
    async function load() {
      const qs = routes.printRoutes.orders.getQueryStrings();
      if (!qs.hasAllRequiredQs) {
        toast.error({ type: "BadUrl" });
        return;
      }
      const { data: patientEncounter } = await encounterApi.getPatientEncounter({
        patientEncounterId: qs.patientEncounterId,
      });
      await diagnosesApi.getDiagnoses({
        request: {
          icd10Codes: patientEncounter.data.diagnoses.map((d) => d.icd10Code),
        },
      });
    }
    load();
  });

  return { encounterApi, diagnosesApi };
}

function getDiagnosis(icd10Codes: string[] | undefined, diagnoses: MdOrthoDiagnosis[] | undefined): string {
  if (!isDefined(icd10Codes) || !isDefined(diagnoses)) {
    return "";
  }

  for (const icd10code of icd10Codes) {
    const matching = diagnoses.find((d) => d.icd10Codes.includes(icd10code));
    if (matching) {
      const icd = matching.icd10Codes.join(", ");
      return `${icd} ${matching.diagnosis}`;
    }
  }

  return "";
}

function Signature({ encounter, refillCount }: { encounter: MdOrthoPatientEncounter; refillCount?: number }) {
  return (
    <div className="row">
      <div className="col-7 d-flex flex-column-reverse">
        <div>
          <span className="mx-1">Refill</span>
          <span className="d-inline-block mx-1 border-bottom border-dark text-center" style={{ minWidth: "3rem" }}>
            {refillCount}
          </span>
          <span className="mx-1">times</span>
        </div>
      </div>
      <div className="col-5 text-center">
        <div style={{ minHeight: "5rem" }}>
          {encounter.providerDigitalSignatureUrl && (
            <img
              src={encounter.providerDigitalSignatureUrl}
              alt={"signature"}
              style={{
                width: "20rem",
                height: "5rem",
                objectFit: "scale-down",
              }}
            />
          )}
        </div>
        <div className="pt-2 border-top border-dark">Signature</div>
        <div className="pt-2 fw-bold fs-4 border-bottom border-dark">
          {encounter.providerPrefix && <span className="">{encounter.providerPrefix}</span>}
          {encounter.providerFirstName && <span className="ms-1">{encounter.providerFirstName}</span>}
          {encounter.providerMiddleName && <span className="ms-1">{encounter.providerMiddleName}</span>}
          {encounter.providerLastName && <span className="ms-1">{encounter.providerLastName}</span>}
          {encounter.providerSuffix && <span className="ms-1">{encounter.providerSuffix}</span>}
        </div>
        <div className="">Printed name</div>
      </div>
    </div>
  );
}

function Main({ children }: { children?: React.ReactNode }) {
  return (
    <div className="my-2" style={{ minHeight: "8rem" }}>
      {children}
    </div>
  );
}

function PatientInfo({ encounter }: { encounter: MdOrthoPatientEncounter }) {
  return (
    <>
      <div className="row py-2">
        <div className="col-9">
          <div className="d-flex">
            <div className="fw-bold">Name</div>
            <div className="mx-2 border-bottom border-dark w-100">
              {encounter.patientFirstName && <span className="ms-1">{encounter.patientFirstName}</span>}
              {encounter.patientLastName && <span className="ms-1">{encounter.patientLastName}</span>}
            </div>
          </div>
        </div>

        <div className="col-3">
          <div className="d-flex">
            <div className="fw-bold">Date</div>
            <div className="mx-2 border-bottom border-dark w-100">
              {s1Date.format.tryFormatDate(encounter.startTime)}
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-6">
          <div className="d-flex">
            <div className="fw-bold">DOB</div>
            <div className="mx-2 border-bottom border-dark flex-grow-1">
              {dateOfBirth.tryFormat(encounter.patientDateOfBirth)}
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="d-flex">
            <div className="fw-bold">Phone</div>
            <div className="mx-2 border-bottom border-dark w-100">{encounter.patientPhoneNumber}</div>
          </div>
        </div>
      </div>
    </>
  );
}

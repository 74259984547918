export function VideoPlayer({ videoId }: { videoId: string }) {
  return (
    <div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?h=971f5f13ea&title=0&byline=0&portrait=0&color=022B40`}
          title="video"
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

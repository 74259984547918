import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useState } from "react";

import { Field } from "@sprint1/pkg/src/form/field";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { Label } from "@sprint1/pkg/src/form/label";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useRoutes } from "@sprint1/pkg/src/router/useRoutes";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useCreateUser } from "api/client/associatedUser/createUser";
import { updateUser } from "api/client/associatedUser/updateUser";
import { searchUsers } from "api/client/user/searchUsers";
import { userFilters } from "api/helpers/searchUsers.helper";
import { CreateMdOrthoAssociatedUserRequest } from "api/types/createMdOrthoAssociatedUserRequest";
import { UpdateMdOrthoAssociatedUserRequest } from "api/types/updateMdOrthoAssociatedUserRequest";
import { RolesEnum } from "common/roles";
import { Breadcrumb } from "components/Breadcrumb";
import { Footer } from "components/Footer";
import { Form, Formik } from "formik";
import { routes } from "routes/routes.config";
import { getBreadCrumbs } from "./helpers";

export function EditAssociatedUser() {
  const { translate } = useTranslation();
  const { go } = useRoutes();
  const data = useData();

  const title = data.editUser ? translate("__editUser") : translate("__addUser");
  return (
    <div>
      <PageLayout breadcrumb={<Breadcrumb crumbs={getBreadCrumbs()} current={translate("__editUser")} />}>
        <PageTitleSimple title={title} left={title} />

        {!data.newUser && !data.editUser ? (
          <ViewPortLoading />
        ) : (
          <Formik
            initialValues={data.newUser ? data.newUser : data.editUser ? data.editUser : {}}
            onSubmit={async (userInForm) => {
              if (data.newUser) {
                await data.createUserApi.createUser({
                  request: { ...(userInForm as CreateMdOrthoAssociatedUserRequest), roles: [RolesEnum.Patient] },
                });
              } else if (data.editUser) {
                await updateUser({ request: userInForm as UpdateMdOrthoAssociatedUserRequest });
              }
              const url = routes.admin.users.list.get(data.clientId!, "");
              go(url);
            }}
          >
            <Form noValidate>
              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <Field name={name("firstName")} isRequired>
                    <Label>{translate("__firstName")}</Label>
                    <S1InputFormikField type="text" autoFocus />
                  </Field>
                  <Field name={name("lastName")} isRequired>
                    <Label>{translate("__lastName")}</Label>
                    <S1InputFormikField type="text" />
                  </Field>

                  <Field name={name("dateOfBirth")} isRequired>
                    <Label>{translate("__dob")}</Label>
                    <S1InputFormikField type="date" />
                  </Field>
                  <Footer
                    variant="backAndSave"
                    saveButtonProps={{
                      showSuccess: data.createUserApi.isSuccess,
                      showSpinner: data.createUserApi.isRunning,
                      disabled: data.createUserApi.isRunning,
                    }}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </PageLayout>
    </div>
  );
}

const name = nameof<CreateMdOrthoAssociatedUserRequest>;

function useData() {
  const createUserApi = useCreateUser();
  const [newUser, setNewUser] = useState<CreateMdOrthoAssociatedUserRequest>();
  const [editUser, setEditUser] = useState<UpdateMdOrthoAssociatedUserRequest>();
  const [clientId, setClientId] = useState<string>();

  const toast = useToast();

  useRunOnMount(() => {
    async function load() {
      const url = getUrlState();
      if (url.mode === "add") {
        setNewUser({
          firstName: "",
          lastName: "",
          parentUserId: url.parentUserId,
          roles: [RolesEnum.Patient],
          dateOfBirth: "",
          tenantId: url.clientId,
        });
        setClientId(url.clientId);
      } else if (url.mode === "edit") {
        setClientId(url.clientId);
        const { data } = await searchUsers({ request: userFilters.searchUser(url.userId) });
        if (!!data.results?.length) {
          const user = data.results[0];
          setEditUser({
            firstName: user.firstName ?? "",
            id: user.id,
            lastName: user.lastName ?? "",
            roles: user.roles,
            dateOfBirth: user.dateOfBirth,
          });
        }
      } else {
        toast.error({ type: "BadUrl" });
      }
    }
    load();
  });

  return { newUser, editUser, createUserApi, clientId };
}

function getUrlState() {
  const userId = getQueryStringValue("userId");
  const parentUserId = getQueryStringValue("parentUserId");
  const clientId = getQueryStringValue("clientId");
  if (!!userId && !!clientId) {
    return { mode: "edit", userId, clientId } as const;
  } else if (!!parentUserId && !!clientId) {
    return { mode: "add", parentUserId, clientId } as const;
  } else {
    return { mode: "invalidUrl" } as const;
  }
}

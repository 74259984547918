import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { EncounterConfigurationViewModel } from "api/types/encounterConfigurationViewModel";

export function getEncounterViewModel(arg: GetEncounterViewModelArgType = {}) {
  //__queryString__
  return fetchJson<EncounterConfigurationViewModel>(`/api/Configuration/EncounterViewModel`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetEncounterViewModel(arg?: UseGetEncounterViewModelArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getEncounterViewModel(arg) : undefined,
  });

  const getEncounterViewModelCb = useCallback(
    (arg: GetEncounterViewModelArgType = {}) => {
      return run(() => getEncounterViewModel(arg));
    },
    [run]
  );
  return {
    ...rest,
    getEncounterViewModel: getEncounterViewModelCb,
    encounterViewModel: rest?.fetchJsonResponse?.data,
  };
}

export interface GetEncounterViewModelArgType {
  //__ArgTypeBody__
}

export type UseGetEncounterViewModelArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetEncounterViewModelArgType);


import { TranslateFnType } from "@sprint1/pkg/src/i18n/useTranslation";
import { BodyPart } from "api/types/bodyPart";
import { InjuryType } from "api/types/injuryType";
import { PatientInjury } from "api/types/patientInjury";
import { ReactNode } from "react";

export function getInjuryDate(translate: TranslateFnType, injury?: PatientInjury): ReactNode | undefined {
  if (injury?.followupVisit) {
    return translate("__VisitType_Followup");
  }

  if (injury?.injuryType === InjuryType.NoInjury) {
    return translate("__VisitType_NewProblem_NoInjury");
  }
  if (injury?.injuryType === InjuryType.Acute) {
    return translate("__VisitType_NewInjury_Within6Weeks");
  }
  if (injury?.injuryType === InjuryType.Chronic) {
    return translate("__VisitType_OldInjury_Over6Weeks");
  }
}

export function getBodyPartText(translate: TranslateFnType, bodyParts?: BodyPart[], injury?: PatientInjury) {
  const matching = bodyParts?.find((b) => b.id === injury?.bodyPartId);
  return matching?.name ?? translate("__unknownBodyPart");
}

import { ReactNode } from "react";

export interface CardType {
  padding?: string;
  margin?: string;
  classNames?: string;
  children: ReactNode;
}

export function Card({
  children,
  padding = "p-2",
  margin = "my-2",
  classNames = "",
}: CardType) {
  return (
    <div className={`bg-white rounded ${padding} ${margin} ${classNames}`}>
      {children}
    </div>
  );
}

import { useEffect } from "react";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { ReferralPartner } from "api/types/referralPartner";
import { useSearchReferralPartners } from "api/client/referralPartner/searchReferralPartners";
import QRCode from "qrcode";

const name = nameof<ReferralPartner>;

export function ReferralPartnerList() {
  const { translate } = useTranslation();
  const data = useData();

  return (
    <PageLayout>
      <PageTitleSimple
        title={translate("__referralPartners")}
        left={translate("__referralPartners")}
        right={
          <>
            <a className="btn btn-sm btn-outline-primary" href={routes.admin.referralPartners.edit.url().fullUrl}>
              {translate("__addNewReferralPartner")}
            </a>
          </>
        }
      />
      <Table data={data} />
    </PageLayout>
  );
}

function useData() {
  const filtersAndSort = useFiltersAndSort({
    filterFields: [],
    sortFields: [{ field: name("name"), ascending: true }],
  });
  const partnersApi = useSearchReferralPartners();

  const { searchReferralPartners } = partnersApi;
  useEffect(() => {
    searchReferralPartners({ request: filtersAndSort.filtersDebounced });
  }, [filtersAndSort.filtersDebounced, searchReferralPartners]);

  return { partnersApi, filtersAndSort };
}
type UseDateReturnType = ReturnType<typeof useData>;

function Table({ data }: { data: UseDateReturnType }) {
  const { translate } = useTranslation();

  const { filtersAndSort } = data;
  const partners = data.partnersApi.referralPartners;

  async function downloadQRCode(partnerName: string, referralPartnerId: string) {
    const url = routes.patient.selfPay.landing.url(referralPartnerId, true);
    const qrCode = await QRCode.toDataURL(url);
    const img = new Image();
    img.onload = () => {
      const downloadLink = document.createElement("a");
      downloadLink.download = `QRCode-${partnerName}`;
      downloadLink.href = qrCode;
      downloadLink.click();
    };
    img.src = qrCode;
  }

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              width="25%"
              label={translate("__name")}
              filter={<TextFilter name={name("name")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {partners &&
            partners.results.map((partner, index) => {
              return (
                <tr key={`tr-${index}-${partner.name}-${partner.id}`}>
                  <td>{partner.name}</td>

                  <td className="text-end">
                    <ActionMenuSimple>
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={routes.admin.referralPartners.edit.url(partner.id).fullUrl}
                      >
                        {translate("__editReferralPartner")}
                      </ActionMenuSimple.MenuItem>

                      <ActionMenuSimple.MenuItem onClick={() => downloadQRCode(partner.name, partner.id)}>
                        {translate("__downloadQRCode")}
                      </ActionMenuSimple.MenuItem>
                    </ActionMenuSimple>
                  </td>
                </tr>
              );
            })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { S1Table } from "@sprint1/pkg/src/table/Components";

import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";

import { searchUsers } from "api/client/user/searchUsers";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { useEffect, useState } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { Breadcrumb } from "components/Breadcrumb";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { BooleanFilter } from "@sprint1/pkg/src/table/filters/BooleanFilter";

const nameOfUser = nameof<MdOrthoUser>;

export function UsersList() {
  const { translate } = useTranslation();
  const clientName = getQueryStringValue("clientName");
  const clientId = getQueryStringValue("clientId");
  const toast = useToast();

  useRunOnMount(() => {
    if (!clientId) {
      toast.error(translate("__urlIsInvalidMakeSureYouCopiedWhole"));
    }
  });

  return (
    <PageLayout breadcrumb={<Breadcrumb crumbs={["clients"]} current={translate("__users")} />}>
      <PageTitleSimple
        title={translate("__users")}
        left={
          <>
            <PageTitleSimple.Text title={translate("__users")} />
            {clientName && <PageTitleSimple.SubText subTitle={clientName} />}
          </>
        }
        right={
          clientId && (
            <Link className="btn btn-outline-primary btn-sm" to={routes.admin.users.edit.url({ clientId })}>
              {translate("__addNewUser")}
            </Link>
          )
        }
      />

      {clientId && <Table clientId={clientId} />}
    </PageLayout>
  );
}

function Table({ clientId }: { clientId: string }) {
  const { translate } = useTranslation();
  const { users, filterAndSort } = useData(clientId);

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              label={translate("__firstName")}
              filter={<TextFilter name={nameOfUser("firstName")} filtersAndSort={filterAndSort} />}
            />

            <S1Table.Th
              label={translate("__lastName")}
              filter={<TextFilter name={nameOfUser("lastName")} filtersAndSort={filterAndSort} />}
            />

            <S1Table.Th
              label={translate("__email")}
              filter={<TextFilter name={nameOfUser("email")} filtersAndSort={filterAndSort} />}
            />

            <S1Table.Th
              label={translate("__phone")}
              filter={<TextFilter name={nameOfUser("phoneNumber")} filtersAndSort={filterAndSort} />}
            />

            <S1Table.Th
              width="7rem"
              label={translate("__active")}
              filter={
                <BooleanFilter
                  name={nameOfUser("active")}
                  filtersAndSort={filterAndSort}
                  trueLabel={translate("__active")}
                  falseLabel={translate("__inactive")}
                />
              }
            />

            <S1Table.Th label={translate("__roles")} />

            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {users &&
            users.map((user, index) => {
              return (
                <tr key={`tr-${index}-${user.id}-${user.email}`}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{`${user.active ? translate("__active") : translate("__inactive")}`}</td>
                  <td>{user.roles.join(", ")}</td>

                  <td>
                    <ActionMenuSimple>
                      {!user.parentAspNetUserId && (
                        <ActionMenuSimple.MenuItem
                          as={Link}
                          to={routes.admin.users.edit.url({
                            clientId: getQueryStringValue("clientId") as string,
                            userId: user.id,
                          })}
                        >
                          {translate("__edit")}
                        </ActionMenuSimple.MenuItem>
                      )}
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={
                          user.parentAspNetUserId
                            ? routes.admin.users.associatedUsers.edit.editDependantUser({
                                userId: user.id,
                                clientId: user.tenantId!,
                                clientName: user.tenantName,
                              })
                            : routes.admin.users.associatedUsers.edit.addDependantUser({
                                clientId: user.tenantId!,
                                clientName: user.tenantName,
                                parentUserId: user.id,
                              })
                        }
                      >
                        {user.parentAspNetUserId ? translate("__editDependantUser") : translate("__addDependantUser")}
                      </ActionMenuSimple.MenuItem>
                    </ActionMenuSimple>
                  </td>
                </tr>
              );
            })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

function useData(clientId: string) {
  const [users, setUsers] = useState<MdOrthoUser[]>();
  const filterAndSort = useFiltersAndSort({
    filterFields: [
      { field: nameOfUser("tenantId"), value: clientId },
      { field: nameOfUser("active"), value: "true" },
    ],
    sortFields: [{ field: nameOfUser("lastName"), ascending: true }],
  });

  useEffect(() => {
    async function load() {
      const { data } = await searchUsers({
        request: filterAndSort.filtersDebounced,
      });
      setUsers(data.results);
    }
    load();
  }, [filterAndSort.filtersDebounced]);

  return { users, filterAndSort };
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";




export function disconnectCalendar(arg: DisconnectCalendarArgType = {}) {
  //__queryString__
  return fetchJson<void>(`/api/Cronofy/DisconnectCalendar`, {
    method: "DELETE",
    body: undefined,
  });
}

export function useDisconnectCalendar(arg?: UseDisconnectCalendarArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => disconnectCalendar(arg) : undefined,
  });

  const disconnectCalendarCb = useCallback(
    (arg: DisconnectCalendarArgType = {}) => {
      return run(() => disconnectCalendar(arg));
    },
    [run]
  );
  return {
    ...rest,
    disconnectCalendar: disconnectCalendarCb,
    
  };
}

export interface DisconnectCalendarArgType {
  //__ArgTypeBody__
}

export type UseDisconnectCalendarArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & DisconnectCalendarArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { UpdatePatientEncounterRequest } from "api/types/updatePatientEncounterRequest";

export function updatePatientEncounter(arg: UpdatePatientEncounterArgType) {
  //__queryString__
  return fetchJson<void>(`/api/patient/encounter/Update`, {
    method: "PUT",
    body: arg.request,
  });
}

export function useUpdatePatientEncounter(arg?: UseUpdatePatientEncounterArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => updatePatientEncounter(arg) : undefined,
  });

  const updatePatientEncounterCb = useCallback(
    (arg: UpdatePatientEncounterArgType) => {
      return run(() => updatePatientEncounter(arg));
    },
    [run]
  );
  return {
    ...rest,
    updatePatientEncounter: updatePatientEncounterCb,
    
  };
}

export interface UpdatePatientEncounterArgType {
  request: UpdatePatientEncounterRequest;
}

export type UseUpdatePatientEncounterArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & UpdatePatientEncounterArgType);


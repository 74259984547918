import { UseDataReturnType } from "./useData";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Card } from "components/Card";
import { EncounterPreview } from "./encounterDetails/preview";
import { PageTitleSimple } from "components/PageLayout";
import { WorkersCompBadge } from "../common/WorkersCompBadge";
import { CtaButton } from "components/CtaButton";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { OpenClosedBadge } from "../common/OpenClosedBadge";
import { routes } from "routes/routes.config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";

import { Link } from "react-router-dom";
import { SecondaryButton } from "components/SecondaryButton";

export function ClosedEncounter({ encounter, data }: { encounter: MdOrthoPatientEncounter; data: UseDataReturnType }) {
  const { translate } = useTranslation();

  return (
    <Card>
      <PageTitleSimple
        left={translate("__encounterDetails")}
        right={
          <>
            <WorkersCompBadge show={encounter.workmansComp} />
            <OpenClosedBadge open={!encounter.closed} className="mx-1" />
            <a
              href={routes.printRoutes.orders.url(encounter.id).fullUrl}
              className="mx-1"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faPrint} title={translate("__printOrders")} />
            </a>

            <Link to={routes.careGivers.encounterPrintout.url(encounter.id)} className="ms-1">
              <FontAwesomeIcon icon={faFile} title={translate("__printSummary")} />
            </Link>

            <SecondaryButton
              onClick={async () => {
                if (data.selectedEncounter?.id) {
                  await data.createFollowupEncounter({ currentEncounterId: data.selectedEncounter.id });
                }
              }}
              className="ms-2"
            >
              {translate("__createFollowupEncounter")}
            </SecondaryButton>

            <CtaButton
              size="small"
              type="button"
              className="btn-sm ms-2"
              onClick={() => {
                data.updateEncounter({
                  request: { encounter: { ...encounter, closed: false }, notifyPatient: false, notifyWcAdmin: false },
                });
                data.setSelectedEncounter(encounter);
              }}
              text={translate("__reopenEncounter")}
              showSpinner={data.updateEncounterApi.isRunning}
              disabled={data.updateEncounterApi.isRunning}
            />
          </>
        }
        compact
      />
      <EncounterPreview
        injury={data.injury}
        bodyParts={data.bodyParts}
        questionnaireResponse={data.injuryQuestionnaire}
        patientEncounter={encounter}
        encounterViewModel={data.encounterViewModel}
        diagnoses={data.diagnoses}
      />
    </Card>
  );
}

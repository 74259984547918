import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export function SignUpComplete() {
  const { translate } = useTranslation();

  return (
    <div className="row justify-content-md-center bg-white text-dark my-4 py-4">
      <div className="col-md-4 ">
        <h4 className="text-center">{translate("__thankYouForRegistering")}</h4>
        <div className="text-center pt-3">{translate("__registrationDescription")}</div>
      </div>
    </div>
  );
}

import { UserContextType, useUser } from "@sprint1/pkg/src/auth/user/context";
import { AccessLevel } from "api/types/accessLevel";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { RolesEnum } from "common/roles";
import { useCallback } from "react";

export function useAppUser() {
  const user = useUser();
  const isOnSiteNurse = user?.hasRole(RolesEnum.OnsiteNurse);
  const isMdOrthoNurse = user?.hasRole(RolesEnum.MDOrthoNurse);
  const isPatient = user?.hasRole(RolesEnum.Patient);
  const isAdmin = user?.hasRole(RolesEnum.Administrator);
  const isDoctor = user?.hasRole(RolesEnum.Doctor);
  const mdOrthoUser: MdOrthoUser | undefined = user?.user ? (user.user as unknown as MdOrthoUser) : undefined;

  const hasFullBenefit = mdOrthoUser?.accessLevel === AccessLevel.Full;
  const hasWcOnlyBenefit = mdOrthoUser?.accessLevel === AccessLevel.WorkerCompOnly;

  const { data, setData } = user;
  const setCurrentRole = useCallback(
    (role: RolesEnum) => {
      setData({ ...(data || {}), currentRole: role });
    },
    [data, setData]
  );

  const currentRole = getDefaultCurrentRole(user, data?.currentRole);
  const isCurrentRolePatient = currentRole === RolesEnum.Patient;
  const isCurrentRoleOnSiteNurse = currentRole === RolesEnum.OnsiteNurse;
  const isCurrentRoleAdministrator = currentRole === RolesEnum.Administrator;
  const isCurrentRoleDoctor = currentRole === RolesEnum.Doctor;
  const isCurrentRoleMDOrthoNurse = currentRole === RolesEnum.MDOrthoNurse;

  return {
    ...user,
    mdOrthoUser,
    isAdmin,
    isPatient,
    isOnSiteNurse,
    isDoctor,
    isMdOrthoNurse,
    hasFullBenefit,
    hasWcOnlyBenefit,
    currentRole,
    setCurrentRole,
    isCurrentRolePatient,
    isCurrentRoleOnSiteNurse,
    isCurrentRoleAdministrator,
    isCurrentRoleDoctor,
    isCurrentRoleMDOrthoNurse,
  };
}

function getDefaultCurrentRole(user?: UserContextType, currentRole?: RolesEnum): RolesEnum | undefined {
  if (!user) {
    return undefined;
  }
  if (currentRole && user.hasRole(currentRole)) {
    return currentRole;
  }
  if (user.hasRole(RolesEnum.Doctor)) {
    return RolesEnum.Doctor;
  }

  if (user.hasRole(RolesEnum.MDOrthoNurse)) {
    return RolesEnum.MDOrthoNurse;
  }
  if (user.hasRole(RolesEnum.OnsiteNurse)) {
    return RolesEnum.OnsiteNurse;
  }
  if (user.hasRole(RolesEnum.Patient)) {
    return RolesEnum.Patient;
  }

  if (user.hasRole(RolesEnum.Administrator)) {
    return RolesEnum.Administrator;
  }

  return undefined;
}
export type UseAppUserReturnType = ReturnType<typeof useAppUser>;

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { LinkButton } from "components/LinkButton";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";

export function Equipment({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["equipments"]>({
    name: name.patientEncounterData("equipments"),
  });

  return (
    <div>
      {data.encounterViewModel?.durableMedicalEquipment?.length && (
        <LinkButton
          icon={faPlus}
          text={translate("__addEquipment")}
          onClick={() => {
            if (!data.encounterViewModel) {
              return;
            }
            const tmpValue = [...field.value];
            tmpValue.push({
              id: data.encounterViewModel.durableMedicalEquipment[0].id,
            });
            helpers.setValue(tmpValue);
            helpers.setTouched(true);
          }}
        />
      )}

      {field.value.map((i, index) => {
        return (
          <div className="bg-light m-4 px-4 pt-4 pb-2" key={`imaging-${index}`}>
            <Field name={name.equipments("id", index)}>
              <Label>{translate("__device")}</Label>
              <ReSelectFormikField
                options={(data.encounterViewModel?.durableMedicalEquipment ?? []).map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
              />
            </Field>

            <Field name={name.equipments("instructions", index)}>
              <Label>{translate("__instructions")}</Label>
              <TextAreaFormikField />
            </Field>

            <LinkButton
              icon={faXmark}
              text={translate("__delete")}
              onClick={() => {
                const tmpImagings = field.value.filter((ti) => i !== ti);
                helpers.setValue(tmpImagings);
                helpers.setTouched(true);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

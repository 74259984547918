import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useGetBodyParts } from "api/client/body/getBodyParts";
import { useGetEncounterViewModel } from "api/client/configuration/getEncounterViewModel";
import { useGetMedicalHistoryConfiguration } from "api/client/configuration/getMedicalHistoryConfiguration";
import { useGetInjury } from "api/client/injury/getInjury";
import { useGetInjuryQuestionnaire } from "api/client/injury/getInjuryQuestionnaire";
import { useGetMedicalHistory } from "api/client/patientMedicalHistory/getMedicalHistory";
import { listPatientEncounters } from "api/client/patientEncounter/listPatientEncounters";
import { encounterFilters } from "api/client/patientEncounter/listPatientEncounters.helpers";
import { useUpdatePatientEncounter } from "api/client/patientEncounter/updatePatientEncounter";
import { useSearchUsers } from "api/client/user/searchUsers";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { UpdatePatientEncounterRequest } from "api/types/updatePatientEncounterRequest";
import { useEffect, useState } from "react";
import { routes } from "routes/routes.config";
import { userFilters } from "api/helpers/searchUsers.helper";
import { getPatientResults } from "api/client/patientResult/getPatientResults";
import { MdOrthoPatientResult } from "api/types/mdOrthoPatientResult";
import { useGetDiagnoses } from "api/client/configuration/getDiagnoses";
import { useCreateFollowUp } from "api/client/patientEncounter/createFollowUp";

export function useData() {
  const toast = useToast();
  const [shouldNotifyPatient, setShouldNotifyPatient] = useState(true);
  const [shouldNotifyWcAdmin, setShouldNotifyWcAdmin] = useState(true);
  const { medicalHistoryConfig, bodyParts, encounterViewModel, diagnoses } = useFetchConfig();
  const updateEncounterApi = useUpdatePatientEncounter();
  const createFollowUpApi = useCreateFollowUp();

  const [encounters, setEncounters] = useState<MdOrthoPatientEncounter[]>();
  const [selectedEncounter, setSelectedEncounter] = useState<MdOrthoPatientEncounter>();
  const [results, setResults] = useState<MdOrthoPatientResult[]>([]);

  const { medicalHistory, getMedicalHistory } = useGetMedicalHistory({
    runOnMount: false,
  });
  const { searchUsers, users } = useSearchUsers({ runOnMount: false });
  const { injury, injuryQuestionnaire } = usePatientResponses(selectedEncounter?.patientInjuryId);

  useEffect(() => {
    async function load() {
      if (selectedEncounter) {
        const { data } = await getPatientResults({
          patientId: selectedEncounter.patientId,
        });
        setResults(data);
      }
    }
    load();
  }, [selectedEncounter]);

  useRunOnMount(() => {
    async function load() {
      const qs = routes.careGivers.patientsDetails.getQueryStrings();

      if (!qs.hasAllRequiredQs) {
        toast.error({ type: "BadUrl" });
        return;
      }
      searchUsers({ request: userFilters.searchUser(qs.patientId) });
      getMedicalHistory({ patientId: qs.patientId });
      const { data } = await listPatientEncounters({
        request: encounterFilters.filterByPatient(qs.patientId),
      });
      setEncounters(data.results);
      setSelectedEncounter(getSelectedEncounter(selectedEncounter, data.results));
    }
    load();
  });

  async function refreshEncounters(encounterIdToSelect?: string) {
    const qs = routes.careGivers.patientsDetails.getQueryStrings();

    if (!qs.hasAllRequiredQs) {
      toast.error({ type: "BadUrl" });
      return;
    }

    const { data } = await listPatientEncounters({
      request: encounterFilters.filterByPatient(qs.patientId),
    });
    setEncounters(data.results);
    setSelectedEncounter(
      getSelectedEncounter(encounterIdToSelect ? encounterIdToSelect : selectedEncounter, data.results)
    );
  }

  async function updateEncounter({
    request,
    shouldRefreshEncounters = true,
  }: {
    request: UpdatePatientEncounterRequest;
    shouldRefreshEncounters?: boolean;
  }) {
    await updateEncounterApi.updatePatientEncounter({
      request: request,
    });

    if (shouldRefreshEncounters) {
      await refreshEncounters();
    }
  }

  async function createFollowupEncounter({ currentEncounterId }: { currentEncounterId: string }) {
    const { data } = await createFollowUpApi.createFollowUp({
      request: {
        encounterId: currentEncounterId,
      },
    });

    await refreshEncounters(data.encounterId);
  }

  return {
    bodyParts,
    medicalHistoryConfig,
    encounterViewModel,
    diagnoses,

    user: !!users?.results?.length ? users?.results[0] : undefined,
    medicalHistory,

    encounters,

    selectedEncounter,
    setSelectedEncounter,
    results,

    injury,
    injuryQuestionnaire,

    shouldNotifyPatient,
    setShouldNotifyPatient,
    shouldNotifyWcAdmin,
    setShouldNotifyWcAdmin,
    refreshEncounters,
    updateEncounter,
    updateEncounterApi,
    createFollowupEncounter,
  };
}

function useFetchConfig() {
  const { medicalHistoryConfiguration: medicalHistoryConfig } = useGetMedicalHistoryConfiguration({ runOnMount: true });
  const { encounterViewModel } = useGetEncounterViewModel({ runOnMount: true });
  const { bodyParts } = useGetBodyParts({ runOnMount: true });
  const { diagnoses } = useGetDiagnoses({
    runOnMount: true,
    request: { icd10Codes: [] },
  });

  return { medicalHistoryConfig, bodyParts, encounterViewModel, diagnoses };
}

function usePatientResponses(patientInjuryId?: string) {
  const { injuryQuestionnaire, getInjuryQuestionnaire } = useGetInjuryQuestionnaire();
  const { injury, getInjury } = useGetInjury();

  useEffect(() => {
    if (patientInjuryId) {
      getInjury({ injuryId: patientInjuryId });
      getInjuryQuestionnaire({ injuryId: patientInjuryId });
    }
  }, [getInjury, getInjuryQuestionnaire, patientInjuryId]);

  return { injuryQuestionnaire, injury };
}

function getSelectedEncounter(
  selectedEncounter: MdOrthoPatientEncounter | undefined | string,
  encounterList: MdOrthoPatientEncounter[]
): MdOrthoPatientEncounter | undefined {
  if (encounterList.length <= 0) {
    return undefined;
  }
  if (encounterList.length >= 1) {
    const encounterId = typeof selectedEncounter === "string" ? selectedEncounter : selectedEncounter?.id;
    if (!encounterId) {
      return encounterList[0];
    }
    const matching = encounterList?.find((e) => e.id === encounterId);
    if (matching) {
      return matching;
    }
    return encounterList[0];
  }
}

export type UseDataReturnType = ReturnType<typeof useData>;

import { getAppConfig } from "api/client/configuration/getAppConfig";
import { AppConfig } from "api/types/appConfig";

let configLocal: AppConfig | undefined = undefined;

export async function getAppConfigGlobal() {
  if (configLocal === undefined) {
    const { data } = await getAppConfig();
    configLocal = data;
  }
  return configLocal;
}

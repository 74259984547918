import { s1Date } from "@sprint1/pkg/src/date";

export function formatDate(date: Date | string) {
  if (s1Date.is(date).today()) {
    return new Date(date).toLocaleTimeString([], {
      timeStyle: "short",
    });
  } else {
    return new Date(date).toLocaleDateString();
  }
}

import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { createFilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest.helper";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { ReferralPartner } from "api/types/referralPartner";

export const referralPartnerFilters = {
  searchReferralPartner(referralPartnerId: string) {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [{ field: name("id"), value: referralPartnerId }],
      sortFields: [],
    });

    return request;
  },

  sortByName() {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [],
      sortFields: [{ field: name("name"), ascending: true }],
    });

    return request;
  },
};

const name = nameof<ReferralPartner>;

export interface SecondaryButtonType
  extends React.ComponentPropsWithRef<"button"> {}
export function SecondaryButton({
  className = "",
  ...rest
}: SecondaryButtonType) {
  return (
    <button
      type="button"
      className={`btn btn-sm btn-outline-primary ${className}`}
      {...rest}
    />
  );
}

import { EncounterConfigurationViewModel } from "api/types/encounterConfigurationViewModel";

export function getEquipmentName(equipmentId: number, encounterConfigViewModel: EncounterConfigurationViewModel) {
  if (!equipmentId || !encounterConfigViewModel) {
    return "";
  }

  const matchingEq = encounterConfigViewModel.durableMedicalEquipment.find((evm) => evm.id === equipmentId);

  return matchingEq?.name ?? "";
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ReferralPartner } from "api/types/referralPartner";

export function saveReferralPartner(arg: SaveReferralPartnerArgType) {
  //__queryString__
  return fetchJson<string>(`/api/ReferralPartner/Save`, {
    method: "POST",
    body: arg.partner,
  });
}

export function useSaveReferralPartner(arg?: UseSaveReferralPartnerArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => saveReferralPartner(arg) : undefined,
  });

  const saveReferralPartnerCb = useCallback(
    (arg: SaveReferralPartnerArgType) => {
      return run(() => saveReferralPartner(arg));
    },
    [run]
  );
  return {
    ...rest,
    saveReferralPartner: saveReferralPartnerCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface SaveReferralPartnerArgType {
  partner: ReferralPartner;
}

export type UseSaveReferralPartnerArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & SaveReferralPartnerArgType);


import { OptionType } from "@sprint1/pkg/src/form/select";
import { TranslateFnType } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { OrderType } from "./orderType";

export const ImagingTypeHelpers = {
  convertToOptions(translate: TranslateFnType) {
    const options: OptionType<number | undefined, string>[] = [];
    for (const io in OrderType) {
      const value = Number(io);
      if (!isNaN(value)) {
        options.push({
          label: translate(`__orderType_${OrderType[value]}`),
          value: value,
        });
      }
    }
    const sortedOptions = options.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    return sortedOptions;
  },

  toString({
    type,
    otherName,
    translate,
  }: {
    type?: OrderType;
    otherName?: string;
    translate: TranslateFnType;
  }) {
    if (!isDefined(type)) {
      return "";
    }
    if (type === OrderType.Other) {
      return otherName ?? "";
    }
    return translate(`__orderType_${OrderType[type]}`);
  },
};

import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useAppRoutes } from "./useRoutes";
import { routes } from "./routes.config";
import { useAppUser } from "common/useAppUser";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";

export function Home() {
  const appUser = useAppUser();
  const { go } = useAppRoutes();

  useRunOnMount(() => {
    if (!appUser.isLoggedIn()) {
      go({ ...routes.auth.loginUrl(), replace: true });
      return;
    }
    if (appUser.isDoctor || appUser.isMdOrthoNurse) {
      go(routes.careGivers.schedule);
      return;
    }

    if (appUser.isAdmin) {
      go(routes.admin.clients.list);
      return;
    }

    if (appUser.isPatient || appUser.isOnSiteNurse) {
      go(routes.patient.dashboard);
      return;
    }
  });

  return <ViewPortLoading />;
}

import { Footer } from "../../../components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { Formik, Form } from "formik";
import { ProfileSection } from "./Profile";
import { HistorySection } from "./HistorySection";
import { EmergencyContact } from "./EmergencyContact";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { getMedicalHistory } from "api/client/patientMedicalHistory/getMedicalHistory";
import { updateMedicalHistory } from "api/client/patientMedicalHistory/updateMedicalHistory";
import { PatientMedicalHistory } from "api/types/patientMedicalHistory";
import { useState } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useGetMedicalHistoryConfiguration } from "api/client/configuration/getMedicalHistoryConfiguration";
import { patientRoutes } from "routes/routes.config";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export function MedicalHistory() {
  const { translate } = useTranslation();
  const { medicalHistoryConfig, medicalHistory, save, isSaving } = useData();
  const routes = useAppRoutes();
  const qs = patientRoutes.medicalHistory.getQueryStrings();

  if (!medicalHistory || !medicalHistoryConfig) {
    return <ViewPortLoading />;
  }
  return (
    <Formik
      initialValues={medicalHistory}
      onSubmit={(updatedMedicalHistory) => {
        return save(updatedMedicalHistory);
      }}
    >
      <Form>
        <div className="pb-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-lg-6">
              <ProfileSection />
              <HistorySection
                titleKey="__medicalHistory"
                labelKey="__doYouHaveFollowingConditions"
                otherLabelKey="__anyOtherMedicalHistory"
                rootJsonPath="diseases"
                medicalHistoryChoice={medicalHistory.data.diseases}
                options={medicalHistoryConfig.diseases}
              />

              <HistorySection
                titleKey="__surgicalHistory"
                labelKey="__haveYouHadFollowingProcedure"
                otherLabelKey="__anyOtherSurgicalHistory"
                rootJsonPath="surgicalHistories"
                medicalHistoryChoice={medicalHistory.data.surgicalHistories}
                options={medicalHistoryConfig.surgicalHistories}
              />

              <HistorySection
                titleKey="__medications"
                labelKey="__whatMedicationsCurrentlyTaking"
                otherLabelKey="__anyOtherMedications"
                rootJsonPath="medications"
                medicalHistoryChoice={medicalHistory.data.medications}
                options={medicalHistoryConfig.medications}
              />

              <HistorySection
                titleKey="__allergies"
                labelKey="__areYouAllergicToAny"
                otherLabelKey="__anyOtherAllergies"
                rootJsonPath="allergies"
                medicalHistoryChoice={medicalHistory.data.allergies}
                options={medicalHistoryConfig.allergies}
              />

              <EmergencyContact />

              <Footer
                variant="cancelAndContinue"
                onCancelClick={() => {
                  if (qs.isValid && qs.redirectTo === "back") {
                    routes.goBack();
                    return;
                  }
                  routes.goToPatientRoutes("dashboard");
                }}
                saveButtonProps={{
                  showSpinner: isSaving,
                  type: "submit",
                  disabled: isSaving,
                  text:
                    qs.isValid && (qs.redirectTo === "back" || qs.redirectTo === "patientDashboard")
                      ? translate("__save")
                      : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

function useData() {
  const { medicalHistoryConfiguration: medicalHistoryConfig } = useGetMedicalHistoryConfiguration({
    runOnMount: true,
  });
  const [medicalHistory, setMedicalHistory] = useState<PatientMedicalHistory>();
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const routes = useAppRoutes();

  useRunOnMount(() => {
    async function load() {
      const qs = patientRoutes.medicalHistory.getQueryStrings();
      if (!qs.isValid) {
        toast.error({ type: "BadUrl" });
        return;
      }

      const { data } = await getMedicalHistory({ patientId: qs.patientId });
      setMedicalHistory(data);
    }
    load();
  });

  async function save(medicalHistory: PatientMedicalHistory) {
    try {
      setIsSaving(true);
      toast.clear();
      await updateMedicalHistory({ patientMedicalHistory: medicalHistory });
      const qs = patientRoutes.medicalHistory.getQueryStrings();

      if (qs.isValid && qs.redirectTo === "questionnaire") {
        routes.go(routes.patientRoutes.questionnaire.url(qs.injuryId));
      } else if (qs.isValid && qs.redirectTo === "back") {
        routes.goBack(true);
      } else if (qs.isValid && qs.redirectTo === "patientDashboard") {
        routes.go({ pathname: routes.patientRoutes.dashboard });
      } else {
        routes.go(routes.patientRoutes.selectBodyPart.url());
      }
    } catch (e) {
      toast.error({ error: e });
    } finally {
      setIsSaving(false);
    }
  }

  return { medicalHistoryConfig, medicalHistory, save, isSaving };
}

import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";

export interface TipType {
  diagnose: string;
  tips: string[];
}

export function getTips(diagnoses: MdOrthoDiagnosis[] | undefined, selectedIcdCodes: string[]): TipType[] {
  const tips: TipType[] = [];
  if (!diagnoses) {
    return tips;
  }

  for (const icdCode of selectedIcdCodes) {
    const matching = diagnoses.filter((d) => d.icd10Codes.includes(icdCode));
    matching.forEach((d) => {
      tips.push({ diagnose: d.diagnosis, tips: d.tips });
    });
  }
  return tips;
}

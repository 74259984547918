import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export function OpenClosedBadge({ open, className = "" }: { open?: boolean; className?: string }) {
  const { translate } = useTranslation();
  return (
    <span className={`badge rounded-pill text-bg-${open ? "danger" : "light"} ${className}`}>
      {open ? translate("__open") : translate("__closed")}
    </span>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";




export function getUnreadMessageCount(arg: GetUnreadMessageCountArgType = {}) {
  //__queryString__
  return fetchJson<number>(`/api/MessageThread/UnreadCount`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetUnreadMessageCount(arg?: UseGetUnreadMessageCountArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getUnreadMessageCount(arg) : undefined,
  });

  const getUnreadMessageCountCb = useCallback(
    (arg: GetUnreadMessageCountArgType = {}) => {
      return run(() => getUnreadMessageCount(arg));
    },
    [run]
  );
  return {
    ...rest,
    getUnreadMessageCount: getUnreadMessageCountCb,
    unreadMessageCount: rest?.fetchJsonResponse?.data,
  };
}

export interface GetUnreadMessageCountArgType {
  //__ArgTypeBody__
}

export type UseGetUnreadMessageCountArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetUnreadMessageCountArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { PatientMedicalHistory } from "api/types/patientMedicalHistory";

export function getMedicalHistory(arg: GetMedicalHistoryArgType) {
  const qs = convertToQueryString( {patientId: arg.patientId })
  return fetchJson<PatientMedicalHistory>(`/api/patient/medicalhistory/Get${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetMedicalHistory(arg?: UseGetMedicalHistoryArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getMedicalHistory(arg) : undefined,
  });

  const getMedicalHistoryCb = useCallback(
    (arg: GetMedicalHistoryArgType) => {
      return run(() => getMedicalHistory(arg));
    },
    [run]
  );
  return {
    ...rest,
    getMedicalHistory: getMedicalHistoryCb,
    medicalHistory: rest?.fetchJsonResponse?.data,
  };
}

export interface GetMedicalHistoryArgType {
  patientId: string;
}

export type UseGetMedicalHistoryArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetMedicalHistoryArgType);


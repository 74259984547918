import { DefaultPageLayout } from "@sprint1/pkg/src/page";
import { PageContainer } from "@sprint1/pkg/src/page/Container";
import { PageTitleSimple } from "@sprint1/pkg/src/page/title/Simple";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export function Playground() {
  const { translate } = useTranslation();
  return (
    <DefaultPageLayout
      documentTitle={translate("__playground")}
      title={<PageTitleSimple>{translate("__playground")}</PageTitleSimple>}
    >
      <PageContainer>
        <div>Your own playground.</div>
      </PageContainer>
    </DefaultPageLayout>
  );
}

import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { Section } from ".";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { PhysicalTherapyDurationHelper } from "api/types/physicalTherapyDuration.helper";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UseDataReturnType } from "../../useData";
import { VideoPlayer } from "components/VideoPlayer";

export function PhysicalTherapySection({
  patientEncounter,
  encounterViewModel,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
}) {
  const { translate } = useTranslation();
  const nonEmpty = patientEncounter.data.physicalTherapies.filter(
    (pt) => isDefined(pt.duration) || isDefined(pt.instructions) || pt.treatmentVideos?.length > 0
  );
  return (
    <Section title={translate("__physicalTherapy")}>
      {nonEmpty.length === 0 && <div>{translate("__none")}</div>}
      {nonEmpty.map((pt, index) => {
        return (
          <div key={`pt-${index}-${pt.duration}-${pt.instructions}`}>
            <div>
              <span>{translate("__duration")}:</span>
              <span className="ms-2">{PhysicalTherapyDurationHelper.translate(pt.duration, translate)}</span>
            </div>
            <div>{translate("__instructions")}:</div>
            <pre className="ms-2">{pt.instructions}</pre>
            <div className="d-print-none">
              <span>{translate("__treatmentVideos")}:</span>
              {pt.treatmentVideos.map((id, index) => {
                const matchingVideo = encounterViewModel?.treatmentVideos?.find((tv) => tv.id === id);
                return (
                  <div key={`treatment-video-${id}-${index}`} className="ms-2 mt-2" style={{ width: "300px" }}>
                    {matchingVideo?.videoId && <VideoPlayer videoId={matchingVideo?.videoId} />}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Section>
  );
}

import { saveQuestionnaire } from "api/client/injury/saveQuestionnaire";
import { getInjuryQuestionnaire } from "api/client/injury/getInjuryQuestionnaire";
import { PageLayout } from "../components/PageLayout";
import { InjuryQuestionnaire } from "api/types/injuryQuestionnaire";
import { useState } from "react";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { Field } from "@sprint1/pkg/src/form/field";

import { Formik, Form, useFormikContext } from "formik";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { PatientQuestionResponse } from "api/types/patientQuestionResponse";

import { PatientInjuryQuestionnaire } from "api/types/patientInjuryQuestionnaire";
import { Section } from "../components/Section";
import { Footer } from "components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { hasValue } from "@sprint1/pkg/src/utils/hasValue";
import { DynamicQuestions } from "./DynamicQuestions";
import { nameOf } from "./name";
import { QuestionImages } from "./QuestionImages";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { LinkButton } from "components/LinkButton";
import { CtaButton } from "components/CtaButton";
import { Loading } from "@sprint1/pkg/src/loading";
import { useAppUser } from "common/useAppUser";
import { routes } from "routes/routes.config";
import { RequiredLegend } from "@sprint1/pkg/src/form/requiredLegend";
import { GetInjuryQuestionnaireResponse } from "api/types/getInjuryQuestionnaireResponse";

export function Questionnaire() {
  const data = useData();
  const { translate } = useTranslation();
  const routes = useAppRoutes();

  if (!data.formData || !data.injuryQuestionnaireResponse) {
    return <ViewPortLoading />;
  }
  return (
    <Formik
      initialValues={data.formData}
      onSubmit={(v) => {
        return data.save(v, "continue");
      }}
    >
      <Form>
        <div className="pb-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-lg-6">
              <PageLayout titleKey="__scheduleAppointment">
                <Section titleKey="__scheduleAppointment">
                  <div className="text-muted fs-7 pb-2">{translate("__step_x_Of_y", { x: 2, y: 3 })}</div>

                  <RequiredLegend className=" my-3 fst-italic">{translate("__required")}</RequiredLegend>
                  <DynamicQuestions injuryQuestionnaire={data.injuryQuestionnaireResponse.questionnaire} />
                  <Field name={`${nameOf.request("additionalDetails")}`}>
                    <Label>{translate("__additionalFactsAboutInjury")}</Label>
                    <TextAreaFormikField rows={5} />
                  </Field>
                  <QuestionImages injuryQuestionnaire={data.injuryQuestionnaireResponse.questionnaire} />
                </Section>
              </PageLayout>
              <Footer
                variant="placeholder"
                left={
                  <LinkButton
                    variant="cancel"
                    onClick={() => {
                      routes.goToPatientRoutes("dashboard");
                    }}
                  />
                }
                right={
                  <>
                    <Loading inline show={data.saving} />
                    {data.appUser.isPatient && <ContinueLaterButton data={data} />}
                    <CtaButton
                      type="submit"
                      disabled={data.saving}
                      size="small"
                      className="ms-2"
                      text={translate("__continue")}
                    />
                  </>
                }
              />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

function ContinueLaterButton({ data }: { data: UseDataReturnType }) {
  const formikContext = useFormikContext<PatientInjuryQuestionnaire>();
  const { translate } = useTranslation();

  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-sm ms-2"
      disabled={data.saving}
      onClick={() => {
        data.save(formikContext.values, "continue-later");
      }}
    >
      {translate("__saveAndContinueLater")}
    </button>
  );
}

function useData() {
  const injuryId = getQueryStringValue("injuryId");
  const appUser = useAppUser();

  const [injuryQuestionnaireResponse, setInjuryQuestionnaireResponse] = useState<GetInjuryQuestionnaireResponse>();
  const [formData, setFormData] = useState<PatientInjuryQuestionnaire>();
  const [saving, setSaving] = useState(false);
  const routesHook = useAppRoutes();
  const toast = useToast();

  useRunOnMount(() => {
    async function load() {
      if (!injuryId) {
        toast.error({ type: "BadUrl" });
        return;
      }
      const { data } = await getInjuryQuestionnaire({ injuryId });
      setInjuryQuestionnaireResponse(data);
      const initialFormData: PatientInjuryQuestionnaire = {
        additionalDetails: "",
        ...data.patientResponses,
        //We want to have question and responses in pairs so we formik fields can just read and write instead of finding item and deleting item in arrays
        //We are filling in an empty response when a question doesn't have response.
        data: {
          responses: getResponses(data.questionnaire, data.patientResponses.data.responses),
        },
      };
      setFormData(initialFormData);
    }
    load();
  });

  async function save(saveRequest: PatientInjuryQuestionnaire, action: "continue" | "continue-later") {
    try {
      toast.clear();
      setSaving(true);
      const cleanRequest = removeEmptyResponses(saveRequest);
      await saveQuestionnaire({ request: cleanRequest });
      if (appUser.isPatient || appUser.isOnSiteNurse) {
        if (action === "continue") {
          routesHook.go(routesHook.patientRoutes.schedule.scheduleUrl(injuryId));
        } else {
          routesHook.go(routesHook.patientRoutes.dashboard);
        }
      } else {
        //We check injuryId not null on load.
        routesHook.go(routes.careGivers.scheduleAppointment.scheduleUrl({ injuryId: injuryId! }));
      }
    } catch (error) {
      toast.error({ error });
    } finally {
      setSaving(false);
    }
  }

  return { formData, injuryQuestionnaireResponse, save, saving, appUser };
}

type UseDataReturnType = ReturnType<typeof useData>;

function removeEmptyResponses(saveRequest: PatientInjuryQuestionnaire) {
  const nonEmptyResponses = saveRequest.data.responses.filter((r) => r.choices.length > 0 || hasValue(r.text));
  return { ...saveRequest, responses: nonEmptyResponses };
}

function getResponses(injuryQuestionnaire: InjuryQuestionnaire, responsesFromServer: PatientQuestionResponse[]) {
  return injuryQuestionnaire.questions.map((q) => {
    const matchingResponse = responsesFromServer.find((r) => r.patientQuestionId === q.id);
    const response: PatientQuestionResponse = {
      choices: [],
      patientQuestionId: q.id,
      text: undefined,
    };
    return matchingResponse ?? response;
  });
}

import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { PatientInjuryQuestionnaire } from "api/types/patientInjuryQuestionnaire";
import { PatientInjuryQuestionnaireData } from "api/types/patientInjuryQuestionnaireData";
import { PatientQuestionResponse } from "api/types/patientQuestionResponse";

export const nameOf = {
  responses(responseIndex: number, key: keyof PatientQuestionResponse) {
    const dataName = nameof<PatientInjuryQuestionnaire>("data");
    const responsesName = nameof<PatientInjuryQuestionnaireData>("responses");
    return `${dataName}.${responsesName}[${responseIndex}].${key}`;
  },
  request(key: keyof PatientInjuryQuestionnaire) {
    return key;
  },
};

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { WorkRestrictionTypeHelper } from "api/types/workRestrictionType.helper";
import { UseDataReturnType } from "../../useData";
import { Section } from ".";
import { WorkRestrictionType } from "api/types/workRestrictionType";
import { toUtcDate } from "common/toUtcDate";

export function RestrictionSection({
  patientEncounter,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
}) {
  const { translate } = useTranslation();
  const options = WorkRestrictionTypeHelper.toOptions(translate);
  const matchingOption = options.find((o) => o.value === patientEncounter.data.restrictions.workRestrictionsType);

  const restrictions = patientEncounter.data.restrictions;
  const hasRestrictions =
    isDefined(restrictions.notes) ||
    isDefined(restrictions.returnToWorkDate) ||
    isDefined(restrictions.workRestrictionsType);
  if (!hasRestrictions) {
    return (
      <Section title={translate("__restrictions")}>
        <div>{translate("__none")}</div>
      </Section>
    );
  }
  return (
    <Section title={translate("__restrictions")}>
      <div className="ms-3 mt-3">
        <div className="fw-bold text-uppercase">{matchingOption?.label}</div>
        <div className="ms-3">
          {patientEncounter.data.restrictions.notes && (
            <>
              <div className="fw-bold text-uppercase">{translate("__instructions")}</div>
              <pre>{patientEncounter.data.restrictions.notes}</pre>
            </>
          )}
          {(patientEncounter.data.restrictions?.workRestrictionsType === WorkRestrictionType.None ||
            patientEncounter.data.restrictions?.workRestrictionsType ===
              WorkRestrictionType.ReturnToWorkWithRestrictions) &&
            patientEncounter.data.restrictions.returnToWorkDate && (
              <>
                <div className="fw-bold text-uppercase">{translate("__returnToWorkOn")}</div>
                <div>{toUtcDate(patientEncounter.data.restrictions.returnToWorkDate)}</div>
              </>
            )}

          {patientEncounter.data.restrictions?.workRestrictionsType === WorkRestrictionType.OffWork &&
            (patientEncounter.data.restrictions?.offWorkStartDate ||
              patientEncounter.data.restrictions?.offWorkEndDate) && (
              <>
                <div className="fw-bold text-uppercase">{translate("__startDate")}</div>
                <div>{toUtcDate(patientEncounter.data.restrictions.offWorkStartDate)}</div>

                <div className="fw-bold text-uppercase">{translate("__endDate")}</div>
                <div>{toUtcDate(patientEncounter.data.restrictions.offWorkEndDate)}</div>
              </>
            )}
        </div>
      </div>
    </Section>
  );
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Section } from ".";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { ImagingSection } from "./ImagingSection";
import { EquipmentSection } from "./EquipmentSection";
import { EncounterConfigurationViewModel } from "api/types/encounterConfigurationViewModel";
import { Fragment } from "react";
import { getTips } from "routes/cg/common/getTipsForIcdCodes";
import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";

export function DiagnosisSection({
  patientEncounter,
  encounterViewModel,
  diagnoses,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: EncounterConfigurationViewModel | undefined;
  diagnoses: MdOrthoDiagnosis[] | undefined;
}) {
  const { translate } = useTranslation();

  return (
    <Section title={translate("__diagnosis&Treatment")}>
      <Icd10 />
      <Tips />
      <div className="mt-2">
        <div className="fw-bold">{translate("__additionalInstructionsForPatient")}</div>
        <div>
          {patientEncounter.data.diagnosisNotes ? (
            <pre className="">{patientEncounter.data.diagnosisNotes}</pre>
          ) : (
            translate("__none")
          )}
        </div>
      </div>
      {/* Treatment */}
      <div className="mt-2">
        <div className="fw-bold">{translate("__treatment")}</div>
        <ImagingSection patientEncounter={patientEncounter} encounterViewModel={encounterViewModel} />

        <EquipmentSection patientEncounter={patientEncounter} encounterViewModel={encounterViewModel} />
      </div>
    </Section>
  );

  function Icd10() {
    return (
      <div>
        <div className="fw-bold">{translate("__icd-10")}</div>

        <div>
          {patientEncounter.data.diagnoses.length === 0 && <div>{translate("__none")}</div>}
          {encounterViewModel &&
            patientEncounter.data.diagnoses.map((d, index) => {
              const matching = encounterViewModel.icd10Codes.find((option) => option.code === d.icd10Code);
              return (
                <Fragment key={`${d.icd10Code}-${index}`}>
                  {matching && (
                    <span className="badge rounded-pill text-bg-light me-1">
                      {matching.code} {matching.description}
                    </span>
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
    );
  }

  function Tips() {
    if (patientEncounter.data.diagnoses.length === 0) {
      return null;
    }

    const icdCodes = patientEncounter.data.diagnoses.map((d) => d.icd10Code);
    const tips = getTips(diagnoses, icdCodes);
    return (
      <div>
        <div className="fw-bold">{translate("__instructions")}</div>
        {tips.map((t, index) => {
          return (
            <div key={`tip-${t.diagnose}-${index}`}>
              <div>{t.diagnose}</div>
              <ul>
                {t.tips.map((t, i) => (
                  <li key={`tip-${t}-${i}`}>{t}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }
}

import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";

export function toUtcDate(utcDate: Date | string | undefined | null): string {
  if (!isDefined(utcDate)) {
    return "";
  }

  const tmpDate = new Date(utcDate); //Converts UTC time to local time
  tmpDate.setMinutes(tmpDate.getMinutes() + tmpDate.getTimezoneOffset()); //Shift local time to UTC time https://stackoverflow.com/a/53528148

  return tryFormatDate(tmpDate);
}

import { routes } from "routes/routes.config";
import { Link } from "react-router-dom";
import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";
import { ErrorWithData } from "@sprint1/pkg/src/error/errorWithData";

export interface BreadcrumbType {
  crumbs: CrumbType[];
  current?: ReactNode;
}
export function Breadcrumb(props: BreadcrumbType) {
  const { translate } = useTranslation();
  const crumbsWithLink = getBreadCrumbs(props.crumbs, translate);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {crumbsWithLink.map((c, i) => {
          return (
            <li className="breadcrumb-item" key={`bc-${i}-${c.link}`}>
              <Link to={c.link}>{c.text}</Link>
            </li>
          );
        })}

        {props.current && (
          <li className="breadcrumb-item active" aria-current="page">
            {props.current}
          </li>
        )}
      </ol>
    </nav>
  );
}

export type CrumbType =
  | "home"
  | "clients"
  | "patients"
  | { page: "users"; clientId: string; clientName: string }
  | { text: string; link: string };

function getBreadCrumbs(types: CrumbType[], translate: TranslateFnType) {
  return types.map((breadcrumb) => {
    if (typeof breadcrumb === "string") {
      if (breadcrumb === "home") return { text: translate("__home"), link: "/" };
      if (breadcrumb === "clients") return { text: translate("__clients"), link: routes.admin.clients.list };
      if (breadcrumb === "patients") return { text: translate("__patients"), link: routes.careGivers.patients };
    }
    if ("page" in breadcrumb && breadcrumb.page === "users") {
      return {
        text: translate("__users"),
        link: routes.admin.users.list.get(breadcrumb.clientId, breadcrumb.clientName),
      };
    }

    if ("text" in breadcrumb && "link" in breadcrumb) {
      return breadcrumb;
    }

    throw new ErrorWithData("Invalid crumb type", { types, breadcrumb });
  });
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ImportUsersResponse } from "api/types/importUsersResponse";
import { ImportUsersRequest } from "api/types/importUsersRequest";

export function importUsers(arg: ImportUsersArgType) {
  //__queryString__
  return fetchJson<ImportUsersResponse[]>(`/api/User/Import`, {
    method: "POST",
    body: arg.request,
  });
}

export function useImportUsers(arg?: UseImportUsersArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => importUsers(arg) : undefined,
  });

  const importUsersCb = useCallback(
    (arg: ImportUsersArgType) => {
      return run(() => importUsers(arg));
    },
    [run]
  );
  return {
    ...rest,
    importUsers: importUsersCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface ImportUsersArgType {
  request: ImportUsersRequest;
}

export type UseImportUsersArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ImportUsersArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { StripePaymentIntentResponse } from "@sprint1/pkg/src/api/payment/types/stripePaymentIntentResponse";
import { ScheduleRequest } from "api/types/scheduleRequest";

export function prepare(arg: PrepareArgType) {
  //__queryString__
  return fetchJson<StripePaymentIntentResponse>(`/api/Payment/Prepare`, {
    method: "POST",
    body: arg.request,
  });
}

export function usePrepare(arg?: UsePrepareArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => prepare(arg) : undefined,
  });

  const prepareCb = useCallback(
    (arg: PrepareArgType) => {
      return run(() => prepare(arg));
    },
    [run]
  );
  return {
    ...rest,
    prepare: prepareCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface PrepareArgType {
  request: ScheduleRequest;
}

export type UsePrepareArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & PrepareArgType);


import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { LinkButton } from "components/LinkButton";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { getOptions } from "./getOptions";
import { PhysicalTherapyDuration } from "api/types/physicalTherapyDuration";
import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";

export function PhysicalTherapy({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["physicalTherapies"]>({
    name: name.patientEncounterData("physicalTherapies"),
  });

  return (
    <div>
      <LinkButton
        icon={faPlus}
        text={translate("__addPhysicalTherapy")}
        onClick={() => {
          const tmpValue = [...field.value];
          tmpValue.push({
            duration: PhysicalTherapyDuration.None,
            treatmentVideos: [],
            instructions: "",
          });
          helpers.setValue(tmpValue);
          helpers.setTouched(true);
        }}
      />
      {field.value.map((i, index) => {
        return (
          <div className="bg-light m-4 px-4 pt-4 pb-2" key={`imaging-${index}-${i.duration}`}>
            <Field name={name.physicalTherapy("duration", index)}>
              <Label>{translate("__duration")}</Label>
              {data.encounterViewModel && (
                <SelectInputFormikField options={getOptions(data.encounterViewModel.physicalTherapyDurations)} />
              )}
            </Field>
            <Field name={name.physicalTherapy("instructions", index)}>
              <Label>{translate("__instructions")}</Label>
              <TextAreaFormikField />
            </Field>

            <Field name={name.physicalTherapy("treatmentVideos", index)}>
              <Label>{translate("__treatmentVideos")}</Label>
              {data.encounterViewModel && (
                <ReSelectFormikField
                  multiple
                  options={data.encounterViewModel.treatmentVideos.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                />
              )}
            </Field>

            <LinkButton
              icon={faXmark}
              text={translate("__delete")}
              onClick={() => {
                const tmp = field.value.filter((ti) => i !== ti);
                helpers.setValue(tmp);
                helpers.setTouched(true);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

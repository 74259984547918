import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Link } from "react-router-dom";
import { Loading } from "@sprint1/pkg/src/loading";
import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { useSearchWorkersCompensation } from "api/client/workersComp/searchWorkersCompensation";
import { routes } from "routes/routes.config";
import { MdOrthoWorkersComp } from "api/types/mdOrthoWorkersComp";
import { useEffect } from "react";
import { WorkRestrictionTypeHelper } from "api/types/workRestrictionType.helper";
import { s1Date } from "@sprint1/pkg/src/date";
import { MdOrthoWorkersCompFilters } from "api/helpers/mdOrthoWorkersCompFilters";
import { useAppUser } from "common/useAppUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";

export function WorkersCompList() {
  const { translate } = useTranslation();

  return (
    <PageLayout>
      <PageTitleSimple title={translate("__workersComp")} left={translate("__workersComp")} />

      <Table />
    </PageLayout>
  );
}

function useData() {
  const filtersAndSort = useFiltersAndSort(MdOrthoWorkersCompFilters.sortByInjuryDate());
  const workerCompApi = useSearchWorkersCompensation({
    runOnMount: false,
  });

  const searchWorkersCompensation = workerCompApi.searchWorkersCompensation;
  useEffect(() => {
    searchWorkersCompensation({ request: filtersAndSort.filtersDebounced });
  }, [filtersAndSort.filtersDebounced, searchWorkersCompensation]);

  return { workerCompApi, filtersAndSort };
}

function Table() {
  const appUser = useAppUser();
  const { workerCompApi, filtersAndSort } = useData();

  const { translate } = useTranslation();

  if (!workerCompApi.workersCompensation) return <Loading />;

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              label={translate("__firstName")}
              filter={<TextFilter name={name("patientFirstName")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__lastName")}
              filter={<TextFilter name={name("patientLastName")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th
              label={translate("__employeeId")}
              filter={<TextFilter name={name("patientUserName")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__organization")}
              filter={<TextFilter name={name("tenantName")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__injuryDate")}
              // filter={<DateFilter name={name("dateOfInjury")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__apptDate")}
              // filter={<DateFilter name={name("lastAppointmentDate")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th
              label={translate("__restrictions")}
              // filter={<TextFilter name={name("restrictions")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {workerCompApi.workersCompensation.results.map((workersComp, index) => {
            return (
              <tr key={`tr-${index}-${workersComp.patientLastName}-${workersComp.dateOfInjury}`}>
                <td>{workersComp.patientFirstName}</td>
                <td>{workersComp.patientLastName}</td>
                <td>{workersComp.patientUserName}</td>
                <td>{workersComp.tenantName}</td>
                <td>{`${tryFormatDate(workersComp.dateOfInjury)}`}</td>
                <td>{`${tryFormatDate(workersComp.lastAppointmentDate)}`}</td>
                <td>
                  <div>
                    <div>
                      {WorkRestrictionTypeHelper.toString({
                        type: workersComp.workRestrictionsType,
                        translate,
                      })}
                      {workersComp.returnToWorkDate && (
                        <div>{`${translate("__returnToWorkOnWithDate", {
                          date: s1Date.format.tryFormatDate(workersComp.returnToWorkDate),
                        })}`}</div>
                      )}
                    </div>
                    <div>{workersComp.restrictions}</div>
                  </div>
                </td>
                <td>
                  <ActionMenuSimple>
                    {!appUser.isOnSiteNurse && (
                      <>
                        <ActionMenuSimple.MenuItem
                          as={Link}
                          to={routes.careGivers.patientsDetails.url(workersComp.patientId).fullUrl}
                        >
                          {translate("__viewPatientDetails")}
                        </ActionMenuSimple.MenuItem>
                        <ActionMenuSimple.MenuItem as={Link} to={routes.messages.url(workersComp.patientId)}>
                          {translate("__messagePatient")}
                        </ActionMenuSimple.MenuItem>
                      </>
                    )}
                    {workersComp.lastPatientEncounterId && (
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={routes.careGivers.encounterPrintout.url(workersComp.lastPatientEncounterId)}
                        target="_blank"
                      >
                        {translate("__printSummary")}

                        <FontAwesomeIcon icon={faUpRightFromSquare} className="ms-2" />
                      </ActionMenuSimple.MenuItem>
                    )}
                  </ActionMenuSimple>
                </td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

const name = nameof<MdOrthoWorkersComp>;

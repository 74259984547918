import { SelectInput } from "@sprint1/pkg/src/form/select";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { Card } from "components/Card";
import { PageTitleSimple } from "components/PageLayout";
import { AppointmentDetails } from "./AppointmentDetails";

import { Appointments } from "./Appointments";
import { MeetingDetails } from "./MeetingDetails";
import { useData, UseDataReturnType } from "./useData";

export function Schedule() {
  const data = useData();
  const { translate } = useTranslation();

  if (data.patientEncounters === undefined) {
    return <ViewPortLoading delay={false} />;
  }
  return (
    <>
      <div className="row g-2">
        <div className="col-lg">
          <Card>
            <PageTitleSimple title={translate("__schedule")} compact right={<DoctorsFilter data={data} />} />
            <Appointments data={data} />
          </Card>
        </div>
        {data.selectedEncounter && (
          <div className="col-lg ms-2">
            {data.selectedEncounter && <MeetingDetails data={data} />}
            {data.injury && <AppointmentDetails data={data} />}
          </div>
        )}
      </div>
    </>
  );
}

function DoctorsFilter({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  if (!data.careTeams) {
    return null;
  }

  const doctorOptions = data.careTeams.results.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  });

  return (
    <SelectInput
      options={[{ label: translate("__allDoctors"), value: undefined }, ...doctorOptions]}
      value={data.selectedDoctorId}
      onChange={(e, { selectedOption }) => {
        data.setSelectedDoctorId(selectedOption?.value);
      }}
    />
  );
}

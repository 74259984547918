import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";

import { ReactNode } from "react";
import Accordion from "react-bootstrap/Accordion";

import { Trans } from "react-i18next";

export function Landing() {
  const { translate } = useTranslation();
  return (
    <div>
      <div className="row justify-content-center ">
        <div className="col">
          <div className="bg-primary p-4 text-light ">
            <h1 className="fs-4">{translate("__landing_whyWaitWeeksOrMonths")}</h1>
            <div className="fs-5">{translate("__landing_scheduleVirtualVisit")}</div>
            <div className="py-2" />
          </div>
          <div className="p-4 bg-white">
            <img src="/public/logo.jpg" style={{ marginTop: "-4rem" }} alt="" />
            <h5>{translate("__landing_whyChooseMdOrtho")}</h5>
            <div>
              MD Ortho diagnoses and treats over 250 common orthopedic conditions and makes referrals to other top
              providers as needed. Our advanced digital telemedicine program is convenient for you: no long wait for an
              appointment, no travel time, and no doctor’s waiting room. We get you better, faster.
            </div>

            <div className="d-grid d-md-flex justify-content-center">
              <GetStartedButton className="my-3" />
            </div>
          </div>

          <div className="bg-gray text-black p-4">
            <h5 className="text-uppercase">How it works?</h5>
            <ListItem number={1}>Click or tap “Get Started” to create your MD Ortho account.</ListItem>
            <ListItem number={2}>
              Enter brief medical history and answer questions about your current orthopedic injury or condition.
            </ListItem>
            <ListItem number={3}>Schedule a virtual appointment with one of our Orthopedic specialists.</ListItem>
            <ListItem number={4}>Enter your payment for a one-time charge of $150.</ListItem>
            <ListItem number={5}>Meet with our orthopedic specialist through a secure video conference.</ListItem>
            <ListItem number={6}>
              After your appointment, a diagnosis and treatment plan, including any necessary referrals and
              prescriptions, will be sent to your account. Your provider will provide ongoing communications with you as
              needed through our secure messaging system and will schedule any follow-up appointments that may be
              necessary.
            </ListItem>
          </div>

          <div className="d-grid d-md-flex justify-content-center my-3 mb-5">
            <GetStartedButton />
          </div>

          <div className="bg-white p-3">
            <h5 className="text-uppercase">Frequently Asked Questions</h5>

            <Faq />
          </div>

          <div className="mt-5 m-3 text-center fs-8">
            <p>MD Ortho Systems, LLC, 485 Hillsdale Dr., Suite 341, Charlottesville VA 22901</p>
          </div>
        </div>
      </div>
    </div>
  );

  function GetStartedButton({ className }: { className?: string }) {
    return (
      <div className="text-center">
        <Link
          to={`${routes.patient.selfPay.signup}${window.location.search}`}
          className={`btn btn-primary ${className} fs-5 `}
        >
          {translate("__getStarted")}
        </Link>
        <div className="mt-2">
          <Trans i18nKey="__alreadyCoveredUnderEmployerPlan">
            <a href={`${routes.home}`} className={` ${className} fs-6`}>
              Click here ---
            </a>
          </Trans>
        </div>
      </div>
    );
  }

  function ListItem({ number, children }: { number: number; children: ReactNode }) {
    return (
      <div className="d-flex my-3">
        <div className="flex-shrink-0">
          <div
            style={{ width: "4rem", height: "4rem", backgroundColor: "var(--bs-gray-300)" }}
            className="d-flex justify-content-center align-items-center rounded-circle  bg-dark-subtle border fs-4 fw-bolder text-black-50"
          >
            {number}
          </div>
        </div>
        <div className="flex-grow-1 ms-3">
          <div className=" fw-bold">Step {number}</div>
          {children}
        </div>
      </div>
    );
  }

  function Faq() {
    return (
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>What is included within the one-time charge for my visit?</Accordion.Header>
          <Accordion.Body>
            Price includes a virtual visit with an orthopedic specialist who will provide you a diagnosis and a
            treatment plan for one orthopedic injury or condition, including any applicable referrals and prescriptions,
            after the visit. Also included are any related follow-up visits that your provider may decide to schedule
            with you and any related communications from your provider, which will be delivered to your MD Ortho account
            through our secure messaging feature. In addition, you will have access to our online Physical Therapy Video
            Library and Common Orthopedic Injury Library for one year at no additional cost.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Can I pay using my health insurance?</Accordion.Header>
          <Accordion.Body>
            MD Ortho does not accept payments through health insurance plans. We accept payment by major credit cards,
            debit cards. These charges may be qualified expenses under your Health Savings Account or Medical Savings
            Account. – please consult with your plan provider.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Are there injuries and conditions that MD Ortho is unable to treat?</Accordion.Header>
          <Accordion.Body>
            Our services are for the diagnosis and treatment of non-emergency orthopedic injuries only. If you need
            emergency medical care or medical care for serious injuries, do not use MD Ortho’s services and seek
            immediate medical attention from a local healthcare provider or facility.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Are there any restrictions on my ability to use MD Ortho’s services?</Accordion.Header>
          <Accordion.Body>
            For licensing and regulatory reasons, MD Ortho can currently provide services only to patients who are
            located in the Commonwealth of Virginia at the time of their appointment.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}

import { useState } from "react";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { LinkButton } from "components/LinkButton";

import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import Accordion from "react-bootstrap/Accordion";
import videos from "./PTVideos-en.json";

export function PhysicalTherapy() {
  const { translate } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState<PtVideoType>();
  const { videos } = useData();
  if (!videos) {
    return <ViewPortLoading />;
  }

  return (
    <>
      {!selectedTopic && (
        <PageLayout>
          <script src="https://player.vimeo.com/api/player.js"></script>

          <PageTitleSimple title={translate("__physicalTherapyVideos")} />
          <Accordion className="my-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{translate("__notMedicalAdviceTitle")}</Accordion.Header>
              <Accordion.Body>{translate("__notMedicalAdviceBody")}</Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="d-flex flex-row flex-wrap justify-content-center">
            {videos.map((c, index) => {
              return (
                <div key={`${c.Id}-${index}`} className="d-inline-block border rounded m-2">
                  <button
                    className="btn btn-outline-primary "
                    onClick={() => {
                      setSelectedTopic(c);
                    }}
                  >
                    {c.Name}
                  </button>
                </div>
              );
            })}
          </div>
        </PageLayout>
      )}

      {selectedTopic && <Content content={selectedTopic} onBackClick={() => setSelectedTopic(undefined)} />}
    </>
  );
}

function Content({ content, onBackClick }: { content: PtVideoType; onBackClick: () => any }) {
  const { translate } = useTranslation();
  return (
    <PageLayout containerType="container">
      <PageTitleSimple title={content.Name} right={<LinkButton variant="back" onClick={onBackClick} />} />
      <ul>
        {content.Description.split("\n").map((tip) => {
          return <li key={tip}>{tip}</li>;
        })}
      </ul>

      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src={`https://player.vimeo.com/video/${content.VideoId}?h=971f5f13ea&title=0&byline=0&portrait=0&color=022B40`}
          title="video"
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mt-4 fs-8">{translate("__notMedicalAdviceBody")}</div>
    </PageLayout>
  );
}

function useData() {
  return { videos };
}

type PtVideoType = (typeof videos)[0];
/* <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
<iframe
  src="https://player.vimeo.com/video/816290553?h=971f5f13ea&title=0&byline=0&portrait=0&color=022B40"
  title="video"
  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
  frameBorder="0"
  allow="autoplay; fullscreen; picture-in-picture"
  allowFullScreen
></iframe>
</div>
<script src="https://player.vimeo.com/api/player.js"></script> */

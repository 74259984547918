import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { ReactNode, useEffect, useRef, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Loading } from "@sprint1/pkg/src/loading";

export interface CtaButtonType extends React.ComponentPropsWithoutRef<"button"> {
  text?: ReactNode;
  icon?: IconProp;
  showSpinner?: boolean;
  showSuccess?: boolean;
  variant?: "save";
  size?: "normal" | "small";
}

export function CtaButton({
  text,
  variant,
  children,
  icon = faChevronRight,
  showSpinner,
  showSuccess,
  className = "",
  size = "normal",
  ...rest
}: CtaButtonType) {
  const { translate } = useTranslation();

  const tmpText = text ?? translate("__save");
  const buttonRef = useRef(null);
  const showSuccessIcon = useShowSuccessIcon(showSuccess);
  return (
    <>
      <button
        type="button"
        className={`btn btn-primary  text-light ${className} ${size === "small" ? "btn-sm" : "py-2"}`}
        ref={buttonRef}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <div className="d-inline-flex align-items-center">
            {showSpinner ? (
              <Loading className="pe-2" />
            ) : showSuccessIcon ? (
              <FontAwesomeIcon className="pe-2 text-success" icon={faCheckCircle} bounce />
            ) : null}
            {tmpText}
            {icon && <FontAwesomeIcon className="ps-2" icon={icon} />}
          </div>
        )}
      </button>
    </>
  );
}

function useShowSuccessIcon(showSuccessIconArg?: boolean) {
  const [showSuccessIcon, set] = useState(showSuccessIconArg);
  useEffect(() => {
    let timeoutId: any = undefined;
    if (showSuccessIconArg === true) {
      set(true);
      timeoutId = setTimeout(() => {
        set(false);
      }, 3000);
    } else {
      set(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuccessIconArg]);

  return showSuccessIcon;
}

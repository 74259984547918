import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { GetInjuryQuestionnaireResponse } from "api/types/getInjuryQuestionnaireResponse";

export function getInjuryQuestionnaire(arg: GetInjuryQuestionnaireArgType) {
  const qs = convertToQueryString( {injuryId: arg.injuryId })
  return fetchJson<GetInjuryQuestionnaireResponse>(`/api/Injury/Questionnaire${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetInjuryQuestionnaire(arg?: UseGetInjuryQuestionnaireArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getInjuryQuestionnaire(arg) : undefined,
  });

  const getInjuryQuestionnaireCb = useCallback(
    (arg: GetInjuryQuestionnaireArgType) => {
      return run(() => getInjuryQuestionnaire(arg));
    },
    [run]
  );
  return {
    ...rest,
    getInjuryQuestionnaire: getInjuryQuestionnaireCb,
    injuryQuestionnaire: rest?.fetchJsonResponse?.data,
  };
}

export interface GetInjuryQuestionnaireArgType {
  injuryId: string;
}

export type UseGetInjuryQuestionnaireArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetInjuryQuestionnaireArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { MdOrthoCareTeam } from "api/types/mdOrthoCareTeam";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";

export function searchCareTeams(arg: SearchCareTeamsArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<MdOrthoCareTeam>>(`/api/CareTeam/Search`, {
    method: "POST",
    body: arg.request,
  });
}

export function useSearchCareTeams(arg?: UseSearchCareTeamsArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => searchCareTeams(arg) : undefined,
  });

  const searchCareTeamsCb = useCallback(
    (arg: SearchCareTeamsArgType) => {
      return run(() => searchCareTeams(arg));
    },
    [run]
  );
  return {
    ...rest,
    searchCareTeams: searchCareTeamsCb,
    careTeams: rest?.fetchJsonResponse?.data,
  };
}

export interface SearchCareTeamsArgType {
  request: FilterAndSortRequest;
}

export type UseSearchCareTeamsArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & SearchCareTeamsArgType);


import { OptionType } from "@sprint1/pkg/src/form/select";
import { TranslateFnType } from "@sprint1/pkg/src/i18n/useTranslation";
import { WhenOccurred } from "./whenOccurred";

export function getWhenOccurredAsOptions(translate: TranslateFnType) {
  const options: OptionType<number | undefined>[] = [
    { label: "", value: undefined },
  ];
  for (const io in WhenOccurred) {
    const value = Number(io);
    if (!isNaN(value)) {
      options.push({
        label: translate(`__injuryOccurred_${WhenOccurred[value]}`),
        value: value,
      });
    }
  }
  return options;
}

import { PageLayout } from "./components/PageLayout";
import { Footer } from "../../components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { AvailabilityPicker } from "components/availabilityPicker";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useToast } from "@sprint1/pkg/src/toast/useToast";

import { CronofyNotification } from "api/types/cronofyNotification";
import { useState } from "react";
import { useSchedule } from "api/client/cronofy/schedule";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Section } from "./components/Section";
import { useReSchedule } from "api/client/cronofy/reSchedule";
import { useGetPatientEncounter } from "api/client/patientEncounter/getPatientEncounter";
import { s1Date } from "@sprint1/pkg/src/date";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { ScheduleAppointmentStatus } from "api/types/scheduleAppointmentStatus";
import Accordion from "react-bootstrap/Accordion";
import { useGetInjury } from "api/client/injury/getInjury";

export function ScheduleAppointment() {
  const routes = useAppRoutes();
  const data = useData();
  const { translate } = useTranslation();

  if (data.mode === "loading") {
    return <ViewPortLoading />;
  }

  const titleKey = data.mode === "reschedule" ? "__reScheduleAppointment" : "__scheduleAppointment";
  return (
    <>
      <PageLayout titleKey={titleKey}>
        <Section titleKey={titleKey}>
          <div className="text-muted fs-7 pb-2">{translate("__step_x_Of_y", { x: 3, y: 3 })}</div>
          {data.patientEncounterApi.patientEncounter && (
            <div className="alert alert-info">
              <span>{translate("__youAreReschedulingAnAppointment")}</span>
              <span className="ms-1">{translate("__itIsCurrentlyScheduledFor")}</span>
              <span className="ms-1 fw-bold">
                {s1Date.format.tryFormatDateTime(data.patientEncounterApi.patientEncounter.startTime)}
              </span>
            </div>
          )}
          <div className="row d-flex justify-content-center">
            <p className="alert alert-warning">{translate("__youShouldBeLocatedInSomeStates")}</p>
            {data.isWorkersComp && <p className="alert alert-info">{translate("__wcExpeditedMessage")}</p>}
            <div className="col-lg-8 col-md-8">
              <AvailabilityPicker onTimePicked={data.setSelectedTimeSlot} />
            </div>
          </div>
        </Section>
      </PageLayout>

      <div className="my-3">
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{translate("__rescheduleCancellationPolicy")}</Accordion.Header>
            <Accordion.Body>{translate("__cancellationPolicyDescription")}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Footer
        variant="backAndSave"
        onCancelClick={() => {
          routes.goToPatientRoutes("dashboard");
        }}
        saveButtonProps={{
          showSpinner: data.scheduleApi.isRunning || data.rescheduleApi.isRunning,
          disabled: data.scheduleApi.isRunning || data.rescheduleApi.isRunning,
          text: translate(titleKey),
        }}
        onSaveClick={() => {
          data.save();
        }}
      />
    </>
  );
}

function useData() {
  const toast = useToast();
  const scheduleApi = useSchedule();
  const rescheduleApi = useReSchedule();
  const patientEncounterApi = useGetPatientEncounter();
  const getInjuryApi = useGetInjury();

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<CronofyNotification>();
  const [mode, setMode] = useState<"schedule" | "reschedule" | "loading">("loading");

  const routes = useAppRoutes();
  const { translate } = useTranslation();
  useRunOnMount(() => {
    async function load() {
      const qs = routes.patientRoutes.schedule.getQueryStrings();
      if (qs.type === "invalid") {
        toast.error({ type: "BadUrl" });
      } else if (qs.type === "reschedule") {
        await patientEncounterApi.getPatientEncounter({ patientEncounterId: qs.patientEncounterId });
        setMode("reschedule");
      } else if (qs.type === "schedule") {
        await getInjuryApi.getInjury({ injuryId: qs.injuryId });
        setMode("schedule");
      }
    }
    load();
  });

  async function save() {
    toast.clear();
    const qs = routes.patientRoutes.schedule.getQueryStrings();

    if (!selectedTimeSlot) {
      toast.error(translate("__pleaseSelectATime"));
      return;
    }

    if (qs.type === "schedule") {
      const request = { injuryId: qs.injuryId, notification: selectedTimeSlot };
      const { data: scheduleResponse } = await scheduleApi.schedule({
        request,
      });
      if (scheduleResponse.status === ScheduleAppointmentStatus.RequiredPayment) {
        routes.go(routes.patientRoutes.selfPay.pay.getNavPayload(request));
        return;
      }
      toast.success(translate("__yourAppointmentBookedCheckEmailOrPhone"), true);
      routes.go(routes.patientRoutes.dashboard);
    } else if (qs.type === "reschedule") {
      await rescheduleApi.reSchedule({
        request: { notification: selectedTimeSlot, patientEncounterId: qs.patientEncounterId },
      });
      toast.success(translate("__yourAppointmentBookedCheckEmailOrPhone"), true);
      routes.go(routes.patientRoutes.dashboard);
    }
  }

  const isWorkersComp = getInjuryApi.injury?.workmansComp || patientEncounterApi.patientEncounter?.workmansComp;
  return { mode, setSelectedTimeSlot, scheduleApi, rescheduleApi, patientEncounterApi, save, isWorkersComp };
}

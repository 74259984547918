import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ReScheduleRequest } from "api/types/reScheduleRequest";

export function reSchedule(arg: ReScheduleArgType) {
  //__queryString__
  return fetchJson<void>(`/api/Cronofy/ReSchedule`, {
    method: "POST",
    body: arg.request,
  });
}

export function useReSchedule(arg?: UseReScheduleArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => reSchedule(arg) : undefined,
  });

  const reScheduleCb = useCallback(
    (arg: ReScheduleArgType) => {
      return run(() => reSchedule(arg));
    },
    [run]
  );
  return {
    ...rest,
    reSchedule: reScheduleCb,
    
  };
}

export interface ReScheduleArgType {
  request: ReScheduleRequest;
}

export type UseReScheduleArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ReScheduleArgType);


import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";

export interface LinkButtonType extends React.ComponentPropsWithoutRef<"button"> {
  icon?: IconProp;
  text?: ReactNode;
  variant?: "back" | "cancel" | "close";
}

export function LinkButton({ type = "button", icon, text, variant, ...rest }: LinkButtonType) {
  const { translate } = useTranslation();

  const iconTmp = getIcon();
  const textTmp = text
    ? text
    : variant === "back"
    ? translate("__back")
    : variant === "close"
    ? translate("__close")
    : translate("__cancel");

  return (
    <button className="btn btn-link btn-sm text-decoration-none" type={type} {...rest}>
      {iconTmp && <FontAwesomeIcon icon={iconTmp} className="pe-2" />}
      <span>{textTmp}</span>
    </button>
  );

  function getIcon() {
    if (icon) {
      return icon;
    }

    if (variant === "back") {
      return faChevronLeft;
    }
    if (variant === "cancel" || variant === "close") {
      return faClose;
    }

    return undefined;
  }
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useCallback } from "react";

export function useFormatName() {
  const { translate } = useTranslation();
  const formatDoctorName = useCallback(
    (lastName?: string) => {
      //API returns lastName as string | undefined
      return translate("__personName-last-title", {
        lastName: lastName ?? "",
        title: translate("__dr."),
      });
    },
    [translate]
  );

  const formatName = useCallback(
    (lastName?: string, firstName?: string) => {
      return translate("__personName-first-last", {
        lastName: lastName,
        firstName: firstName,
      });
    },
    [translate]
  );

  const formatPatientNameDetailed = useCallback(
    (user: MdOrthoUser) => {
      return translate("__patientNameWithDetails", {
        lastName: user.lastName,
        firstName: user.firstName,
        tenantName: user.tenantName,
        email: user.email ?? "",
        phoneNumber: user.phoneNumber ?? "",
        userName: user.userName,
      });
    },
    [translate]
  );

  return { formatDoctorName, formatName, formatPatientNameDetailed };
}

export type UseFormatNameType = ReturnType<typeof useFormatName>;

import { useEffect } from "react";
import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Link } from "react-router-dom";
import { Loading } from "@sprint1/pkg/src/loading";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { DateFilter } from "@sprint1/pkg/src/table/filters/DateFilter";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { routes } from "routes/routes.config";
import { useAppUser } from "common/useAppUser";
import { dateOfBirth } from "@sprint1/pkg/src/date/dateOfBirth";
import { useSearchPatients } from "api/client/user/searchPatients";
import { userFilters } from "api/helpers/searchUsers.helper";
import { AccessLevel } from "api/types/accessLevel";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";

const name = nameof<MdOrthoUser>;

export function PatientList() {
  const { translate } = useTranslation();

  return (
    <PageLayout>
      <PageTitleSimple title={translate("__patients")} left={translate("__patients")} />
      <Table />
    </PageLayout>
  );
}

function useData() {
  const filtersAndSort = useFiltersAndSort(userFilters.sortByLastNameAndFirstName());
  const fetchPatientsApi = useSearchPatients();

  const fetchPatients = fetchPatientsApi.searchPatients;
  useEffect(() => {
    fetchPatients({ request: filtersAndSort.filtersDebounced });
  }, [fetchPatients, filtersAndSort.filtersDebounced]);

  return {
    patients: fetchPatientsApi.patients,
    filtersAndSort,
    fetchPatientsApi,
  };
}

function Table() {
  const appUser = useAppUser();
  const { patients, filtersAndSort } = useData();

  const { translate } = useTranslation();

  if (!patients) return <Loading />;

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              label={translate("__lastName")}
              filter={<TextFilter name={name("lastName")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th
              label={translate("__firstName")}
              filter={<TextFilter name={name("firstName")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th label={translate("__gender")} />
            <S1Table.Th
              label={translate("__dob")}
              filter={<DateFilter name={name("dateOfBirth")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__employeeId")}
              filter={<TextFilter name={name("userName")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th label={translate("__employeeAccessLevel")} />
            <S1Table.Th
              label={translate("__organization")}
              filter={<TextFilter name={name("tenantName")} filtersAndSort={filtersAndSort} />}
            />
            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {patients.results.map((patient, index) => {
            return (
              <tr key={`tr-${index}-${patient.id}-${patient.tenantId}`}>
                <td>{patient.lastName}</td>
                <td>{patient.firstName}</td>
                <td>{formatGender(patient.gender, translate)}</td>

                <td>{dateOfBirth.tryFormat(patient.dateOfBirth)}</td>
                <td>{patient.userName}</td>
                <td>
                  {EnumHelper.toTranslatedString({
                    value: patient.accessLevel,
                    enumObj: AccessLevel,
                    translationPrefix: "__accessLevel_",
                    translate,
                  })}
                </td>
                <td>{patient.tenantName}</td>
                <td>
                  <ActionMenuSimple>
                    <ActionMenuSimple.MenuItem as={Link} to={routes.careGivers.patientsDetails.url(patient.id)}>
                      {translate("__viewPatientEncounters")}
                    </ActionMenuSimple.MenuItem>
                    <ActionMenuSimple.MenuItem as={Link} to={routes.messages.url(patient.id).fullUrl}>
                      {translate("__messagePatient")}
                    </ActionMenuSimple.MenuItem>
                    <ActionMenuSimple.MenuItem as={Link} to={routes.patient.selectBodyPart.url().fullUrl}>
                      {translate("__scheduleAppointment")}
                    </ActionMenuSimple.MenuItem>
                    <ActionMenuSimple.MenuItem
                      as={Link}
                      to={routes.patient.medicalHistory.getUrl({ patientId: patient.id, redirectTo: "back" })}
                    >
                      {translate("__updatePatientHistory")}
                    </ActionMenuSimple.MenuItem>
                    {patient.tenantId && appUser.isAdmin && (
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={routes.admin.users.edit.url({
                          clientId: patient.tenantId,
                          userId: patient.id,
                        })}
                      >
                        {translate("__updatePatientAccountDetails")}
                      </ActionMenuSimple.MenuItem>
                    )}
                  </ActionMenuSimple>
                </td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

function formatGender(gender: string | undefined, translate: TranslateFnType) {
  return gender === "Male" ? translate("__male") : gender === "Female" ? translate("__female") : !!gender ? gender : "";
}

import { OptionType } from "@sprint1/pkg/src/form/select";
import { TranslateFnType } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { WorkRestrictionType } from "./workRestrictionType";

export const WorkRestrictionTypeHelper = {
  toOptions(translate: TranslateFnType) {
    const options: OptionType<number | undefined>[] = [{ label: "", value: undefined }];
    for (const io in WorkRestrictionType) {
      const value = Number(io);
      if (!isNaN(value)) {
        options.push({
          label: translate(`__workRestrictionType_${WorkRestrictionType[value]}`),
          value: value,
        });
      }
    }
    return options;
  },

  toString({ type, translate }: { type?: WorkRestrictionType; translate: TranslateFnType }) {
    if (!isDefined(type)) {
      return "";
    }
    return translate(`__workRestrictionType_${WorkRestrictionType[type]}`);
  },
};

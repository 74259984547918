import { InjuryQuestionnaire } from "api/types/injuryQuestionnaire";

import { useField } from "formik";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UploadButton } from "@sprint1/pkg/src/upload/UploadButton";
import { uploadToS3Async } from "common/uploadToS3";
import { nameOf } from "./name";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { Photo } from "api/types/photo";

export function QuestionImages({ injuryQuestionnaire }: { injuryQuestionnaire: InjuryQuestionnaire }) {
  const [field, , helpers] = useField<InjuryQuestionnaire["requiredPhotos"]>({
    name: nameOf.request("photos"),
  });
  const { translate } = useTranslation();

  function handleImageDeleted(url: string) {
    const filtered = field.value.filter((v) => v.originalUrl !== url);
    helpers.setValue(filtered);
    helpers.setTouched(true);
  }

  function handleImageUploaded(newImageUrl: string) {
    const newPhoto: Photo = {
      originalUrl: newImageUrl,
      thumbnailUrl: newImageUrl,
    };
    const newList = [...field.value, newPhoto];
    helpers.setValue(newList);
    helpers.setTouched(true);
  }

  return (
    <div className="mb-3">
      <div className="mb-2">{translate("__uploadPhotos")}</div>
      {injuryQuestionnaire.imageUploadInstructions.length > 0 && (
        <ul>
          {injuryQuestionnaire.imageUploadInstructions.map((instruction, index) => (
            <li key={index} className="mb-2">
              {instruction}
            </li>
          ))}
        </ul>
      )}
      {injuryQuestionnaire.requiredPhotos?.length > 0 && (
        <div className="mb-2">
          {injuryQuestionnaire.requiredPhotos.map((p, index) => {
            return (
              <img
                key={`example-image-${index}`}
                //TODO Ask Shaun to fix the urls.
                src={p.thumbnailUrl.replaceAll("\\\\", "/")}
                alt={p.thumbnailUrl}
                className="img-thumbnail"
                style={{
                  height: "100px",
                  width: "120px",
                  objectFit: "cover",
                }}
              />
            );
          })}
        </div>
      )}
      <UploadButton
        uploadFileAsync={async ({ file }) => {
          const { url } = await uploadToS3Async(file);
          handleImageUploaded(url);
        }}
      />
      <div className="mb-2">
        {Array.isArray(field.value) &&
          field.value.map((p, index: number) => {
            return (
              <div
                className="card p-1 m-1 d-inline-flex"
                style={{ width: "125px" }}
                key={`img-${p.originalUrl}-${index}`}
              >
                <a href={p.originalUrl} target="__blank">
                  <img
                    src={p.thumbnailUrl}
                    alt={""}
                    className="card-img-top"
                    style={{
                      height: "100px",
                      width: "120px",
                      objectFit: "fill",
                    }}
                  />
                </a>
                <div className="d-flex justify-content-center pt-1">
                  <a href={p.originalUrl} target="__blank" className="btn btn-link">
                    <FontAwesomeIcon icon={faExpandAlt} />
                  </a>
                  <button
                    className="ms-1 btn btn-link"
                    onClick={() => {
                      handleImageDeleted(p.originalUrl);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="text-danger" />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

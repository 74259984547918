import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { UseDataReturnType } from "../../useData";

export function EquipmentSection({
  patientEncounter,
  encounterViewModel,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
}) {
  const { translate } = useTranslation();
  const nonEmptyEquipments = patientEncounter.data.equipments.filter(
    (e) => isDefined(e.id) || isDefined(e.instructions)
  );
  return (
    <div className="ms-3 mt-3">
      <div className="fw-bold">{translate("__equipment")}</div>
      {nonEmptyEquipments.length === 0 && <div>{translate("__none")}</div>}
      {nonEmptyEquipments.map((e, index) => {
        return (
          <div className="ms-3 mt-2" key={`imaging-${e.id}-${index}`}>
            <div className="fw-bold">{getEquipmentName(e.id, encounterViewModel)}</div>
            <div className="fw-bold mt-1">{translate("__instructions")}</div>
            <pre>{e.instructions}</pre>
          </div>
        );
      })}
    </div>
  );
}

function getEquipmentName(equipmentId: number, equipmentViewModel: UseDataReturnType["encounterViewModel"]) {
  if (!equipmentId || !equipmentViewModel) {
    return "";
  }

  const matchingEq = equipmentViewModel.durableMedicalEquipment.find((evm) => evm.id === equipmentId);

  return matchingEq?.name ?? "";
}

import { Field } from "@sprint1/pkg/src/form/field";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { Label } from "@sprint1/pkg/src/form/label";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { InjuryQuestionnaire } from "api/types/injuryQuestionnaire";
import { Fragment } from "react";
import { Field as FormikField, FieldProps } from "formik";
import { nameOf } from "./name";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { QuestionType } from "api/types/questionType";
import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";

export function DynamicQuestions({ injuryQuestionnaire }: { injuryQuestionnaire: InjuryQuestionnaire }) {
  return (
    <>
      {injuryQuestionnaire.questions.map((q, index) => {
        return (
          <Fragment key={`question-${index}`}>
            {q.type === QuestionType.FreeForm && (
              <div className="mb-3">
                <Field name={`${nameOf.responses(index, "text")}`} isRequired>
                  <Label>{q.questionText}</Label>
                  <S1InputFormikField />
                </Field>
              </div>
            )}

            {q.type === QuestionType.SingleSelectList && (
              <div className="mb-3">
                <Field name={`${nameOf.responses(index, "choices")}`} isRequired>
                  <Label>{q.questionText}</Label>
                  <FormikField name={`${nameOf.responses(index, "choices")}`}>
                    {(props: FieldProps<number[]>) => {
                      const value = props.field.value.length > 0 ? props.field.value[0] : undefined;
                      return (
                        <ReSelectField
                          value={value}
                          options={q.choices.map((c) => {
                            return { label: c.choiceText, value: c.id };
                          })}
                          isClearable
                          onChange={(payload) => {
                            const name = `${nameOf.responses(index, "choices")}`;
                            props.form.setFieldValue(name, payload.selectedValues);
                            props.form.setFieldTouched(name, true);
                          }}
                        />
                      );
                    }}
                  </FormikField>
                </Field>
              </div>
            )}

            {q.type === QuestionType.MultiSelectList && (
              <div className="mb-3">
                <Field name={`${nameOf.responses(index, "choices")}`} isRequired>
                  <Label>{q.questionText}</Label>

                  <ReSelectFormikField
                    options={q.choices.map((c) => {
                      return { label: c.choiceText, value: c.id };
                    })}
                    multiple
                    isClearable
                  />
                </Field>
              </div>
            )}

            {q.type === QuestionType.SingleSelectRadio && (
              <div className="mb-3">
                <Fieldset>
                  <Legend isRequired>{q.questionText}</Legend>
                  {q.choices.map((c, cIndex) => {
                    return (
                      <Field
                        key={`choice ${cIndex} ${c.id}`}
                        name={`${nameOf.responses(index, "choices")}`}
                        isCheckOrRadio
                        isInline
                      >
                        <Label>
                          <FormikField name={`${nameOf.responses(index, "choices")}`}>
                            {(props: FieldProps<number[]>) => {
                              const checked = props.field.value.includes(c.id);
                              return (
                                <S1InputField
                                  value={c.id}
                                  checked={checked}
                                  parse="asNumber"
                                  type="radio"
                                  onChange={() => {
                                    const name = `${nameOf.responses(index, "choices")}`;
                                    props.form.setFieldValue(name, [c.id]);
                                    props.form.setFieldTouched(name, true);
                                  }}
                                />
                              );
                            }}
                          </FormikField>

                          {c.choiceText}
                        </Label>
                      </Field>
                    );
                  })}
                </Fieldset>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { commonLinks } from "components/header/link";
import { ReactNode } from "react";
import { Outlet } from "react-router-dom";

export function Layout({ Header, containerClassName = "" }: { Header: ReactNode; containerClassName?: string }) {
  const { translate } = useTranslation();
  return (
    <>
      <div>
        <header>{Header}</header>
        <div className={`${containerClassName}`}>
          <main>
            <Outlet />
          </main>
        </div>
        <footer>
          <div className="container">
            <div className="text-center fs-7 text-muted my-3">
              <span>
                <a href={commonLinks.privacyPolicy} target="_blank" rel="noreferrer">
                  {translate("__privacyPolicy")}
                </a>
              </span>

              <span className="ms-2">
                <a href={commonLinks.termsOfUse} target="_blank" rel="noreferrer">
                  {translate("__termsOfUse")}
                </a>
              </span>
            </div>
            <div className="text-center fs-7 text-muted ">
              <span className="ms-2">Copyright© MD ORTHO SYSTEMS LLC. All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { LinkButton } from "components/LinkButton";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { sprint1ParseInt } from "@sprint1/pkg/src/parseInt";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { RefillQuantity } from "api/types/refillQuantity";

export function Prescription({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["prescriptions"]>({
    name: name.patientEncounterData("prescriptions"),
  });

  return (
    <div>
      <LinkButton
        icon={faPlus}
        text={translate("__addPrescription")}
        onClick={() => {
          const tmpValue = [...field.value];
          tmpValue.push({
            dispense: "",
            medication: "",
            sig: "",
            refill: RefillQuantity.None,
          });
          helpers.setValue(tmpValue);
          helpers.setTouched(true);
        }}
      />
      {field.value.map((i, index) => {
        return (
          <div className="bg-light m-4 px-4 pt-4 pb-2" key={`imaging-${index}`}>
            <Field name={name.prescription("medication", index)}>
              <Label>{translate("__medication/Strength")}</Label>
              <S1InputFormikField type="text" />
            </Field>

            <Field name={name.prescription("sig", index)}>
              <Label>{translate("__SIG")}</Label>
              <TextAreaFormikField />
            </Field>

            <Field name={name.prescription("dispense", index)}>
              <Label>{translate("__dispense")}</Label>
              <S1InputFormikField type="text" />
            </Field>

            <Field name={name.prescription("refill", index)}>
              <Label>{translate("__refills")}</Label>
              <SelectInputFormikField options={getOptions()} />
            </Field>

            <LinkButton
              icon={faXmark}
              text={translate("__delete")}
              onClick={() => {
                const tmpImagings = field.value.filter((ti) => i !== ti);
                helpers.setValue(tmpImagings);
                helpers.setTouched(true);
              }}
            />
          </div>
        );
      })}
    </div>
  );

  function getOptions() {
    const refillOptions: OptionType<number | undefined>[] = [];
    refillOptions.push({ label: "", value: undefined });
    Object.entries(data.encounterViewModel?.refillQuantities ?? {}).forEach(([key, value]) => {
      const parsedKey = sprint1ParseInt(key);
      if (parsedKey !== undefined) {
        refillOptions.push({ label: value, value: parsedKey });
      }
    });
    return refillOptions;
  }
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";

export function listPatientEncounters(arg: ListPatientEncountersArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<MdOrthoPatientEncounter>>(`/api/patient/encounter/List`, {
    method: "POST",
    body: arg.request,
  });
}

export function useListPatientEncounters(arg?: UseListPatientEncountersArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => listPatientEncounters(arg) : undefined,
  });

  const listPatientEncountersCb = useCallback(
    (arg: ListPatientEncountersArgType) => {
      return run(() => listPatientEncounters(arg));
    },
    [run]
  );
  return {
    ...rest,
    listPatientEncounters: listPatientEncountersCb,
    patientEncounters: rest?.fetchJsonResponse?.data,
  };
}

export interface ListPatientEncountersArgType {
  request: FilterAndSortRequest;
}

export type UseListPatientEncountersArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ListPatientEncountersArgType);


import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";

export function Section({
  titleKey,
  children = null,
}: {
  titleKey: string;
  //TODO Change it to not null
  children?: ReactNode;
}) {
  const { translate } = useTranslation();
  return (
    <>
      <div className="mb-2 pb-2 fs-3 text-uppercase border-bottom border-dark border-2">
        {translate(titleKey)}
      </div>
      {children}
    </>
  );
}

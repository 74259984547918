import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Card } from "components/Card";
import { PageTitleSimple } from "components/PageLayout";
import { MeetingInfo } from "../../common/MeetingInfo";
import { WorkersCompBadge } from "../../common/WorkersCompBadge";
import { UseDataReturnType } from "../useData";

import Accordion from "react-bootstrap/Accordion";
import { IntakeForm } from "./IntakeForm";
import { PhysicalExam } from "./PhysicalExam";
import { Formik, Form, useFormikContext } from "formik";
import { DiagnosisAndTreatmentContainer } from "./diagnosis";
import { Restrictions } from "./Restrictions";
import { EstimatedSavings } from "./EstimatedSavings";
import { CtaButton } from "components/CtaButton";
import { useState } from "react";
import { EncounterDetailsModal } from "./modal";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { SecondaryButton } from "components/SecondaryButton";
import { Surgery } from "./diagnosis/Surgery";
import { Referral } from "./diagnosis/Referral";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { OpenClosedBadge } from "routes/cg/common/OpenClosedBadge";
import { Recordings } from "../Recordings";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { Loading } from "@sprint1/pkg/src/loading";

export function EncounterDetails({ data }: { data: UseDataReturnType }) {
  if (!data.selectedEncounter) {
    return null;
  }
  console.log("data.selectedEncounter", data.selectedEncounter);

  return (
    <Card>
      <Formik
        key={`${data.selectedEncounter.id}`}
        initialValues={data.selectedEncounter.data}
        onSubmit={async (updatedEncounterData) => {
          if (data.selectedEncounter) {
            data.updateEncounter({
              request: {
                encounter: {
                  ...data.selectedEncounter,
                  data: updatedEncounterData,
                  closed: true,
                },
                notifyPatient: data.shouldNotifyPatient,
                notifyWcAdmin: data.shouldNotifyWcAdmin,
              },
            });
          }
        }}
      >
        <EncounterDetailsForm data={data} />
      </Formik>
    </Card>
  );
}

function EncounterDetailsForm({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const context = useFormikContext<PatientEncounterData>();

  const [showEncounterDetailsModal, setShowEncounterDetailsModal] = useState(false);

  if (!data.selectedEncounter) {
    return null;
  }

  return (
    <Form>
      <PageTitleSimple
        left={translate("__encounterDetails")}
        right={
          <>
            {data.updateEncounterApi.isRunning && <Loading inline />}
            <WorkersCompBadge show={data.selectedEncounter.workmansComp} />
            <OpenClosedBadge open={!data.selectedEncounter.closed} className="mx-1" />

            <SecondaryButton
              className="ms-2"
              disabled={data.updateEncounterApi.isRunning}
              onClick={() => {
                if (data.selectedEncounter) {
                  data.updateEncounter({
                    request: {
                      encounter: {
                        ...data.selectedEncounter,
                        data: context.values,
                        closed: false,
                      },
                      notifyPatient: false,
                      notifyWcAdmin: false,
                    },
                    shouldRefreshEncounters: false,
                  });
                }
              }}
            >
              {translate("__saveAndContinue")}
            </SecondaryButton>
            <CtaButton
              size="small"
              type="button"
              className="btn-sm ms-2"
              onClick={() => setShowEncounterDetailsModal(true)}
              text={translate("__reviewAndCloseEncounter")}
              disabled={data.updateEncounterApi.isRunning}
            />
          </>
        }
        compact
      />

      <EncounterDetailsModalFormik
        isSuccess={data.updateEncounterApi.isSuccess}
        isRunning={data.updateEncounterApi.isRunning}
        patientEncounter={data.selectedEncounter}
        showEncounterDetailsModal={showEncounterDetailsModal}
        setShowEncounterDetailsModal={setShowEncounterDetailsModal}
        data={data}
      />
      <div className="row g-2">
        <div className="col-3">
          <MeetingInfo patientEncounter={data.selectedEncounter} />
          <Accordion className="mt-3" alwaysOpen defaultActiveKey={["IntakeForm", "PhysicalExam"]}>
            <Accordion.Item eventKey="IntakeForm">
              <Accordion.Header>{translate("__intakeForm")}</Accordion.Header>
              <Accordion.Body>
                {data.injury && data.injuryQuestionnaire && data.bodyParts && (
                  <IntakeForm
                    injury={data.injury}
                    questionnaireResponse={data.injuryQuestionnaire}
                    bodyParts={data.bodyParts}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="PhysicalExam">
              <Accordion.Header>{translate("__physicalExam")}</Accordion.Header>
              <Accordion.Body>
                <PhysicalExam data={data} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-6">
          <Accordion className="mt-3" alwaysOpen defaultActiveKey={["Diagnosis"]}>
            <Accordion.Item eventKey="Diagnosis">
              <Accordion.Header>{translate("__diagnosis&Treatment")}</Accordion.Header>
              <Accordion.Body>
                <DiagnosisAndTreatmentContainer data={data} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-3">
          <Accordion className="mt-3" alwaysOpen>
            <Accordion.Item eventKey="Restrictions">
              <Accordion.Header>{translate("__restrictions")}</Accordion.Header>
              <Accordion.Body>
                <Restrictions data={data} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="Surgery&Referral">
              <Accordion.Header>{translate("__surgery&Referral")}</Accordion.Header>
              <Accordion.Body>
                <Surgery data={data} />
                <Referral data={data} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="EstimatedSavings">
              <Accordion.Header>{translate("__estimatedSavings")}</Accordion.Header>
              <Accordion.Body>
                <EstimatedSavings data={data} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="Recordings">
              <Accordion.Header>
                {translate("__recordings")}
                <span className="mx-1">({data.selectedEncounter?.videoAppointmentData?.recordings?.length ?? 0})</span>
              </Accordion.Header>
              <Accordion.Body>
                <Recordings recordings={data.selectedEncounter?.videoAppointmentData?.recordings} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Form>
  );
}

function EncounterDetailsModalFormik({
  showEncounterDetailsModal,
  setShowEncounterDetailsModal,
  patientEncounter,
  data,
  isSuccess,
  isRunning,
}: {
  isSuccess: boolean;
  isRunning: boolean;
  data: UseDataReturnType;
  patientEncounter: MdOrthoPatientEncounter;
  showEncounterDetailsModal: boolean;
  setShowEncounterDetailsModal: (visible: boolean) => any;
}) {
  const { translate } = useTranslation();
  const context = useFormikContext<PatientEncounterData>();
  return (
    <>
      {showEncounterDetailsModal && (
        <EncounterDetailsModal
          footerRight={
            <div className="d-flex align-items-center">
              <SecondaryButton className="me-2" onClick={() => setShowEncounterDetailsModal(false)}>
                {translate("__makeChanges")}
              </SecondaryButton>
              <Field name="notifyPatient" isCheckOrRadio isInline className="ms-4">
                <S1InputField
                  type={"checkbox"}
                  checked={data.shouldNotifyPatient}
                  onChange={() => data.setShouldNotifyPatient((prev) => !prev)}
                />
                <Label className="mb-0">{translate("__notifyPatient")}</Label>
              </Field>
              {patientEncounter.workmansComp && (
                <Field name="notifyWcAdmin" isCheckOrRadio isInline className="ms-2">
                  <S1InputField
                    type={"checkbox"}
                    checked={data.shouldNotifyWcAdmin}
                    onChange={() => data.setShouldNotifyWcAdmin((prev) => !prev)}
                  />
                  <Label className="mb-0">{translate("__notifyWcAdmin")}</Label>
                </Field>
              )}
              <CtaButton
                size="small"
                text={translate("__approveAndSendToPatient")}
                type="submit"
                showSpinner={isRunning}
                disabled={isRunning}
                onClick={async () => {
                  await context.submitForm();
                  setShowEncounterDetailsModal(false);
                }}
              />
            </div>
          }
          encounterViewModel={data.encounterViewModel}
          onHide={() => setShowEncounterDetailsModal(false)}
          patientEncounter={{ ...patientEncounter, data: context.values }}
          injury={data.injury}
          bodyParts={data.bodyParts}
          questionnaireResponse={data.injuryQuestionnaire}
          diagnoses={data.diagnoses}
        />
      )}
    </>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ScheduleAppointmentStatus } from "api/types/scheduleAppointmentStatus";

export function getPaymentStatus(arg: GetPaymentStatusArgType) {
  const qs = convertToQueryString( {stripePaymentId: arg.stripePaymentId })
  return fetchJson<ScheduleAppointmentStatus>(`/api/Payment/Status${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetPaymentStatus(arg?: UseGetPaymentStatusArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getPaymentStatus(arg) : undefined,
  });

  const getPaymentStatusCb = useCallback(
    (arg: GetPaymentStatusArgType) => {
      return run(() => getPaymentStatus(arg));
    },
    [run]
  );
  return {
    ...rest,
    getPaymentStatus: getPaymentStatusCb,
    paymentStatus: rest?.fetchJsonResponse?.data,
  };
}

export interface GetPaymentStatusArgType {
  stripePaymentId: string;
}

export type UseGetPaymentStatusArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetPaymentStatusArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { PatientInjury } from "api/types/patientInjury";

export function getIncompleteInjuries(arg: GetIncompleteInjuriesArgType = {}) {
  //__queryString__
  return fetchJson<PatientInjury[]>(`/api/Injury/Incomplete`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetIncompleteInjuries(arg?: UseGetIncompleteInjuriesArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getIncompleteInjuries(arg) : undefined,
  });

  const getIncompleteInjuriesCb = useCallback(
    (arg: GetIncompleteInjuriesArgType = {}) => {
      return run(() => getIncompleteInjuries(arg));
    },
    [run]
  );
  return {
    ...rest,
    getIncompleteInjuries: getIncompleteInjuriesCb,
    incompleteInjuries: rest?.fetchJsonResponse?.data,
  };
}

export interface GetIncompleteInjuriesArgType {
  //__ArgTypeBody__
}

export type UseGetIncompleteInjuriesArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetIncompleteInjuriesArgType);


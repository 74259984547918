import { TranslateFnType } from "@sprint1/pkg/src/i18n/useTranslation";
import { PhysicalTherapyDuration } from "./physicalTherapyDuration";

export const PhysicalTherapyDurationHelper = {
  translate(duration: PhysicalTherapyDuration, translate: TranslateFnType) {
    switch (duration) {
      case PhysicalTherapyDuration.None:
        return "";
      case PhysicalTherapyDuration.OneWeek:
        return translate("__oneWeek");
      case PhysicalTherapyDuration.SixWeeks:
        return translate("__sixWeeks");
      case PhysicalTherapyDuration.ThreeMonths:
        return translate("__threeMonths");
      case PhysicalTherapyDuration.ThreeWeeks:
        return translate("__threeWeeks");
      default:
        return "";
    }
  },
};

import { uploadFileToS3 } from "@sprint1/pkg/src/upload/uploadFileToS3";
import { getDomainName } from "@sprint1/pkg/src/url/getDomainName";
import { getConfig } from "./config";

export async function uploadToS3Async(file: File) {
  const config = await getConfig();
  const { key } = await uploadFileToS3(
    file,
    config.identityPoolId,
    config.region,
    config.bucketName,
    config.uploadLocationPrefix,
    true
  );

  const urlPrefix = `https://content.${getDomainName()}`;
  const url = `${urlPrefix}/${key}`;
  return { url, key };
}

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Photo } from "api/types/photo";
import { name } from "../name";
import { UseDataReturnType } from "../useData";

export function PhysicalExam({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const photos = data?.injuryQuestionnaire?.patientResponses?.photos;
  return (
    <div>
      {photos && <PhysicalExamImages photos={photos} />}

      <div>
        <div className="d-flex justify-content-between">
          <Field name={name.physicalExam("bloodPressure", true)} className="mx-1">
            <Label title="Blood Pressure">{translate("__BP")}</Label>
            <S1InputFormikField />
          </Field>
          <Field name={name.physicalExam("heartRate", true)} className="mx-1">
            <Label title="Heart Rate">{translate("__HR")}</Label>
            <S1InputFormikField />
          </Field>

          <Field name={name.physicalExam("respiratoryRate", true)} className="mx-1">
            <Label title="Respiratory Rate">{translate("__RR")}</Label>
            <S1InputFormikField />
          </Field>
        </div>

        <div className="d-flex justify-content-between">
          <Field name={name.physicalExam("height", true)} className="mx-1">
            <Label>{translate("__height")}</Label>
            <S1InputFormikField />
          </Field>
          <Field name={name.physicalExam("weight", true)} className="mx-1">
            <Label>{translate("__weight")}</Label>
            <S1InputFormikField />
          </Field>
        </div>
      </div>
      <div className="mt-1">
        <Field name={name.physicalExam("notes", true)}>
          <Label title="Visible to only doctors.">{translate("__examNotes")}</Label>
          <TextAreaFormikField rows={3} />
        </Field>
      </div>
    </div>
  );
}

export function PhysicalExamImages({ photos }: { photos: Photo[] }) {
  const { translate } = useTranslation();
  return (
    <>
      {photos.map((p, index) => {
        return (
          <div className="d-inline-block mb-2 me-2" key={`img-${index}-${p.originalUrl}`}>
            <a href={p.originalUrl} target="__blank">
              <img
                className="img-thumbnail"
                src={p.thumbnailUrl}
                alt={translate("__physicalExam")}
                style={{
                  height: "100px",
                  width: "120px",
                  objectFit: "cover",
                }}
              />
            </a>
          </div>
        );
      })}
    </>
  );
}

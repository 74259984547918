import { PageLayout, PageTitleSimple } from "components/PageLayout";

import { Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { useSearchThreads } from "api/client/messageThread/searchThreads";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { CreateMessageModal } from "./CreateMessageModal";
import { ViewMessagesModal } from "./ViewMessagesModal";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useAppUser } from "common/useAppUser";
import { formatDate } from "./formatDate";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { formatDateTime } from "./formatDateTime";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";

import { useMarkAsRead } from "api/client/messageThread/markAsRead";
import { useMarkAsUnRead } from "api/client/messageThread/markAsUnRead";

import { Loading } from "@sprint1/pkg/src/loading";
import { useFormatName } from "common/useFormatName";

import { searchPatients } from "api/client/user/searchPatients";
import { userFilters } from "api/helpers/searchUsers.helper";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { ReSelect } from "@sprint1/pkg/src/reSelect";
import { routes } from "routes/routes.config";
import { MdOrthoMessageThread } from "api/types/mdOrthoMessageThread";
import { SearchThreadsRequest } from "api/types/searchThreadsRequest";
import { Label } from "@sprint1/pkg/src/form/label";
import { Field } from "@sprint1/pkg/src/form/field";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { ComparisonType } from "@sprint1/pkg/src/api/common/types/comparisonType";
import { AndOrOperator } from "@sprint1/pkg/src/api/common/types/andOrOperator";
import { useToggleArchived } from "api/client/messageThread/toggleArchived";

export function Messages() {
  const { translate } = useTranslation();
  const data = useData();

  if (!data.threads) {
    return <ViewPortLoading />;
  }
  return (
    <PageLayout>
      <PageTitleSimple
        title={translate("__messages")}
        right={
          <div className="d-flex align-items-center">
            <Loading
              inline
              show={data.markAsReadApi.isRunning || data.markAsUnreadApi.isRunning || data.searchThreadsApi.isRunning}
            />
            {isDefined(data.patients) && (
              <>
                <Field name="archived" isCheckOrRadio isInline>
                  <Label defaultClassName="some-dummy-class">{translate("__showArchived?")}</Label>
                  <S1InputField
                    type="checkbox"
                    value={data.showArchived}
                    onChange={(e) => {
                      data.setShowArchived((p) => !p);
                    }}
                  />
                </Field>
                <div style={{ minWidth: "20rem" }} className="mx-2">
                  <ReSelect
                    value={data.selectedPatientId}
                    options={data.patients}
                    placeholder={translate("__filterMessagesByPatient")}
                    isClearable
                    onChange={(p) => {
                      data.filterThreadsByPatient(p.selectedValue);
                    }}
                  />
                </div>
              </>
            )}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                data.setShowCreateModal(true);
              }}
            >
              {translate("__newMessage")}
            </button>
          </div>
        }
      />
      {data.showCreateModal && <CreateMessageModal data={data} />}
      {data.showMessagesModal && <ViewMessagesModal data={data} />}
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th width="10rem"></S1Table.Th>
            <S1Table.Th width="20rem"></S1Table.Th>
            <S1Table.Th>{translate("__subject")}</S1Table.Th>
            <S1Table.Th width="5rem" />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {data.threads.map((thread, index) => {
            return (
              <tr
                key={`tr-${index}-${thread.id}`}
                className={`${!thread.read ? "fw-bold" : ""} ${
                  thread.id === data.selectedThread?.id ? "table-active" : ""
                }`}
                onClick={() => {
                  data.showMessages(thread);
                }}
              >
                <td title={formatDateTime(thread.modifiedOn)}>{formatDate(thread.modifiedOn)}</td>
                <td>{`${thread.fullName}`}</td>
                <td>{thread.subject}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ActionsDropdown data={data} thread={thread} />
                </td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </PageLayout>
  );
}

function useData() {
  const appUser = useAppUser();
  const [fetchThreadsTrigger, setFetchThreadsTrigger] = useState<Date>();
  const [patients, setPatients] = useState<OptionType<string>[]>();
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [threads, setThreads] = useState<MdOrthoMessageThread[]>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const markAsReadApi = useMarkAsRead();
  const markAsUnreadApi = useMarkAsUnRead();
  const toggleArchivedApi = useToggleArchived();
  const format = useFormatName();
  const searchThreadsApi = useSearchThreads();

  const [selectedThread, setSelectedThread] = useState<MdOrthoMessageThread | undefined>();

  useRunOnMount(() => {
    async function load() {
      const qs = routes.messages.queryStrings();

      if (!(appUser.isPatient || appUser.isOnSiteNurse)) {
        const { data } = await searchPatients({
          request: userFilters.sortByFirstAndLastName(),
        });
        const patientOptions: OptionType<string>[] = data.results.map((p) => {
          return {
            label: format.formatName(p.lastName, p.firstName),
            value: p.id,
          };
        });
        setPatients(patientOptions);
      }

      setSelectedPatientId(qs.patientId);
      setFetchThreadsTrigger(new Date());
    }
    load();
  });

  const { searchThreads } = searchThreadsApi;
  useEffect(() => {
    async function run() {
      if (fetchThreadsTrigger) {
        const { data } = await searchThreads({
          request: createFiltersForThread(selectedPatientId, showArchived),
        });
        setThreads(data.results);
      }
    }

    run();
  }, [fetchThreadsTrigger, searchThreads, selectedPatientId, showArchived]);

  function refreshThreads() {
    setFetchThreadsTrigger(new Date());
  }

  async function filterThreadsByPatient(patientId?: string) {
    setSelectedPatientId(patientId);
  }

  function updateThreadModifiedOn(selectedThread: MdOrthoMessageThread) {
    const newThreads = threads?.map((thread) => {
      if (thread.id === selectedThread.id) {
        return { ...thread, modifiedOn: new Date() };
      } else {
        return thread;
      }
    });
    setThreads(
      newThreads?.sort((a, b) => {
        return new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime();
      })
    );
  }

  async function toggleArchiveThread(selectedThread: MdOrthoMessageThread) {
    await toggleArchivedApi.toggleArchived({
      request: { threadId: selectedThread.id },
    });
    setFetchThreadsTrigger(new Date());
  }

  async function toggleThreadAsRead(selectedThread: MdOrthoMessageThread) {
    if (selectedThread.read) {
      await markAsUnreadApi.markAsUnRead({
        request: { threadId: selectedThread.id },
      });
    } else {
      await markAsReadApi.markAsRead({
        request: { threadId: selectedThread.id },
      });
    }
    const newThreads = threads?.map((thread) => {
      if (thread.id === selectedThread.id) {
        return { ...thread, read: !selectedThread.read };
      } else {
        return thread;
      }
    });
    setThreads(newThreads);
  }

  function showMessages(selectedThread: MdOrthoMessageThread) {
    setSelectedThread(selectedThread);
    setShowMessagesModal(true);
  }

  return {
    showCreateModal,
    setShowCreateModal,

    showMessagesModal,
    setShowMessagesModal,
    showMessages,

    threads,

    selectedThread,
    setSelectedThread,
    updateThreadModifiedOn,
    toggleThreadAsRead,

    markAsReadApi,
    markAsUnreadApi,
    patients,

    showArchived,
    setShowArchived,

    selectedPatientId,
    filterThreadsByPatient,
    toggleArchiveThread,
    refreshThreads,
    searchThreadsApi,
  };
}

export type UseDataReturnType = ReturnType<typeof useData>;

function createFiltersForThread(patientId?: string, archived: boolean = false) {
  const name = nameof<MdOrthoMessageThread>;
  const request: SearchThreadsRequest = {
    filterFields: [
      {
        field: name("archived"),
        value: `${archived}`,
        comparisonType: ComparisonType.Equals,
        andOrOperator: AndOrOperator.And,
      },
    ],
    sortFields: [{ field: name("modifiedOn"), ascending: false }],
    patientId,
  };

  return request;
}

export function ActionsDropdown({ data, thread }: { data: UseDataReturnType; thread: MdOrthoMessageThread }) {
  const { translate } = useTranslation();
  return (
    <Dropdown className="d-inline">
      <Dropdown.Toggle bsPrefix="d-inline-flex align-items-center btn btn-outline-primary " variant="">
        <FontAwesomeIcon icon={faEllipsisH} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="reply"
          onClick={() => {
            data.setSelectedThread(thread);
            data.setShowMessagesModal(true);
          }}
        >
          {translate("__reply")}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="markAsUnread"
          onClick={() => {
            data.toggleThreadAsRead(thread);
          }}
        >
          {thread.read ? translate("__markAsUnread") : translate("__markAsRead")}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="toggleArchiveMessage"
          onClick={() => {
            data.toggleArchiveThread(thread);
          }}
        >
          {thread.archived ? translate("__unarchive") : translate("__archive")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { SelfPayRegisterResponse } from "api/types/selfPayRegisterResponse";
import { SelfPayRegisterRequest } from "api/types/selfPayRegisterRequest";

export function register(arg: RegisterArgType) {
  //__queryString__
  return fetchJson<SelfPayRegisterResponse>(`/api/SelfPay/Register`, {
    method: "POST",
    body: arg.request,
  });
}

export function useRegister(arg?: UseRegisterArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => register(arg) : undefined,
  });

  const registerCb = useCallback(
    (arg: RegisterArgType) => {
      return run(() => register(arg));
    },
    [run]
  );
  return {
    ...rest,
    register: registerCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface RegisterArgType {
  request: SelfPayRegisterRequest;
}

export type UseRegisterArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & RegisterArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";

export function getPatientEncounter(arg: GetPatientEncounterArgType) {
  const qs = convertToQueryString( {patientEncounterId: arg.patientEncounterId })
  return fetchJson<MdOrthoPatientEncounter>(`/api/patient/encounter/Get${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetPatientEncounter(arg?: UseGetPatientEncounterArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getPatientEncounter(arg) : undefined,
  });

  const getPatientEncounterCb = useCallback(
    (arg: GetPatientEncounterArgType) => {
      return run(() => getPatientEncounter(arg));
    },
    [run]
  );
  return {
    ...rest,
    getPatientEncounter: getPatientEncounterCb,
    patientEncounter: rest?.fetchJsonResponse?.data,
  };
}

export interface GetPatientEncounterArgType {
  patientEncounterId: string;
}

export type UseGetPatientEncounterArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetPatientEncounterArgType);


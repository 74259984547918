import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { CreatePatientResultRequest } from "api/types/createPatientResultRequest";

export function createPatientResult(arg: CreatePatientResultArgType) {
  //__queryString__
  return fetchJson<void>(`/api/patient/result/Create`, {
    method: "POST",
    body: arg.request,
  });
}

export function useCreatePatientResult(arg?: UseCreatePatientResultArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => createPatientResult(arg) : undefined,
  });

  const createPatientResultCb = useCallback(
    (arg: CreatePatientResultArgType) => {
      return run(() => createPatientResult(arg));
    },
    [run]
  );
  return {
    ...rest,
    createPatientResult: createPatientResultCb,
    
  };
}

export interface CreatePatientResultArgType {
  request: CreatePatientResultRequest;
}

export type UseCreatePatientResultArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CreatePatientResultArgType);


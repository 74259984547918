import { s1Date } from "@sprint1/pkg/src/date";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { getDiagnoses } from "api/client/configuration/getDiagnoses";
import { useGetEncounterViewModel } from "api/client/configuration/getEncounterViewModel";
import { encounterFilters } from "api/client/patientEncounter/listPatientEncounters.helpers";
import { useListTreatmentPlans } from "api/client/patientTreatmentPlan/listTreatmentPlans";
import { EncounterConfigurationViewModel } from "api/types/encounterConfigurationViewModel";
import { ImagingTypeHelpers } from "api/types/orderType.helper";
import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { PhysicalTherapyDurationHelper } from "api/types/physicalTherapyDuration.helper";
import { getEquipmentName } from "common/getEquipmentName";
import { useEffect, useState } from "react";
import { PageLayout } from "./components/PageLayout";
import { Section } from "./components/Section";
import { SubSection } from "./components/SubSection";
import { VideoPlayer } from "components/VideoPlayer";

export function VisitSummary() {
  const data = useData();
  const { translate } = useTranslation();
  if (!data.treatmentPlans || !data.encounterViewModel) {
    return <ViewPortLoading />;
  }

  return (
    <div className="row d-flex justify-content-center">
      <div className="col">
        <PageLayout titleKey="__visitSummary">
          <Section titleKey="__visitSummary">
            {data.treatmentPlans.results.length === 0 && <div> {translate("__youDon'tHaveAnyVisitSummary")}</div>}
            {!!data.treatmentPlans.results.length && (
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-6 ">
                  <Field name="visits" className="text-bg-light p-3 ">
                    <Label>{translate("__visits")}</Label>
                    <ReSelectField
                      value={data.selectedEncounter}
                      options={data.treatmentPlans.results.map((e) => {
                        return {
                          label: s1Date.format.tryFormatDate(e.startTime),
                          value: e,
                        };
                      })}
                      onChange={(payload) => {
                        data.setSelectedEncounter(payload.selectedOption?.value);
                      }}
                    />
                  </Field>
                </div>
              </div>
            )}

            {data.selectedEncounter && (
              <>
                <SubSection fontSize="fs-4" titleKey="__instructions">
                  <>
                    {data.selectedEncounter.data.diagnoses.length === 0 &&
                      !data.selectedEncounter.data.diagnosisNotes && <div>{translate("__none")}</div>}

                    {!!data.diagnosesAndInstructions.length &&
                      data.diagnosesAndInstructions.map((d, i) => {
                        return (
                          <div key={`diagnose-${i}-${d.diagnosis}`}>
                            <div className="fs-5">{d.diagnosis}</div>
                            {d.tips.length > 0 && (
                              <ol>
                                {d.tips.map((tip, index) => {
                                  return <li key={`instruction-${index}-${tip}`}>{tip}</li>;
                                })}
                              </ol>
                            )}
                          </div>
                        );
                      })}

                    {data.selectedEncounter.data.diagnosisNotes && (
                      <>
                        <div className="fs-5">{translate("__additionalInstructions")}</div>
                        <pre>{data.selectedEncounter.data.diagnosisNotes}</pre>
                      </>
                    )}
                  </>
                </SubSection>

                <WhatYouNeed
                  encounter={data.selectedEncounter}
                  encounterViewModel={data.encounterViewModel}
                  diagnosesAndInstructions={data.diagnosesAndInstructions}
                />

                {!!data.selectedEncounter.data.physicalTherapies?.length && (
                  <SubSection fontSize="fs-5" titleKey="__physicalTherapy">
                    <>
                      {data.selectedEncounter.data.physicalTherapies.map((p, i) => {
                        return (
                          <div key={`pt-${p.instructions}-${i}`} className="mb-3">
                            {p.instructions && (
                              <div>
                                <span className="me-2 fw-bold">{translate("__instructions")}</span>
                                <span>{p.instructions}</span>
                              </div>
                            )}
                            {p.duration && (
                              <div>
                                <span className="me-2 fw-bold">{translate("__duration")}</span>
                                <span>{PhysicalTherapyDurationHelper.translate(p.duration, translate)}</span>
                              </div>
                            )}
                            {p.treatmentVideos && (
                              <div className="d-flex  flex-column flex-md-row">
                                {p.treatmentVideos.map((m, i) => {
                                  const matchingVideo = data.encounterViewModel?.treatmentVideos?.find(
                                    (v) => v.id === m
                                  );

                                  return (
                                    <div
                                      className="p-2"
                                      style={{ minWidth: "300px", maxWidth: "450px" }}
                                      key={`media-${m}-${i}`}
                                    >
                                      {matchingVideo && <VideoPlayer videoId={matchingVideo?.videoId} />}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </SubSection>
                )}
              </>
            )}
          </Section>
        </PageLayout>
      </div>
    </div>
  );
}

function WhatYouNeed({
  encounter,
  encounterViewModel,
  diagnosesAndInstructions,
}: {
  encounter: MdOrthoPatientEncounter;
  encounterViewModel: EncounterConfigurationViewModel;
  diagnosesAndInstructions: MdOrthoDiagnosis[];
}) {
  const { translate } = useTranslation();

  if (!encounter.data.equipments.length && !encounter.data.orders.length && !encounter.data.prescriptions.length) {
    return null;
  }
  return (
    <SubSection fontSize="fs-5" titleKey="__whatYouNeed">
      {!!encounter.data.equipments.length && (
        <div>
          <div className="fs-6 fw-bold">{translate("__equipments")}</div>
          <div className="ms-2">
            {encounter.data.equipments.map((e, index) => {
              const matchingEquipmentVm = encounterViewModel.durableMedicalEquipment.find((dme) => dme.id === e.id);
              return (
                <div key={`eq-${e.id}-${index}`} className="mb-3">
                  <div className="">{getEquipmentName(e.id, encounterViewModel)}</div>
                  {matchingEquipmentVm?.imageUrl && (
                    <img
                      src={matchingEquipmentVm.imageUrl}
                      alt={matchingEquipmentVm?.name}
                      style={{
                        width: "10rem",
                        height: "10rem",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {e.instructions && <pre>{e.instructions}</pre>}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!!encounter.data.orders.length && (
        <div>
          <div className="fs-6 fw-bold">{translate("__orders")}</div>
          <div className="ms-2">
            {encounter.data.orders.map((i, index) => {
              if (!isDefined(i.type) && !i.instructions && !i.other) {
                return null;
              }
              const diagnosisName = getMatchingDiagnosis(i.icd10Codes, diagnosesAndInstructions);
              return (
                <div key={`eq-${i.type}-${index}`} className="mb-3">
                  <div>
                    <span className="fw-bold">
                      {ImagingTypeHelpers.toString({
                        type: i.type,
                        translate,
                        otherName: i.other,
                      })}
                    </span>
                    {diagnosisName && <span className="ms-1">{diagnosisName}</span>}
                  </div>
                  <pre>{i.instructions}</pre>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!!encounter.data.prescriptions.length && (
        <div>
          <div className="fs-6 fw-bold">{translate("__prescriptions")}</div>
          <div className="ms-2">
            {encounter.data.prescriptions.map((p, index) => {
              if (!isDefined(p.dispense) && !isDefined(p.medication) && !isDefined(p.refill) && !isDefined(p.sig)) {
                return null;
              }
              return (
                <div key={`eq-${p.medication}-${index}`} className="mb-3">
                  <div className="mb-2" key={`imaging-${p.medication}-${index}`}>
                    <div>
                      <span className="fw-bold">{translate("__medication/Strength")}</span>
                      <span className="ms-2">{p.medication}</span>
                    </div>
                    <div>
                      <span className="fw-bold">{translate("__SIG")}</span>
                      <span className="ms-2">{p.sig}</span>
                    </div>
                    <div>
                      <span className="fw-bold">{translate("__dispense")}</span>
                      <span className="ms-2">{p.dispense}</span>
                    </div>
                    <div>
                      <span className="fw-bold">{translate("__refills")}</span>
                      <span className="ms-2">{encounterViewModel?.refillQuantities[p.refill]}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </SubSection>
  );
}

function useData() {
  const { treatmentPlans } = useListTreatmentPlans({
    runOnMount: true,
    request: encounterFilters.getEncountersRecentToOld(),
  });
  const { encounterViewModel } = useGetEncounterViewModel({ runOnMount: true });
  const [diagnosesAndInstructions, setDiagnosesAndInstructions] = useState<MdOrthoDiagnosis[]>([]);

  const [selectedEncounter, setSelectedEncounter] = useState<MdOrthoPatientEncounter>();

  useEffect(() => {
    async function load() {
      if (treatmentPlans && treatmentPlans.results.length > 0) {
        const tmpTreatmentPlan = treatmentPlans.results[0];
        setSelectedEncounter(tmpTreatmentPlan);
        if (tmpTreatmentPlan.data.diagnoses.length > 0) {
          const { data: diagnoses } = await getDiagnoses({
            request: {
              icd10Codes: tmpTreatmentPlan.data.diagnoses.map((d) => d.icd10Code),
            },
          });
          setDiagnosesAndInstructions(diagnoses);
        }
      }
    }
    load();
  }, [treatmentPlans]);

  return {
    treatmentPlans,
    encounterViewModel,
    selectedEncounter,
    setSelectedEncounter,
    diagnosesAndInstructions,
  };
}

function getMatchingDiagnosis(icd10Codes: string[], diagnoses: MdOrthoDiagnosis[]): string | undefined {
  for (const icd10Code of icd10Codes) {
    const matching = diagnoses.find((d) => d.icd10Codes.includes(icd10Code));
    if (matching?.diagnosis) {
      return matching?.diagnosis;
    }
  }
}

import { s1Date } from "@sprint1/pkg/src/date";
import { sort } from "@sprint1/pkg/src/date/sort";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { VideoAppointmentRecording } from "api/types/videoAppointmentRecording";

export function Recordings({
  recordings,
}: {
  recordings?: VideoAppointmentRecording[];
}) {
  const { translate } = useTranslation();

  if (!recordings || !recordings.length) {
    return <div>{translate("__noRecordingsForThisEncounter")}</div>;
  }

  const sorted = recordings.sort((a, b) =>
    sort(a.startDateTime, b.startDateTime, "desc")
  );
  return (
    <div>
      {sorted.map((r, i) => {
        return (
          <div key={`result-${r.id}-${i}`} className="mb-1">
            <a href={r.s3Url} target="_blank" rel="noreferrer">
              <span> {s1Date.format.tryFormatDateTime(r.startDateTime)}</span>
            </a>
          </div>
        );
      })}
    </div>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ReviewPatientResultRequest } from "api/types/reviewPatientResultRequest";

export function reviewPatientResult(arg: ReviewPatientResultArgType) {
  //__queryString__
  return fetchJson<void>(`/api/patient/result/Review`, {
    method: "PUT",
    body: arg.request,
  });
}

export function useReviewPatientResult(arg?: UseReviewPatientResultArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => reviewPatientResult(arg) : undefined,
  });

  const reviewPatientResultCb = useCallback(
    (arg: ReviewPatientResultArgType) => {
      return run(() => reviewPatientResult(arg));
    },
    [run]
  );
  return {
    ...rest,
    reviewPatientResult: reviewPatientResultCb,
    
  };
}

export interface ReviewPatientResultArgType {
  request: ReviewPatientResultRequest;
}

export type UseReviewPatientResultArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ReviewPatientResultArgType);


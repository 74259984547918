import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { tryFormatTime } from "@sprint1/pkg/src/date/tryFormatTime";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons/faCalendarCheck";

import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";
import { useCancelPatientEncounter } from "api/client/patientEncounter/cancelPatientEncounter";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { useState } from "react";
import { ConfirmModal } from "@sprint1/pkg/src/modal/ConfirmModal";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useFormatName } from "common/useFormatName";
import { UseAppUserReturnType, useAppUser } from "common/useAppUser";
import { getAppConfigGlobal } from "common/getAppConfig";
import { BillingMethod } from "api/types/billingMethod";
import { s1Date } from "@sprint1/pkg/src/date";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { MdOrthoTenant } from "api/types/mdOrthoTenant";

export function MeetingInfo({
  patientEncounter,
  readOnly,
  displayPatientName,
  onMeetingCancelled,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  displayPatientName?: boolean;
  readOnly?: boolean;
  onMeetingCancelled?: () => any;
}) {
  const toast = useToast();
  const appUser = useAppUser();
  const { cancelPatientEncounter, isRunning } = useCancelPatientEncounter();
  const { translate } = useTranslation();
  const format = useFormatName();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tenant, setTenant] = useState<MdOrthoTenant>();

  useRunOnMount(() => {
    async function load() {
      const appConfig = await getAppConfigGlobal();
      setTenant(appConfig.tenant);
    }
    load();
  });

  if (!tenant) {
    return <ViewPortLoading />;
  }

  const canCancel = canCancelMeeting(readOnly, tenant, patientEncounter, appUser);

  return (
    <>
      <ConfirmModal
        show={showConfirmModal}
        variant="cancel"
        showSpinner={isRunning}
        noButton={{
          disabled: isRunning,
          onClick() {
            setShowConfirmModal(false);
          },
        }}
        yesButton={{
          disabled: isRunning,
          async onClick() {
            if (!patientEncounter.cancelled) {
              toast.clear();
              await cancelPatientEncounter({
                patientEncounterId: patientEncounter.id,
              });
              setShowConfirmModal(false);
              if (onMeetingCancelled) {
                onMeetingCancelled();
              }
            }
          },
        }}
      />
      <div className="d-flex mt-2 g-2">
        <div className="flex-grow-1">
          <div className="d-flex ">
            <FontAwesomeIcon
              icon={faCalendarCheck}
              size="2x"
              className="mt-1 ms-1 "
              style={{ color: "var(--bs-gray-500)" }}
            />
            <div className="ps-4">
              {displayPatientName && (
                <div className="fw-bold fs-7">
                  {format.formatName(patientEncounter.patientLastName, patientEncounter.patientFirstName)}
                </div>
              )}
              <div className="fw-bold fs-7">
                <div>
                  {tryFormatDate(patientEncounter.startTime, {
                    locales: [],
                    options: {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    },
                  })}
                </div>
                <div>
                  <span>{tryFormatTime(patientEncounter.startTime)}</span>
                  <span className="px-2">-</span>
                  <span>{tryFormatTime(patientEncounter.endTime)}</span>
                </div>
              </div>
              {canCancel && (
                <div>
                  <button
                    className="btn btn-link btn-sm ps-0 me-2"
                    onClick={() => {
                      setShowConfirmModal(true);
                    }}
                    disabled={isRunning}
                  >
                    {translate("__cancel")}
                  </button>
                  {patientEncounter && patientEncounter.patientInjuryId && (
                    <Link
                      to={
                        appUser.isDoctor || appUser.isMdOrthoNurse
                          ? routes.careGivers.scheduleAppointment.rescheduleUrl({
                              patientEncounterId: patientEncounter.id,
                            }).fullUrl
                          : routes.patient.schedule.rescheduleUrl(patientEncounter.id).fullUrl
                      }
                      className="btn btn-link btn-sm ps-0"
                    >
                      {translate("__reSchedule")}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!readOnly && (
          <div className=" d-flex justify-content-md-end  justify-content-center align-items-center">
            <a
              href={patientEncounter.joinLink}
              className="btn btn-primary btn-sm flex-grow-1 flex-md-grow-0"
              target="_blank"
              rel="noreferrer"
            >
              {translate("__startVideoCall")}
            </a>
          </div>
        )}
      </div>
    </>
  );
}

function canCancelMeeting(
  readOnly: boolean | undefined,
  tenant: MdOrthoTenant,
  patientEncounter?: MdOrthoPatientEncounter,
  appUser?: UseAppUserReturnType
) {
  if (readOnly === true || !patientEncounter || !appUser) {
    return false;
  }
  if (appUser.isPatient && tenant.data?.billingMethod === BillingMethod.PatientPay) {
    const cutOfTime = s1Date.addDays(-1, patientEncounter.startTime);
    const now = new Date();
    if (s1Date.is(now).before(cutOfTime)) {
      return true;
    }
    return false;
  }
  return true;
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { UseDataReturnType } from "../../useData";
import { Section } from ".";

export function PrescriptionSection({
  patientEncounter,
  encounterViewModel,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
}) {
  const { translate } = useTranslation();
  const nonEmptyPrescriptions = patientEncounter.data.prescriptions.filter(
    (p) => isDefined(p.dispense) || isDefined(p.medication) || isDefined(p.refill) || isDefined(p.sig)
  );
  return (
    <Section title={translate("__prescriptions")}>
      <div className="">
        {nonEmptyPrescriptions.length === 0 && <div>{translate("__none")}</div>}
        {nonEmptyPrescriptions.map((p, index) => {
          return (
            <div className="mb-2" key={`prescription-${p.medication}-${index}`}>
              <div>
                <span className="fw-bold">{translate("__medication/Strength")}</span>
                <span className="ms-2">{p.medication}</span>
              </div>
              <div>
                <span className="fw-bold">{translate("__SIG")}</span>
                <span className="ms-2">{p.sig}</span>
              </div>
              <div>
                <span className="fw-bold">{translate("__dispense")}</span>
                <span className="ms-2">{p.dispense}</span>
              </div>
              <div>
                <span className="fw-bold">{translate("__refills")}</span>
                <span className="ms-2">{encounterViewModel?.refillQuantities[p.refill]}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

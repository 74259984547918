import { sprint1ParseInt } from "@sprint1/pkg/src/parseInt";

export function getOptions(
  optionsObj?: { [key: number]: string },
  isStringArray: boolean = false
) {
  const options = [];
  options.push({ label: "", value: undefined });
  Object.entries(optionsObj ?? {}).forEach(([key, value]) => {
    const parsedKey = sprint1ParseInt(key);
    if (parsedKey !== undefined) {
      options.push({ label: value, value: isStringArray ? value : parsedKey });
    }
  });
  return options;
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";
import { GetDiagnosesRequest } from "api/types/getDiagnosesRequest";

export function getDiagnoses(arg: GetDiagnosesArgType) {
  //__queryString__
  return fetchJson<MdOrthoDiagnosis[]>(`/api/Configuration/Diagnoses`, {
    method: "POST",
    body: arg.request,
  });
}

export function useGetDiagnoses(arg?: UseGetDiagnosesArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getDiagnoses(arg) : undefined,
  });

  const getDiagnosesCb = useCallback(
    (arg: GetDiagnosesArgType) => {
      return run(() => getDiagnoses(arg));
    },
    [run]
  );
  return {
    ...rest,
    getDiagnoses: getDiagnosesCb,
    diagnoses: rest?.fetchJsonResponse?.data,
  };
}

export interface GetDiagnosesArgType {
  request: GetDiagnosesRequest;
}

export type UseGetDiagnosesArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetDiagnosesArgType);


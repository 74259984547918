import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useAppRoutes } from "routes/useRoutes";
import { Formik, Form } from "formik";

import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { Footer } from "components/Footer";
import { MdOrthoTenant } from "api/types/mdOrthoTenant";
import { useState } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { LinkButton } from "components/LinkButton";
import { CtaButton } from "components/CtaButton";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { Breadcrumb, CrumbType } from "components/Breadcrumb";
import { routes } from "routes/routes.config";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useSearchReferralPartners } from "api/client/referralPartner/searchReferralPartners";
import { ReferralPartner } from "api/types/referralPartner";
import { referralPartnerFilters } from "api/helpers/referralPartnerFilters";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useSaveReferralPartner } from "api/client/referralPartner/saveReferralPartner";
const keyof = nameof<MdOrthoTenant>;

export function EditReferralPartner() {
  const { translate } = useTranslation();
  const { referralPartner, isNew, gotoPartnersList, save, savePartnerApi } = useData();

  const title = isNew ? translate("__editReferralPartner") : translate("__addReferralPartner");

  if (!referralPartner) {
    return <ViewPortLoading />;
  }
  return (
    <PageLayout breadcrumb={<Breadcrumb crumbs={getBreadCrumbs(translate)} current={title} />}>
      <PageTitleSimple title={title} left={title} />

      <Formik
        initialValues={referralPartner}
        onSubmit={(partnerInForm) => {
          return save(partnerInForm);
        }}
      >
        <Form>
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <Field name={keyof("name")} isRequired>
                <Label>{translate("__name")}</Label>
                <S1InputFormikField type="text" autoFocus />
              </Field>

              <Footer
                variant="placeholder"
                left={
                  <LinkButton
                    variant="back"
                    onClick={(e) => {
                      gotoPartnersList();
                    }}
                  />
                }
                right={
                  <CtaButton
                    type="submit"
                    showSpinner={savePartnerApi.isRunning}
                    showSuccess={savePartnerApi.isSuccess}
                    disabled={savePartnerApi.isRunning}
                  />
                }
              />
            </div>
          </div>
        </Form>
      </Formik>
    </PageLayout>
  );
}

function useData() {
  const [isNew, setIsNew] = useState(false);
  const [referralPartner, setReferralPartner] = useState<ReferralPartner>();

  const searchPartnerApi = useSearchReferralPartners();
  const savePartnerApi = useSaveReferralPartner();
  const toast = useToast();
  const { go } = useAppRoutes();

  const { searchReferralPartners } = searchPartnerApi;
  useRunOnMount(() => {
    async function load() {
      const { referralPartnerId } = routes.admin.referralPartners.edit.getQueryStringValue();
      if (referralPartnerId) {
        const { data } = await searchReferralPartners({
          request: referralPartnerFilters.searchReferralPartner(referralPartnerId),
        });
        if (data.results.length > 0) {
          setReferralPartner(data.results[0]);
          setIsNew(false);
        } else {
          toast.error({ type: "BadUrl" });
        }
      } else {
        setReferralPartner({ id: "00000000-0000-0000-0000-000000000000", name: "" });
        setIsNew(false);
      }
    }
    load();
  });

  function gotoPartnersList() {
    go(routes.admin.referralPartners.list);
  }
  async function save(partnerInForm: ReferralPartner) {
    try {
      await savePartnerApi.saveReferralPartner({ partner: partnerInForm });
      gotoPartnersList();
    } catch (error) {
      toast.handleError(error);
    }
  }

  return { searchPartnerApi, savePartnerApi, isNew, referralPartner, save, gotoPartnersList };
}
export function getBreadCrumbs(translate: TranslateFnType): CrumbType[] {
  const crumbs: CrumbType[] = [{ text: translate("__referralPartners"), link: routes.admin.referralPartners.list }];

  return crumbs;
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";

export function listTreatmentPlans(arg: ListTreatmentPlansArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<MdOrthoPatientEncounter>>(`/api/patient/treatmentplan/List`, {
    method: "POST",
    body: arg.request,
  });
}

export function useListTreatmentPlans(arg?: UseListTreatmentPlansArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => listTreatmentPlans(arg) : undefined,
  });

  const listTreatmentPlansCb = useCallback(
    (arg: ListTreatmentPlansArgType) => {
      return run(() => listTreatmentPlans(arg));
    },
    [run]
  );
  return {
    ...rest,
    listTreatmentPlans: listTreatmentPlansCb,
    treatmentPlans: rest?.fetchJsonResponse?.data,
  };
}

export interface ListTreatmentPlansArgType {
  request: FilterAndSortRequest;
}

export type UseListTreatmentPlansArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ListTreatmentPlansArgType);


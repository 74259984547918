import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { CreateTenantResponse } from "@sprint1/pkg/src/api/auth/types/createTenantResponse";
import { MdOrthoTenant } from "api/types/mdOrthoTenant";

export function createTenant(arg: CreateTenantArgType) {
  //__queryString__
  return fetchJson<CreateTenantResponse>(`/api/Tenant/Create`, {
    method: "POST",
    body: arg.tenant,
  });
}

export function useCreateTenant(arg?: UseCreateTenantArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => createTenant(arg) : undefined,
  });

  const createTenantCb = useCallback(
    (arg: CreateTenantArgType) => {
      return run(() => createTenant(arg));
    },
    [run]
  );
  return {
    ...rest,
    createTenant: createTenantCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface CreateTenantArgType {
  tenant: MdOrthoTenant;
}

export type UseCreateTenantArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CreateTenantArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MdOrthoMessage } from "api/types/mdOrthoMessage";

export function listMessages(arg: ListMessagesArgType) {
  const qs = convertToQueryString( {threadId: arg.threadId })
  return fetchJson<MdOrthoMessage[]>(`/api/Message/List${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useListMessages(arg?: UseListMessagesArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => listMessages(arg) : undefined,
  });

  const listMessagesCb = useCallback(
    (arg: ListMessagesArgType) => {
      return run(() => listMessages(arg));
    },
    [run]
  );
  return {
    ...rest,
    listMessages: listMessagesCb,
    messages: rest?.fetchJsonResponse?.data,
  };
}

export interface ListMessagesArgType {
  threadId: string;
}

export type UseListMessagesArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ListMessagesArgType);


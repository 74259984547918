import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { PageLayout } from "../components/PageLayout";
import { Section } from "../components/Section";
import { Option } from "api/types/option";
import { name } from "./name";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { MedicalHistoryChoice } from "api/types/medicalHistoryChoice";

export interface HistorySectionType {
  titleKey: string;
  labelKey: string;
  rootJsonPath: "allergies" | "diseases" | "medications" | "surgicalHistories";
  otherLabelKey: string;
  options: Option[];
  medicalHistoryChoice: MedicalHistoryChoice;
}
export function HistorySection({ titleKey, labelKey, rootJsonPath, otherLabelKey, options }: HistorySectionType) {
  const { translate } = useTranslation();
  return (
    <PageLayout>
      <Section titleKey={titleKey}>
        {options.length > 0 && (
          <Fieldset>
            <Legend isRequired subLabel={translate("__selectAllThatApply")}>
              {translate(labelKey)}
            </Legend>
            {options.map((option, index) => {
              return (
                <Field
                  name={`${name.medicalHistory("data")}.${rootJsonPath}.${name.medicalHistoryChoice("values")}`}
                  isCheckOrRadio
                  key={`field-${index}-${option.id}`}
                >
                  <S1InputFormikField type="checkbox" parse={"asNumber"} value={option.id} formikFieldType="array" />
                  <Label isCheckOrRadio>{translate(option.name)}</Label>
                </Field>
              );
            })}
          </Fieldset>
        )}

        <Field name={`${name.medicalHistory("data")}.${rootJsonPath}.${name.medicalHistoryChoice("other")}`}>
          <Label>{translate(otherLabelKey)}</Label>
          <S1InputFormikField type="text" />
        </Field>
      </Section>
    </PageLayout>
  );
}

import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { tryFormatTime } from "@sprint1/pkg/src/date/tryFormatTime";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { BarLoader } from "@sprint1/pkg/src/loading/BarLoader";
import { DateNav } from "./DateNav";
import { UseDataReturnType } from "./useData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { WorkersCompBadge } from "../common/WorkersCompBadge";
import { useFormatName } from "common/useFormatName";
import { OpenClosedBadge } from "../common/OpenClosedBadge";

export function Appointments({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const format = useFormatName();

  if (!data.patientEncounters) {
    return <BarLoader loading={data.isAppointmentLoading} margin="mt-2" />;
  }

  return (
    <>
      <DateNav data={data} />

      <BarLoader loading={data.isAppointmentLoading} />
      <table className="table table-striped table-hover">
        <tbody>
          {data.patientEncounters.results.map((encounter, index) => {
            return (
              <tr
                key={`schedule-${encounter.patientId}-${index}`}
                className={`${data.selectedEncounter === encounter ? "bg-primary-50" : ""}`}
                role="button"
                onClick={() => {
                  data.setSelectedEncounter(encounter);
                }}
              >
                <td style={{ minWidth: "6rem" }}>{tryFormatTime(encounter.startTime)}</td>
                <td style={{ minWidth: "6rem" }}>{tryFormatDate(encounter.startTime, "shortDayMonthDate")}</td>
                <td>
                  <span>{format.formatName(encounter.patientLastName, encounter.patientFirstName)}</span>
                  {encounter.patientUserName && <span className="ms-1 fs-7">({encounter.patientUserName})</span>}
                </td>
                <td>
                  <OpenClosedBadge open={!encounter.closed} />
                </td>
                <td>{encounter.tenantName}</td>
                <td>{format.formatDoctorName(encounter.providerLastName)}</td>
                <td>
                  <WorkersCompBadge show={encounter.workmansComp} />
                </td>
                <td style={{ minWidth: "6rem" }}>
                  <span>{translate("__details")}</span>
                  <FontAwesomeIcon icon={faChevronRight} className="ps-2" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {data.patientEncounters?.results?.length === 0 && (
        <div className="text-center mt-4">{translate("__noAppointments")}</div>
      )}
    </>
  );
}

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { LinkButton } from "components/LinkButton";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { OrderType } from "api/types/orderType";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { getOptions } from "./getOptions";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";

export function Imaging({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["orders"]>({
    name: name.patientEncounterData("orders"),
  });

  const [diagnosesField] = useField<PatientEncounterData["diagnoses"]>({
    name: name.patientEncounterData("diagnoses"),
  });

  function getSelectedIcdCodesAsOptions() {
    const options: OptionType<string>[] = (diagnosesField.value ?? []).map((d) => {
      const matchingCode = (data.encounterViewModel?.icd10Codes ?? []).find((vm) => vm.code === d.icd10Code);
      return {
        label: matchingCode?.description ?? d.icd10Code,
        value: d.icd10Code,
      };
    });
    return options;
  }
  return (
    <div>
      <LinkButton
        icon={faPlus}
        text={translate("__addOrders")}
        onClick={() => {
          const tmpValue = [...field.value];
          tmpValue.push({
            icd10Codes: [],
          });
          helpers.setValue(tmpValue);
          helpers.setTouched(true);
        }}
      />
      {field.value.map((i, index) => {
        return (
          <div className="bg-light m-4 px-4 pt-4 pb-2" key={`imaging-${index}-${i.type}`}>
            <Field name={name.orders("icd10Codes", index)}>
              <Label>{translate("__diagnoses")}</Label>
              <ReSelectFormikField multiple options={getSelectedIcdCodesAsOptions()} />
            </Field>

            <Field name={name.orders("type", index)}>
              <Label>{translate("__orderType")}</Label>
              {data.encounterViewModel && (
                <SelectInputFormikField options={getOptions(data.encounterViewModel?.orderOptions)} />
              )}
            </Field>
            {i.type === OrderType.Other && (
              <Field name={name.orders("other", index)}>
                <Label>{translate("__custom")}</Label>
                <S1InputFormikField type="text" />
              </Field>
            )}

            <Field name={name.orders("instructions", index)}>
              <Label>{translate("__instructions")}</Label>
              <TextAreaFormikField />
            </Field>
            <LinkButton
              icon={faXmark}
              text={translate("__delete")}
              onClick={() => {
                const tmpImagings = field.value.filter((ti) => i !== ti);
                helpers.setValue(tmpImagings);
                helpers.setTouched(true);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { useField } from "formik";

import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { UseDataReturnType } from "../useData";
import { name } from "../name";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";

import { WorkRestrictionType } from "api/types/workRestrictionType";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { WorkRestrictionTypeHelper } from "api/types/workRestrictionType.helper";
import { PatientEncounterData } from "api/types/patientEncounterData";
export function Restrictions({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, ,] = useField<PatientEncounterData["restrictions"]>({
    name: name.patientEncounterData("restrictions"),
  });

  return (
    <div>
      <Field name={name.restrictions("workRestrictionsType", true)}>
        <Label>{translate("__restrictionType")}</Label>
        <SelectInputFormikField options={WorkRestrictionTypeHelper.toOptions(translate)} />
      </Field>
      {(field.value.workRestrictionsType === WorkRestrictionType.ReturnToWorkWithRestrictions ||
        field.value.workRestrictionsType === WorkRestrictionType.None ||
        field.value.workRestrictionsType === WorkRestrictionType.OffWork) && (
        <div>
          <Field name={name.restrictions("notes", true)}>
            <Label>{translate("__restrictions")}</Label>
            <TextAreaFormikField autoFocus />
          </Field>

          {(field.value.workRestrictionsType === WorkRestrictionType.ReturnToWorkWithRestrictions ||
            field.value.workRestrictionsType === WorkRestrictionType.None) && (
            <Field name={name.restrictions("returnToWorkDate", true)}>
              <Label>{translate("__returnToWorkOn")}</Label>
              <S1InputFormikField type="date" />
            </Field>
          )}

          {field.value.workRestrictionsType === WorkRestrictionType.OffWork && (
            <>
              <Field name={name.restrictions("offWorkStartDate", true)}>
                <Label>{translate("__startDate")}</Label>
                <S1InputFormikField type="date" />
              </Field>

              <Field name={name.restrictions("offWorkEndDate", true)}>
                <Label>{translate("__endDate")}</Label>
                <S1InputFormikField type="date" />
              </Field>
            </>
          )}
        </div>
      )}
    </div>
  );
}

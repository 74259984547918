import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { MdOrthoPatientResult } from "api/types/mdOrthoPatientResult";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";

export function listPatientResults(arg: ListPatientResultsArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<MdOrthoPatientResult>>(`/api/patient/result/List`, {
    method: "POST",
    body: arg.request,
  });
}

export function useListPatientResults(arg?: UseListPatientResultsArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => listPatientResults(arg) : undefined,
  });

  const listPatientResultsCb = useCallback(
    (arg: ListPatientResultsArgType) => {
      return run(() => listPatientResults(arg));
    },
    [run]
  );
  return {
    ...rest,
    listPatientResults: listPatientResultsCb,
    patientResults: rest?.fetchJsonResponse?.data,
  };
}

export interface ListPatientResultsArgType {
  request: FilterAndSortRequest;
}

export type UseListPatientResultsArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ListPatientResultsArgType);


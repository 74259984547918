import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { AvailabilityRulesResponse } from "api/types/availabilityRulesResponse";

export function availabilityRules(arg: AvailabilityRulesArgType = {}) {
  //__queryString__
  return fetchJson<AvailabilityRulesResponse>(`/api/Cronofy/AvailabilityRules`, {
    method: "POST",
    body: undefined,
  });
}

export function useAvailabilityRules(arg?: UseAvailabilityRulesArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => availabilityRules(arg) : undefined,
  });

  const availabilityRulesCb = useCallback(
    (arg: AvailabilityRulesArgType = {}) => {
      return run(() => availabilityRules(arg));
    },
    [run]
  );
  return {
    ...rest,
    availabilityRules: availabilityRulesCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface AvailabilityRulesArgType {
  //__ArgTypeBody__
}

export type UseAvailabilityRulesArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & AvailabilityRulesArgType);


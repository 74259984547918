import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons/faCircleUser";
import { Formik, Form } from "formik";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { LinkButton } from "components/LinkButton";
import { CtaButton } from "components/CtaButton";
import { useAppRoutes } from "routes/useRoutes";
import { SelfPayRegisterRequest } from "api/types/selfPayRegisterRequest";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { SelfPayRegisterResponseStatus } from "api/types/selfPayRegisterResponseStatus";
import { routes } from "routes/routes.config";
import { useRegister } from "api/client/selfPay/register";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";

export function SignUp() {
  const { translate } = useTranslation();
  const { goBack, go } = useAppRoutes();
  const { register, isRunning } = useRegister();
  const toast = useToast();

  return (
    <div>
      <Formik
        initialValues={getInitialValue()}
        onSubmit={async (selfPayRegisterRequest) => {
          try {
            const { data } = await register({ request: selfPayRegisterRequest });
            switch (data.status) {
              case SelfPayRegisterResponseStatus.RegisterSuccess: {
                go(routes.patient.selfPay.signUpComplete);
                return;
              }
              case SelfPayRegisterResponseStatus.UserAlreadyExists: {
                toast.error(translate("__youAlreadyHaveAnAccount"));
                return;
              }
              default: {
                toast.error({ type: "UnknownError" });
              }
            }
          } catch (error) {
            toast.handleError(error);
          }
        }}
      >
        <Form>
          <div className="row justify-content-md-center my-2 bg-white text-dark">
            <div className="col-md-4 ">
              <div className="d-grid d-md-flex justify-content-center align-items-center my-2">
                <div className="text-center">
                  <FontAwesomeIcon icon={faCircleUser} size="2x" />
                </div>
                <h5 className="ms-2 mb-0 text-uppercase">{translate("__createAccount")}</h5>
              </div>
              <div className="p-3">
                <Field name={keyof("firstName")} isRequired>
                  <Label>{translate("__firstName")}</Label>
                  <S1InputFormikField type="text" autoFocus />
                </Field>
                <Field name={keyof("lastName")} isRequired>
                  <Label>{translate("__lastName")}</Label>
                  <S1InputFormikField type="text" />
                </Field>
                <Field name={keyof("dateOfBirth")} isRequired>
                  <Label>{translate("__dob")}</Label>
                  <S1InputFormikField type="date" />
                </Field>
                <Field name={keyof("email")} isRequired>
                  <Label>{translate("__email")}</Label>
                  <S1InputFormikField type="text" />
                </Field>
                <Field name={keyof("phoneNumber")}>
                  <Label>{translate("__phoneNumber")}</Label>
                  <S1InputFormikField type="text" />
                </Field>
                <Field name="phoneNumberTerms" isCheckOrRadio isInline>
                  <S1InputField type="checkbox" />
                  <Label>{translate("__phoneNumberOptInDisclaimer")}</Label>
                </Field>
                <Field name={keyof("referralCode")}>
                  <Label>{translate("__referralCode")}</Label>

                  <S1InputFormikField type="text" />
                  {/* <div className="form-text">{translate("__referralCodeDescription")}</div> */}
                </Field>
              </div>
            </div>
          </div>
          <div className="row justify-content-b my-2 p-3">
            <div className="d-flex justify-content-between align-items-center py-2">
              <div>
                <LinkButton
                  variant="back"
                  onClick={() => {
                    goBack();
                  }}
                ></LinkButton>
              </div>
              <div>
                <CtaButton
                  showSpinner={isRunning}
                  disabled={isRunning}
                  type="submit"
                  text={translate("__createAccount")}
                />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

function getInitialValue(): SelfPayRegisterRequest {
  const referralCode = getQueryStringValue("referralCode");
  const initialSelfPayRegisterRequest: SelfPayRegisterRequest = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    referralCode,
  };
  return initialSelfPayRegisterRequest;
}

const keyof = nameof<SelfPayRegisterRequest>;

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

export interface WorkersCompBadgeType {
  show: boolean;
  className?: string;
  size?: string;
}
export function WorkersCompBadge({ show, className = "", size = "fs-9" }: WorkersCompBadgeType) {
  const { translate } = useTranslation();
  if (show === true) {
    return (
      <span className={`badge rounded-pill text-bg-secondary ${className} ${size}`} title={translate("__workersComp")}>
        {translate("__wc")}
      </span>
    );
  }
  return null;
}

import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { createFilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest.helper";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { MdOrthoUser } from "api/types/mdOrthoUser";

export const userFilters = {
  searchUser(userId: string) {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [{ field: name("id"), value: userId }],
      sortFields: [],
    });

    return request;
  },

  sortByFirstAndLastName() {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [],
      sortFields: [
        { field: name("firstName"), ascending: true },
        { field: name("lastName"), ascending: true },
      ],
    });

    return request;
  },

  sortByLastNameAndFirstName() {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [],
      sortFields: [
        { field: name("lastName"), ascending: true },
        { field: name("firstName"), ascending: true },
      ],
    });

    return request;
  },
};

const name = nameof<MdOrthoUser>;

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { BodyPart } from "api/types/bodyPart";
import { PatientInjury } from "api/types/patientInjury";
import { getBodyPartText, getInjuryDate } from "./injuryHelper";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { InjurySide } from "api/types/injurySide";
import { AlternateCare } from "api/types/alternateCare";

export interface InjuryInfoType {
  bodyParts?: BodyPart[];
  injury?: PatientInjury;
  bodyPartMargin?: string;
  injuryOccurredMargin?: string;
}
export function InjuryInfo({
  bodyParts,
  injury,
  bodyPartMargin = "mt-3",
  injuryOccurredMargin = "mt-3",
}: InjuryInfoType) {
  const { translate } = useTranslation();
  return (
    <>
      <div className={`${bodyPartMargin} fw-bold`}>{translate("__whatBodyPartBotheringYou")}</div>
      <div>{getBodyPartText(translate, bodyParts, injury)}</div>

      <div className={`${injuryOccurredMargin} fw-bold`}>{translate("__whatTypeOfVisit")}</div>
      <div>{getInjuryDate(translate, injury) ?? ""}</div>

      <div className={`${injuryOccurredMargin} fw-bold`}>{translate("__whichSide")}</div>
      <div>
        {EnumHelper.toTranslatedString({
          enumObj: InjurySide,
          translate,
          translationPrefix: "__InjurySide__",
          value: injury?.injurySide,
        }) || translate("__notSpecified")}
      </div>

      <div className={`${injuryOccurredMargin} fw-bold`}>{translate("__whereWouldYouGoForCare")}</div>
      <div>
        {EnumHelper.toTranslatedString({
          enumObj: AlternateCare,
          translate,
          translationPrefix: "__AlternateCare__",
          value: injury?.alternateCare,
        }) || translate("__notSpecified")}
      </div>
    </>
  );
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Loading } from "@sprint1/pkg/src/loading";
import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { DateFilter } from "@sprint1/pkg/src/table/filters/DateFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
export function Orders() {
  const { translate } = useTranslation();

  return (
    <PageLayout>
      <PageTitleSimple
        title={translate("__orders")}
        left={translate("__orders")}
      />

      <Table />
    </PageLayout>
  );
}

function useData() {
  const filtersAndSort = useFiltersAndSort();

  return { orders: fakeOrders, filtersAndSort };
}

function Table() {
  const { orders, filtersAndSort } = useData();

  const { translate } = useTranslation();

  if (!orders) return <Loading />;

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              label={translate("__firstName")}
              filter={
                <TextFilter
                  name={name("firstName")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />
            <S1Table.Th
              label={translate("__lastName")}
              filter={
                <TextFilter
                  name={name("lastName")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />

            <S1Table.Th
              label={translate("__employeeId")}
              filter={
                <TextFilter
                  name={name("employeeId")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />
            <S1Table.Th
              label={translate("__organization")}
              filter={
                <TextFilter
                  name={name("tenantName")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />
            <S1Table.Th
              label={translate("__injuryDate")}
              filter={
                <DateFilter
                  name={name("tenantName")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />
            <S1Table.Th
              label={translate("__apptDate")}
              filter={
                <DateFilter
                  name={name("appointmentDate")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />

            <S1Table.Th
              label={translate("__orders")}
              filter={
                <TextFilter
                  name={name("orders")}
                  filtersAndSort={filtersAndSort}
                />
              }
            />
            <S1Table.Th label={translate("__status")} />
            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {orders.map((workersComp, index) => {
            return (
              <tr
                key={`tr-${index}-${workersComp.employeeId}-${workersComp.tenantName}`}
              >
                <td>{workersComp.firstName}</td>
                <td>{workersComp.lastName}</td>
                <td>{workersComp.employeeId}</td>
                <td>{workersComp.tenantName}</td>
                <td>{`${tryFormatDate(workersComp.injuryDate)}`}</td>
                <td>{`${tryFormatDate(workersComp.appointmentDate)}`}</td>
                {/* //TODO Change to enum */}
                <td>{workersComp.orders?.join(", ")}</td>
                <td className="text-center">
                  {workersComp.completed ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                      title={translate("__completedOn", {
                        date: tryFormatDate(workersComp.completedOn),
                      })}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-danger"
                      title={translate("__needsReview")}
                    />
                  )}
                </td>
                <td>
                  <ActionMenuSimple>
                    <ActionMenuSimple.MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        //TODO Mark order as completed
                      }}
                    >
                      {translate("__markAsCompleted")}
                    </ActionMenuSimple.MenuItem>
                  </ActionMenuSimple>
                </td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

const name = nameof<OrderType>;
export interface OrderType {
  firstName: string;
  lastName: string;
  employeeId: string;
  tenantName: string;
  injuryDate: Date;
  appointmentDate: Date;
  orders: number[];
  completed: boolean;
  completedOn?: Date;
}

const fakeOrders: OrderType[] = [
  {
    firstName: "John",
    lastName: "Doe",
    employeeId: "1",
    appointmentDate: new Date(),
    injuryDate: new Date(),
    tenantName: "Sprint1",
    completed: false,
    orders: [1, 2],
  },
  {
    firstName: "Jane",
    lastName: "Doe",
    employeeId: "2",
    appointmentDate: new Date(),
    injuryDate: new Date(),
    completed: true,
    completedOn: new Date(),
    orders: [1, 2],
    tenantName: "Sprint1",
  },
];

import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Loading } from "@sprint1/pkg/src/loading";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { name } from "../../name";
import { UseDataReturnType } from "../../useData";
import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { Diagnosis as DiagnosisType } from "api/types/diagnosis";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import Accordion from "react-bootstrap/Accordion";
import { getTips } from "routes/cg/common/getTipsForIcdCodes";

export function Diagnosis({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["diagnoses"] | undefined>({
    name: name.patientEncounterData("diagnoses"),
  });

  if (!data.encounterViewModel) {
    return <Loading />;
  }

  const options = data.encounterViewModel.icd10Codes.map((c) => {
    return { label: `${c.code} ${c.description}`, value: c.code };
  });

  const icdCodes = (field.value ?? []).map((d) => d.icd10Code);
  const tipsForIcdCodes = getTips(data.diagnoses, icdCodes);
  return (
    <div>
      <Field name={name.diagnoses("icd10Code", true)}>
        <Label>{translate("__icd10Codes")}</Label>
        <ReSelectField
          multiple
          value={icdCodes}
          onChange={({ selectedOptions }) => {
            const newDiagnosis: DiagnosisType[] = [];
            selectedOptions.forEach((o) => {
              newDiagnosis.push({ icd10Code: o.value });
            });
            helpers.setValue(newDiagnosis);
            helpers.setTouched(true);
          }}
          options={options}
        />
      </Field>

      {!!tipsForIcdCodes?.length && (
        <Accordion className="my-3">
          <Accordion.Item eventKey="tips">
            <Accordion.Header>{translate("__instructions")}</Accordion.Header>
            <Accordion.Body>
              <div>
                {tipsForIcdCodes.map((t, index) => {
                  return (
                    <div key={`tip-${t.diagnose}-${index}`}>
                      <div>{t.diagnose}</div>
                      <ul>
                        {t.tips.map((t, i) => (
                          <li key={`tip-${t}-${i}`}>{t}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Field name={name.patientEncounterData("diagnosisNotes")}>
        <Label>{translate("__additionalInstructionsForPatient")}</Label>
        <TextAreaFormikField />
      </Field>
    </div>
  );
}

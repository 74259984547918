import { useState } from "react";
import { Field } from "@sprint1/pkg/src/form/field";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { authRoutes } from "@sprint1/pkg/src/auth/routes";

import Accordion from "react-bootstrap/Accordion";
import { Trans } from "react-i18next";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";

export function Login() {
  const { translate } = useTranslation();
  const [userName, setUserName] = useState("");
  return (
    <section style={{ height: "calc(100vh - var(--sprint1-header-height))" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="text-center pb-3">
              <img
                src="/public/email_logo.png"
                className="d-inline-block align-top"
                alt="Logo"
                style={{ height: "100px" }}
              />

              <div className="fs-5 text-center fst-italic pt-2 fw-bolder">{translate("__betterHealthStartsHere")}</div>
            </div>
            <div className="card shadow-2-strong">
              <div className="card-body p-5">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (userName) {
                      const returnUrl = getQueryStringValue("returnUrl");
                      authRoutes.routes.loginWithUsername.redirect({ userNameOrEmail: userName, returnUrl: returnUrl });
                    }
                  }}
                >
                  <Field name="Email" isRequired>
                    <Label>{translate("__emailOrUsername")}</Label>
                    <S1InputField value={userName} onChange={(e) => setUserName(e.target.value)} autoFocus />
                  </Field>
                  <div className="text-center">
                    <button type="submit" className="btn btn-warning mt-3">
                      {translate("__login")}
                    </button>
                  </div>
                </form>

                <div>
                  <div className="text-center mt-3">
                    <a href={authRoutes.routes.forgotPassword.path}>{translate("__forgotPassword?")}</a>
                  </div>

                  <div className="border border-0 border-top mt-3">
                    <Accordion flush className="">
                      <Accordion.Item eventKey="NoAccount">
                        <Accordion.Header>{translate("__havingTroubleLoggingIn?")}</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <Trans i18nKey="__firstTimeLoggingIn?">
                                <strong> First time logging in? </strong>
                                <em> Login </em>
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="__forgotYourPassword?">
                                <strong />
                                <em />
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="__forgotYourUsername?">
                                <strong />
                                <strong />
                              </Trans>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

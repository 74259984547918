import { Field } from "@sprint1/pkg/src/form/field";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UploadButton } from "@sprint1/pkg/src/upload/UploadButton";
import { uploadToS3Async } from "common/uploadToS3";
import { CtaButton } from "components/CtaButton";
import { LinkButton } from "components/LinkButton";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { UseDataReturnType } from ".";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { TextAreaField } from "@sprint1/pkg/src/form/textArea/Field";
import { useCreateMessageThread } from "api/client/messageThread/createMessageThread";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { useGetAuthorizations } from "api/client/messageThread/getAuthorizations";
import { useSearchCareTeams } from "api/client/careTeam/searchCareTeams";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { MessageAuthorization } from "api/types/messageAuthorization";
import { useAppUser } from "common/useAppUser";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { dateOfBirth } from "@sprint1/pkg/src/date/dateOfBirth";

export function CreateMessageModal({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const modalData = useCreateModalData();
  const userOptions = getAuthorizations();
  return (
    <Modal
      size="lg"
      centered
      show={data.showCreateModal}
      onHide={() => {
        data.setShowCreateModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{translate("__newMessage")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Field name="to" isRequired>
          <Label>{translate("__to")}</Label>

          <ReSelectField
            autoFocus
            value={modalData.toUserId}
            options={userOptions}
            onChange={(e) => modalData.setToUser(e.selectedOption?.meta)}
          />
        </Field>

        {!!modalData.careTeamsApi.careTeams?.results?.length && (
          <Field name="careTeam" isRequired>
            <Label>{translate("__careTeam")}</Label>
            <ReSelectField
              value={modalData.careTeamId}
              options={modalData.careTeamsApi.careTeams.results.map((c) => ({ label: c.name, value: c.id }))}
              onChange={(e) => modalData.setCareTeamId(e.selectedValue)}
            />
          </Field>
        )}

        <Field name="subject" isRequired>
          <Label>{translate("__subject")}</Label>
          <S1InputField value={modalData.subject} onChange={(e) => modalData.setSubject(e.target.value)} />
        </Field>
        <Field name="message" isRequired>
          <Label>{translate("__message")}</Label>
          <TextAreaField value={modalData.message} onChange={(e) => modalData.setMessage(e.target.value)} />
        </Field>
        <UploadButton
          uploadFileAsync={async ({ file }) => {
            const { url } = await uploadToS3Async(file);
            modalData.setFileName(file.name);
            modalData.setFileUrl(url);
          }}
        />
        {modalData.fileUrl && modalData.fileName && (
          <div className="mt-3">
            <a href={modalData.fileUrl} target="__blank">
              {modalData.fileName}
            </a>
            <button
              className="btn btn-sm btn-link ms-1"
              onClick={() => {
                modalData.setFileUrl(undefined);
                modalData.setFileName("");
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div>
            <LinkButton
              variant="cancel"
              onClick={() => {
                data.setShowCreateModal(false);
              }}
            />
          </div>
          <div>
            <CtaButton
              text={translate("__sendMessage")}
              showSpinner={modalData.isRunning}
              showSuccess={modalData.isSuccess}
              disabled={modalData.isRunning}
              onClick={async () => {
                if (await modalData.createMessage()) {
                  data.refreshThreads();
                  data.setShowCreateModal(false);
                }
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );

  function getAuthorizations() {
    const authorizations = modalData.getAuthorizationApi.authorizations;

    const getOptions = (
      type: CareTeamOptionType["type"],
      users?: MessageAuthorization[]
    ): OptionType<string, string, CareTeamOptionType>[] => {
      return (users ?? []).map((p, i) => {
        const dob = p.dateOfBirth ? ` | ${dateOfBirth.tryFormat(p.dateOfBirth)}` : "";
        const email = p.email ? ` | ${p.email}` : "";
        return {
          label: `${p.name} | ${p.tenantName} ${
            type === "provider" ? `| ${translate("__provider")}` : ""
          }${dob}${email}`,
          meta: { id: p.id, type },
          value: p.id,
        };
      });
    };

    const users = [
      ...getOptions("patient", authorizations?.patients),
      ...getOptions("provider", authorizations?.providers),
    ];

    return users;
  }
}

function useCreateModalData() {
  const toast = useToast();
  const { translate } = useTranslation();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [toUserId, setToUserId] = useState("");
  const [careTeamId, setCareTeamId] = useState<string | undefined>();
  const appUser = useAppUser();

  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState("");
  const getAuthorizationApi = useGetAuthorizations();
  const careTeamsApi = useSearchCareTeams();
  const { createMessageThread, isRunning, isSuccess } = useCreateMessageThread();

  useRunOnMount(() => {
    async function load() {
      getAuthorizationApi.getAuthorizations();
    }
    load();
  });

  async function setSelectedUser(user?: CareTeamOptionType) {
    setToUserId(user?.id ?? "");
    if (user?.type === "patient" && !appUser.isDoctor && !careTeamsApi.careTeams && !careTeamsApi.isRunning) {
      //We want to fetch care teams only for nurses. A nurse can belong to multiple care teams.
      careTeamsApi.searchCareTeams({ request: { filterFields: [], sortFields: [] } });
    } else {
      setCareTeamId(undefined);
    }
  }

  async function createMessage(): Promise<boolean> {
    toast.clear();
    if (!toUserId) {
      toast.error(translate("__pleaseSelectUserToSendMessage"));
      return false;
    }
    await createMessageThread({
      request: {
        message: {
          body: message,
          attachments: fileUrl ? [{ temporaryPath: fileUrl }] : [],
        },
        fromCareTeamId: careTeamId,
        subject: subject,
        toUserId: toUserId,
      },
    });
    return true;
  }

  return {
    toUserId,
    setToUserId,
    setToUser: setSelectedUser,

    subject,
    setSubject,

    message,
    setMessage,

    fileUrl,
    setFileUrl,

    fileName,
    setFileName,

    createMessage,
    isRunning,
    isSuccess,
    getAuthorizationApi,

    careTeamsApi,
    careTeamId,
    setCareTeamId,
  };
}

type CareTeamOptionType = {
  id: string;
  type: "patient" | "provider";
};

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { adminRoutes, patientRoutes } from "./routes.config";

export type GoPayload =
  | string
  | {
      pathname: string;
      search?: string;
      delay?: boolean;
      replace?: boolean;
    };
export function useAppRoutes() {
  const navigate = useNavigate();

  const go = useCallback(
    (urlOrObject: GoPayload) => {
      const navigatePayload = typeof urlOrObject === "string" ? { pathname: urlOrObject } : urlOrObject;

      if (navigatePayload.delay) {
        setTimeout(() => {
          navigate(navigatePayload);
        }, 750);
      } else {
        navigate(navigatePayload);
      }
    },
    [navigate]
  );

  const goToPatientRoutes = useCallback(
    (
      key: keyof Omit<
        typeof patientRoutes,
        "medicalHistory" | "questionnaire" | "schedule" | "visitSummary" | "selfPay" | "selectBodyPart"
      >
    ) => {
      navigate({
        pathname: patientRoutes[key],
      });
    },
    [navigate]
  );

  const goBack = useCallback(
    (delayed: boolean = true) => {
      if (delayed) {
        setTimeout(() => {
          navigate(-1);
        }, 750);
      } else {
        navigate(-1);
      }
    },
    [navigate]
  );

  return {
    goBack,
    goToPatientRoutes,
    go,
    navigate,
    patientRoutes,
    adminRoutes,
  };
}

import { useCallback, useEffect } from "react";

export function useSetDocumentTitle(title?: string, shouldClear?: boolean) {
  const setTitle = useCallback((title: string) => {
    document.title = `${title}`;
  }, []);

  const clear = useCallback(() => {
    document.title = ``;
  }, []);

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
    if (shouldClear) {
      clear();
    }
  }, [clear, setTitle, shouldClear, title]);

  return { setTitle, clear };
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Section } from ".";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";

export function SurgeryInstructionSection({ patientEncounter }: { patientEncounter: MdOrthoPatientEncounter }) {
  const { translate } = useTranslation();
  return (
    <Section title={translate("__surgeryInstructions")}>
      {patientEncounter.data.surgery.instructions ? (
        <pre>{patientEncounter.data.surgery.instructions}</pre>
      ) : (
        translate("__none")
      )}
    </Section>
  );
}

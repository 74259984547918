import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Card } from "components/Card";
import { Link } from "react-router-dom";
import { PageTitleSimple } from "components/PageLayout";
import { Loading } from "@sprint1/pkg/src/loading";
import { UseDataReturnType } from "./useData";
import { routes } from "routes/routes.config";
import { WorkersCompBadge } from "../common/WorkersCompBadge";
import { InjuryInfo } from "../common/InjuryInfo";

export function AppointmentDetails({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();

  if (!data.selectedEncounter || !data.bodyParts || !data.injury) {
    return <Loading />;
  }
  return (
    <Card classNames="mt-4">
      <PageTitleSimple
        compact
        left={
          <>
            <PageTitleSimple.Text
              title={
                <div className="d-flex align-items-center">
                  <span>{translate("__appointmentDetails")}</span>
                  <WorkersCompBadge className="ms-1" show={data.selectedEncounter.workmansComp} />
                </div>
              }
            />
          </>
        }
        right={
          <Link
            to={routes.careGivers.patientsDetails.url(data.selectedEncounter.patientId).fullUrl}
            className="btn btn-outline-primary btn-sm "
          >
            {translate("__viewPatientRecord")}
          </Link>
        }
      />
      <InjuryInfo bodyParts={data?.bodyParts} injury={data?.injury} />
    </Card>
  );
}

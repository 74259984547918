import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { PatientMedicalHistory } from "api/types/patientMedicalHistory";

export function updateMedicalHistory(arg: UpdateMedicalHistoryArgType) {
  //__queryString__
  return fetchJson<void>(`/api/patient/medicalhistory/Update`, {
    method: "PUT",
    body: arg.patientMedicalHistory,
  });
}

export function useUpdateMedicalHistory(arg?: UseUpdateMedicalHistoryArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => updateMedicalHistory(arg) : undefined,
  });

  const updateMedicalHistoryCb = useCallback(
    (arg: UpdateMedicalHistoryArgType) => {
      return run(() => updateMedicalHistory(arg));
    },
    [run]
  );
  return {
    ...rest,
    updateMedicalHistory: updateMedicalHistoryCb,
    
  };
}

export interface UpdateMedicalHistoryArgType {
  patientMedicalHistory: PatientMedicalHistory;
}

export type UseUpdateMedicalHistoryArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & UpdateMedicalHistoryArgType);


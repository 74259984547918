import { RefillQuantity } from "./refillQuantity";

export const RefillQuantityHelpers = {
  toNumber(refillQuantity: RefillQuantity) {
    switch (refillQuantity) {
      case RefillQuantity.None:
        return 0;
      case RefillQuantity.One:
        return 1;
      case RefillQuantity.Two:
        return 2;
      case RefillQuantity.Three:
        return 3;
      default:
        break;
    }
  },
};

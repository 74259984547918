import { AndOrOperator } from "@sprint1/pkg/src/api/common/types/andOrOperator";
import { ComparisonType } from "@sprint1/pkg/src/api/common/types/comparisonType";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";

const name = nameof<MdOrthoPatientEncounter>;

export const encounterFilters = {
  filterByPatient(patientId: string): FilterAndSortRequest {
    return {
      filterFields: [
        {
          field: name("patientId"),
          value: patientId,
          comparisonType: ComparisonType.Equals,
          andOrOperator: AndOrOperator.And,
        },
      ],
      sortFields: [{ field: name("startTime"), ascending: false }],
    };
  },
  getEncountersRecentToOld(): FilterAndSortRequest {
    return {
      filterFields: [
        {
          field: name("closed"),
          value: "true",
          comparisonType: ComparisonType.Equals,
          andOrOperator: AndOrOperator.And,
        },
      ],
      sortFields: [{ field: name("startTime"), ascending: false }],
    };
  },
};

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { ReferralPartner } from "api/types/referralPartner";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";

export function searchReferralPartners(arg: SearchReferralPartnersArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<ReferralPartner>>(`/api/ReferralPartner/Search`, {
    method: "POST",
    body: arg.request,
  });
}

export function useSearchReferralPartners(arg?: UseSearchReferralPartnersArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => searchReferralPartners(arg) : undefined,
  });

  const searchReferralPartnersCb = useCallback(
    (arg: SearchReferralPartnersArgType) => {
      return run(() => searchReferralPartners(arg));
    },
    [run]
  );
  return {
    ...rest,
    searchReferralPartners: searchReferralPartnersCb,
    referralPartners: rest?.fetchJsonResponse?.data,
  };
}

export interface SearchReferralPartnersArgType {
  request: FilterAndSortRequest;
}

export type UseSearchReferralPartnersArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & SearchReferralPartnersArgType);


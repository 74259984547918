import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { BodyPart } from "api/types/bodyPart";

export function getBodyParts(arg: GetBodyPartsArgType = {}) {
  //__queryString__
  return fetchJson<BodyPart[]>(`/api/Body/parts`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetBodyParts(arg?: UseGetBodyPartsArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getBodyParts(arg) : undefined,
  });

  const getBodyPartsCb = useCallback(
    (arg: GetBodyPartsArgType = {}) => {
      return run(() => getBodyParts(arg));
    },
    [run]
  );
  return {
    ...rest,
    getBodyParts: getBodyPartsCb,
    bodyParts: rest?.fetchJsonResponse?.data,
  };
}

export interface GetBodyPartsArgType {
  //__ArgTypeBody__
}

export type UseGetBodyPartsArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetBodyPartsArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MdOrthoUser } from "api/types/mdOrthoUser";

export function tryGetLoggedInUser(arg: TryGetLoggedInUserArgType = {}) {
  //__queryString__
  return fetchJson<MdOrthoUser>(`/api/User/TryGetLoggedInUser`, {
    method: "GET",
    body: undefined,
  });
}

export function useTryGetLoggedInUser(arg?: UseTryGetLoggedInUserArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => tryGetLoggedInUser(arg) : undefined,
  });

  const tryGetLoggedInUserCb = useCallback(
    (arg: TryGetLoggedInUserArgType = {}) => {
      return run(() => tryGetLoggedInUser(arg));
    },
    [run]
  );
  return {
    ...rest,
    tryGetLoggedInUser: tryGetLoggedInUserCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface TryGetLoggedInUserArgType {
  //__ArgTypeBody__
}

export type UseTryGetLoggedInUserArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & TryGetLoggedInUserArgType);


import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { SecondaryButton } from "components/SecondaryButton";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Link } from "react-router-dom";
import { Loading } from "@sprint1/pkg/src/loading";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { useListPatientResults } from "api/client/patientResult/listPatientResults";
import { MdOrthoPatientResult } from "api/types/mdOrthoPatientResult";
import { s1Date } from "@sprint1/pkg/src/date";

import Modal from "react-bootstrap/Modal";
import React from "react";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { ImagingTypeHelpers } from "api/types/orderType.helper";
import { OrderType } from "api/types/orderType";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useFormatName } from "common/useFormatName";
import { UploadedFileType, UploadFiles } from "common/UploadFiles";
import { useCreatePatientResult } from "api/client/patientResult/createPatientResult";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { CtaButton } from "components/CtaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { routes } from "routes/routes.config";
import { useReviewPatientResult } from "api/client/patientResult/reviewPatientResult";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { ComparisonType } from "@sprint1/pkg/src/api/common/types/comparisonType";
import { AndOrOperator } from "@sprint1/pkg/src/api/common/types/andOrOperator";
import { FilterField } from "@sprint1/pkg/src/api/common/types/filterField";
import { useSearchPatients } from "api/client/user/searchPatients";
import { userFilters } from "api/helpers/searchUsers.helper";

export function Results() {
  const { translate } = useTranslation();
  const data = useData();
  return (
    <PageLayout>
      <PageTitleSimple
        title={translate("__results")}
        left={translate("__results")}
        right={
          <>
            <Loading
              inline
              show={data.resultsApi.isRunning || data.reviewApi.isRunning || data.filtersAndSort.isDebouncing}
            />

            <Field name="showReviewed" isCheckOrRadio className="mx-2">
              <S1InputField
                type="checkbox"
                parse="asBoolean"
                value={data.showOnlyUnReviewed}
                onClick={() => {
                  data.toggleShowReviewed();
                }}
              />
              <Label isCheckOrRadio>{translate("__showAllResults?")}</Label>
            </Field>

            <SecondaryButton
              onClick={() => {
                data.setShowUploadModal(true);
              }}
            >
              {translate("__uploadResult")}
            </SecondaryButton>
          </>
        }
      />
      <UploadResultsModal data={data} />
      <Table {...data} />
    </PageLayout>
  );
}

function useData() {
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const [showAllResults, setShowAllResults] = React.useState(false);

  const initialFasr = {
    filterFields: [
      {
        field: name("reviewedBy"),
        value: "NULL",
        comparisonType: ComparisonType.Equals,
        andOrOperator: AndOrOperator.None,
      },
    ],
    sortFields: [{ field: name("createdOn"), ascending: false }],
  };
  const filtersAndSort = useFiltersAndSort(initialFasr);
  const reviewApi = useReviewPatientResult();
  const resultsApi = useListPatientResults({ runOnMount: false });

  const { filtersDebounced } = filtersAndSort;
  const { listPatientResults } = resultsApi;
  React.useEffect(() => {
    listPatientResults({ request: filtersDebounced });
  }, [filtersDebounced, listPatientResults]);

  const toggleShowReviewed = React.useCallback(() => {
    setShowAllResults((p) => {
      const newShowAllResults = !p;

      if (newShowAllResults) {
        filtersAndSort.removeFilter(name("reviewedBy"));
      } else {
        const reviewedByFilter: FilterField = {
          field: name("reviewedBy"),
          value: "NULL",
          comparisonType: ComparisonType.Equals,
          andOrOperator: AndOrOperator.None,
        };
        filtersAndSort.setFilter({ filterField: reviewedByFilter });
      }

      return newShowAllResults;
    });
  }, [filtersAndSort]);

  const refreshResults = React.useCallback(() => {
    resultsApi.listPatientResults({ request: filtersAndSort.filters });
  }, [filtersAndSort.filters, resultsApi]);

  return {
    resultsApi,
    patientResults: resultsApi?.patientResults,
    filtersAndSort,
    showUploadModal,
    setShowUploadModal,
    refreshResults,
    reviewApi,

    showOnlyUnReviewed: showAllResults,
    setShowONlyUnReviewed: setShowAllResults,
    toggleShowReviewed,
  };
}

type UseDataReturnType = ReturnType<typeof useData>;

function Table(data: UseDataReturnType) {
  const { translate } = useTranslation();
  const formatName = useFormatName();

  if (!data.patientResults) return <Loading />;

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th label={translate("__createdOn")} />
            <S1Table.Th label={translate("__type")} />

            <S1Table.Th
              label={translate("__firstName")}
              filter={<TextFilter name={name("patientFirstName")} filtersAndSort={data.filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__lastName")}
              filter={<TextFilter name={name("patientLastName")} filtersAndSort={data.filtersAndSort} />}
            />

            <S1Table.Th
              label={translate("__employeeId")}
              filter={<TextFilter name={name("patientUserName")} filtersAndSort={data.filtersAndSort} />}
            />
            <S1Table.Th
              label={translate("__organization")}
              filter={<TextFilter name={name("tenantName")} filtersAndSort={data.filtersAndSort} />}
            />
            <S1Table.Th label={translate("__attachment")} />
            <S1Table.Th label={translate("__reviewedBy")} />

            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {data.patientResults.results.map((result, index) => {
            return (
              <tr key={`tr-${index}-${result.patientId}-${result.resultUrl}`}>
                <td>{s1Date.format.tryFormatDate(result.createdOn)}</td>
                <td>
                  {ImagingTypeHelpers.toString({
                    type: result.type,
                    translate,
                  })}
                </td>
                <td>{result.patientFirstName}</td>
                <td>{result.patientLastName}</td>
                <td>{result.patientUserName}</td>

                <td>{result.tenantName}</td>
                <td className="text-center">
                  {result.resultUrl && (
                    <a href={result.resultUrl} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFile} />
                    </a>
                  )}
                </td>
                <td>{formatName.formatName(result.reviewerLastName, result.reviewerFirstName)}</td>
                <td>
                  <ActionMenuSimple>
                    {result.patientId && (
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={routes.careGivers.patientsDetails.url(result.patientId).fullUrl}
                      >
                        {translate("__viewPatientDetails")}
                      </ActionMenuSimple.MenuItem>
                    )}
                    <ActionMenuSimple.MenuItem as={Link} to={routes.messages.url(result.patientId).fullUrl}>
                      {translate("__messagePatient")}
                    </ActionMenuSimple.MenuItem>

                    <ActionMenuSimple.MenuItem
                      onClick={async () => {
                        await data.reviewApi.reviewPatientResult({
                          request: { id: result.id },
                        });
                        data.refreshResults();
                      }}
                    >
                      {translate("__markAsReviewed")}
                    </ActionMenuSimple.MenuItem>
                  </ActionMenuSimple>
                </td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

function UploadResultsModal({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const patientsApi = useSearchPatients({
    runOnMount: true,
    request: userFilters.sortByFirstAndLastName(),
  });
  const createApi = useCreatePatientResult();
  const format = useFormatName();
  const toast = useToast();

  const [selectedOrderType, setSelectedOrderType] = React.useState<OrderType>();
  const [selectedPatient, setSelectedPatient] = React.useState<MdOrthoUser>();
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFileType[]>([]);

  if (!data.showUploadModal) return null;
  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        data.setShowUploadModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{translate("__uploadResult")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Field name="imagingType" isRequired>
          <Label>{translate("__type")}</Label>
          <ReSelectField
            options={ImagingTypeHelpers.convertToOptions(translate)}
            value={selectedOrderType}
            onChange={(payload) => {
              setSelectedOrderType(payload.selectedValue);
            }}
          />
        </Field>
        {patientsApi.patients?.results && (
          <Field name="selectedPatient" isRequired>
            <Label>{translate("__patient")}</Label>
            <ReSelectField
              options={patientsApi.patients.results.map((p) => ({
                label: format.formatName(p.lastName, p.firstName),
                value: p,
              }))}
              value={selectedPatient}
              onChange={(payload) => {
                setSelectedPatient(payload.selectedValue);
              }}
            />
          </Field>
        )}
        <UploadFiles
          fileInput={{ multiple: false }}
          onChange={({ files }) => {
            setUploadedFiles(files);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <CtaButton
          size="small"
          disabled={createApi.isRunning}
          showSpinner={createApi.isRunning}
          showSuccess={createApi.isSuccess}
          onClick={async () => {
            toast.clear();
            if (selectedOrderType === undefined || selectedPatient === undefined || uploadedFiles?.length <= 0) {
              toast.error(translate("__pleaseFillInAllRequiredFields"));
              return;
            }
            await createApi.createPatientResult({
              request: {
                imagingType: selectedOrderType,
                patientId: selectedPatient.id,
                temporaryFileLocation: uploadedFiles[0].key,
              },
            });
            data.refreshResults();
            data.setShowUploadModal(false);
          }}
        >
          {translate("__uploadResult")}
        </CtaButton>
      </Modal.Footer>
    </Modal>
  );
}

const name = nameof<MdOrthoPatientResult>;

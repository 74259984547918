import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MedicalHistoryConfiguration } from "api/types/medicalHistoryConfiguration";

export function getMedicalHistoryConfiguration(arg: GetMedicalHistoryConfigurationArgType = {}) {
  //__queryString__
  return fetchJson<MedicalHistoryConfiguration>(`/api/Configuration/MedicalHistory`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetMedicalHistoryConfiguration(arg?: UseGetMedicalHistoryConfigurationArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getMedicalHistoryConfiguration(arg) : undefined,
  });

  const getMedicalHistoryConfigurationCb = useCallback(
    (arg: GetMedicalHistoryConfigurationArgType = {}) => {
      return run(() => getMedicalHistoryConfiguration(arg));
    },
    [run]
  );
  return {
    ...rest,
    getMedicalHistoryConfiguration: getMedicalHistoryConfigurationCb,
    medicalHistoryConfiguration: rest?.fetchJsonResponse?.data,
  };
}

export interface GetMedicalHistoryConfigurationArgType {
  //__ArgTypeBody__
}

export type UseGetMedicalHistoryConfigurationArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetMedicalHistoryConfigurationArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { CreateFollowUpResponse } from "api/types/createFollowUpResponse";
import { CreateFollowUpRequest } from "api/types/createFollowUpRequest";

export function createFollowUp(arg: CreateFollowUpArgType) {
  //__queryString__
  return fetchJson<CreateFollowUpResponse>(`/api/patient/encounter/FollowUp`, {
    method: "POST",
    body: arg.request,
  });
}

export function useCreateFollowUp(arg?: UseCreateFollowUpArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => createFollowUp(arg) : undefined,
  });

  const createFollowUpCb = useCallback(
    (arg: CreateFollowUpArgType) => {
      return run(() => createFollowUp(arg));
    },
    [run]
  );
  return {
    ...rest,
    createFollowUp: createFollowUpCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface CreateFollowUpArgType {
  request: CreateFollowUpRequest;
}

export type UseCreateFollowUpArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CreateFollowUpArgType);


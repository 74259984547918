import { s1Date } from "@sprint1/pkg/src/date";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ImagingTypeHelpers } from "api/types/orderType.helper";
import { MdOrthoPatientResult } from "api/types/mdOrthoPatientResult";

export function Results({ results }: { results: MdOrthoPatientResult[] }) {
  const { translate } = useTranslation();
  return (
    <div>
      {results.length === 0 && <div>{translate("__noResultsForPatient")}</div>}
      {!!results.length && (
        <div>
          {results.map((r, i) => {
            return (
              <div key={`result-${r.id}-${i}`}>
                <a href={r.resultUrl} target="_blank" rel="noreferrer">
                  <span>
                    {ImagingTypeHelpers.toString({ type: r.type, translate })}
                  </span>
                  <span> {s1Date.format.tryFormatDate(r.createdOn)}</span>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MarkAsUnreadRequest } from "api/types/markAsUnreadRequest";

export function markAsUnRead(arg: MarkAsUnReadArgType) {
  //__queryString__
  return fetchJson<void>(`/api/MessageThread/MarkAsUnRead`, {
    method: "POST",
    body: arg.request,
  });
}

export function useMarkAsUnRead(arg?: UseMarkAsUnReadArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => markAsUnRead(arg) : undefined,
  });

  const markAsUnReadCb = useCallback(
    (arg: MarkAsUnReadArgType) => {
      return run(() => markAsUnRead(arg));
    },
    [run]
  );
  return {
    ...rest,
    markAsUnRead: markAsUnReadCb,
    
  };
}

export interface MarkAsUnReadArgType {
  request: MarkAsUnreadRequest;
}

export type UseMarkAsUnReadArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & MarkAsUnReadArgType);


import { addDays } from "@sprint1/pkg/src/date/add";
import { getEndOfToday } from "@sprint1/pkg/src/date/getEndOf";
import { getStartOfToday } from "@sprint1/pkg/src/date/getStartOf";
import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UseDataReturnType } from "./useData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";

export function DateNav({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();

  function handleTodayClick() {
    data.setStartDate(getStartOfToday());
    data.setEndDate(getEndOfToday());
  }

  function handlePrevClick() {
    data.setStartDate((prevStartDate) => addDays(-1, prevStartDate));
    data.setEndDate((prevEndDate) => addDays(-1, prevEndDate));
  }

  function handleNextClick() {
    data.setStartDate((prevStartDate) => addDays(1, prevStartDate));
    data.setEndDate((prevEndDate) => addDays(1, prevEndDate));
  }

  return (
    <div className="row g-2 py-2 align-items-center ">
      <div className="col-sm text-lg-start text-center">
        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-primary" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} className="px-2" />
          </button>
          <button type="button" className="btn btn-primary" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} className="px-2" />
          </button>
        </div>
        <button type="button" className="btn btn-primary btn-sm ms-2" onClick={handleTodayClick}>
          {translate("__today")}
        </button>
      </div>
      <div className="col-sm text-center text-bold ">
        <div className="fw-bold">
          <span title={tryFormatDate(data.startDate)}>
            {tryFormatDate(data.startDate, {
              options: { month: "short", day: "numeric" },
            })}
          </span>
          {/* <span className="mx-2">-</span>
            <span title={tryFormatDate(data.endDate)}>
              {tryFormatDate(data.endDate, {
                options: { month: "short", day: "numeric" },
              })}
            </span> */}
        </div>
      </div>
      <div className="col-sm text-lg-end text-center">
        <Link to={routes.patient.selectBodyPart.url()} type="button" className="btn btn-outline-primary btn-sm ms-2">
          {translate("__scheduleAppointment")}
        </Link>
      </div>
    </div>
  );
}

import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { OpenClosedBadge } from "../common/OpenClosedBadge";
import { WorkersCompBadge } from "../common/WorkersCompBadge";
import { UseDataReturnType } from "./useData";

export function EncountersList({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();

  if (!isDefined(data.encounters)) {
    return null;
  }

  if (data.encounters.length === 0) {
    return <div>{translate("__noEncountersForPatient")}</div>;
  }
  return (
    <>
      {data.encounters.map((encounter, index) => {
        const date = tryFormatDate(encounter.startTime);
        const matchingBodyPart = data.bodyParts?.find((b) => b.id === encounter.bodyPartId);
        return (
          <div className=" " key={`encounter-${index}-${encounter.id}`}>
            <div className="d-flex">
              <span className="flex-grow-1 fs-6">{matchingBodyPart?.name}</span>
              <span className="mx-auto ms-2 fs-8 ">{date}</span>
            </div>

            <div className="d-flex mt-2">
              <div className="flex-grow-1">
                {data.selectedEncounter === encounter && (
                  <span className="badge rounded-pill text-bg-secondary">{translate("__current")}</span>
                )}
                <OpenClosedBadge open={!encounter.closed} className="ms-1" />
                <WorkersCompBadge show={encounter.workmansComp} className="ms-1" />
              </div>
              <div className="mx-auto">
                {data.selectedEncounter === encounter ? null : (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                      //@ts-ignore
                      "--bs-btn-padding-y": "0.2rem",
                      "--bs-btn-padding-x": "0.2rem",
                      "--bs-btn-font-size": ".75rem",
                    }}
                    onClick={() => {
                      data.setSelectedEncounter(encounter);
                    }}
                  >
                    {translate("__view")}
                  </button>
                )}
              </div>
            </div>

            <div className="my-2 border" />
          </div>
        );
      })}
    </>
  );
}

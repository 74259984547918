import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { PatientInjury } from "api/types/patientInjury";

export function getInjury(arg: GetInjuryArgType) {
  const qs = convertToQueryString( {injuryId: arg.injuryId })
  return fetchJson<PatientInjury>(`/api/Injury/GetInjury${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetInjury(arg?: UseGetInjuryArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getInjury(arg) : undefined,
  });

  const getInjuryCb = useCallback(
    (arg: GetInjuryArgType) => {
      return run(() => getInjury(arg));
    },
    [run]
  );
  return {
    ...rest,
    getInjury: getInjuryCb,
    injury: rest?.fetchJsonResponse?.data,
  };
}

export interface GetInjuryArgType {
  injuryId: string;
}

export type UseGetInjuryArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetInjuryArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MarkAsReadRequest } from "api/types/markAsReadRequest";

export function markAsRead(arg: MarkAsReadArgType) {
  //__queryString__
  return fetchJson<void>(`/api/MessageThread/MarkAsRead`, {
    method: "POST",
    body: arg.request,
  });
}

export function useMarkAsRead(arg?: UseMarkAsReadArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => markAsRead(arg) : undefined,
  });

  const markAsReadCb = useCallback(
    (arg: MarkAsReadArgType) => {
      return run(() => markAsRead(arg));
    },
    [run]
  );
  return {
    ...rest,
    markAsRead: markAsReadCb,
    
  };
}

export interface MarkAsReadArgType {
  request: MarkAsReadRequest;
}

export type UseMarkAsReadArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & MarkAsReadArgType);


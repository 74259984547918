import { useState } from "react";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { LinkButton } from "components/LinkButton";
import { getDiagnoses } from "api/client/configuration/getDiagnoses";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import Accordion from "react-bootstrap/Accordion";
import { ReSelect } from "@sprint1/pkg/src/reSelect";

export function OrthoLibrary() {
  const { translate } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState<MdOrthoDiagnosis>();
  const data = useData();
  if (!data.diagnoses.length) {
    return <ViewPortLoading />;
  }

  const matchingDiagnoses = data.selectedBodyPart
    ? data.diagnoses.filter((d) => d.bodyPart === data.selectedBodyPart)
    : data.topDiagnoses;

  return (
    <>
      {!selectedTopic && (
        <PageLayout>
          <PageTitleSimple title={translate("__orthopedicLibrary")} />
          <Accordion className="my-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{translate("__notMedicalAdviceTitle")}</Accordion.Header>
              <Accordion.Body>{translate("__notMedicalAdviceBody")}</Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="d-flex flex-row flex-wrap gap-2 justify-content-center align-items-center m-3">
            <div style={{ minWidth: "300px" }}>
              <ReSelect
                options={data.bodyParts.map((b) => {
                  return { label: b, value: b };
                })}
                onChange={(payload) => {
                  data.setSelectedBodyPart(payload.selectedValue);
                }}
                value={data.selectedBodyPart}
                isClearable
                placeholder={translate("__selectABodyPart")}
              />
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-center">
            {matchingDiagnoses.map((c, index) => {
              return (
                <div key={`${c.diagnosis}-${index}`} className="d-inline-block border rounded m-2">
                  <button
                    className="btn btn-outline-primary "
                    onClick={() => {
                      setSelectedTopic(c);
                    }}
                  >
                    {c.diagnosis}
                  </button>
                </div>
              );
            })}
          </div>
        </PageLayout>
      )}

      {selectedTopic && <Content content={selectedTopic} onBackClick={() => setSelectedTopic(undefined)} />}
    </>
  );
}

function Content({ content, onBackClick }: { content: MdOrthoDiagnosis; onBackClick: () => any }) {
  const { translate } = useTranslation();
  return (
    <PageLayout containerType="container">
      <PageTitleSimple title={content.diagnosis} right={<LinkButton variant="back" onClick={onBackClick} />} />
      <ul>
        {content.tips.map((tip) => {
          return <li key={tip}>{tip}</li>;
        })}
      </ul>

      <div className="mt-4 fs-8">{translate("__notMedicalAdviceBody")}</div>
    </PageLayout>
  );
}

function useData() {
  const [diagnoses, setDiagnoses] = useState<MdOrthoDiagnosis[]>([]);
  const [topDiagnoses, setTopDiagnoses] = useState<MdOrthoDiagnosis[]>([]);
  const [bodyParts, setBodyParts] = useState<string[]>([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState<string>();

  useRunOnMount(() => {
    async function load() {
      const { data } = await getDiagnoses({ request: { icd10Codes: [] } });
      const bodyPartSet = new Set(
        data.map((d) => {
          return d.bodyPart;
        })
      );
      setDiagnoses(data);
      setBodyParts(Array.from(bodyPartSet).sort((a, b) => a.localeCompare(b)));
      const topDiagnoses = data.filter((d) => d.isTopInjury).sort((a, b) => a.diagnosis.localeCompare(b.diagnosis));
      setTopDiagnoses(topDiagnoses);
    }
    load();
  });

  return { topDiagnoses, bodyParts, selectedBodyPart, setSelectedBodyPart, diagnoses, setDiagnoses };
}

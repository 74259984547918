import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { AccessLevel } from "api/types/accessLevel";

export interface AccessLevelBadgeType {
  accessLevel?: AccessLevel;
  className?: string;
  size?: string;
}
export function AccessLevelBadge({ accessLevel, className = "", size = "fs-9" }: AccessLevelBadgeType) {
  const { translate } = useTranslation();

  if (accessLevel === AccessLevel.WorkerCompOnly) {
    const accessLevelString = EnumHelper.toTranslatedString({
      value: accessLevel,
      enumObj: AccessLevel,
      translationPrefix: "__accessLevel_",
      translate,
    });
    return (
      <span className={`badge rounded-pill text-bg-warning ${className} ${size}`} title={accessLevelString}>
        {accessLevelString}
      </span>
    );
  }
  return null;
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";
import { useAppRoutes } from "routes/useRoutes";
import { CtaButton, CtaButtonType } from "./CtaButton";
import { LinkButton, LinkButtonType } from "./LinkButton";
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
export type FooterType =
  | {
      variant: "close";
      onCloseClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
      closeButtonProps?: CtaButtonType;
    }
  | {
      variant: "cancelAndContinue" | "backAndSave";
      onCancelClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
      onSaveClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
      cancelButtonProps?: LinkButtonType;
      saveButtonProps?: CtaButtonType;
    }
  | {
      variant: "placeholder";
      left: ReactNode;
      right: ReactNode;
    };

export function Footer(props: FooterType) {
  const { goBack } = useAppRoutes();
  const { translate } = useTranslation();
  let left: ReactNode;
  let right: ReactNode;

  switch (props.variant) {
    case "cancelAndContinue":
    case "backAndSave": {
      left = (
        <LinkButton
          variant={props.variant === "backAndSave" ? "back" : "cancel"}
          onClick={
            props.onCancelClick ??
            ((e) => {
              goBack();
            })
          }
          {...props?.cancelButtonProps}
        />
      );
      right = (
        <CtaButton
          type="submit"
          onClick={props.onSaveClick}
          text={props.variant === "backAndSave" ? translate("__save") : translate("__continue")}
          {...props?.saveButtonProps}
        />
      );
      break;
    }
    case "close": {
      left = null;
      right = (
        <CtaButton
          type="button"
          onClick={props.onCloseClick}
          text={translate("__close")}
          icon={faClose}
          {...props?.closeButtonProps}
        />
      );
      break;
    }
    case "placeholder": {
      left = props.left;
      right = props.right;
      break;
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center py-2">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
}

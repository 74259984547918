import { Card } from "components/Card";

import { PageTitleSimple } from "components/PageLayout";
import { Loading } from "@sprint1/pkg/src/loading";
import { UseDataReturnType } from "./useData";
import { MeetingInfo } from "../common/MeetingInfo";
import { s1Date } from "@sprint1/pkg/src/date";
import { useFormatName } from "common/useFormatName";
export function MeetingDetails({ data }: { data: UseDataReturnType }) {
  const format = useFormatName();
  if (!data.selectedEncounter) {
    return <Loading />;
  }

  const oneHrAgo = s1Date.addMinutes(-60);
  const readOnly = s1Date.is(data.selectedEncounter.startTime).before(oneHrAgo);
  return (
    <Card>
      <PageTitleSimple
        compact
        left={
          <PageTitleSimple.Text
            title={format.formatName(data.selectedEncounter.patientLastName, data.selectedEncounter.patientFirstName)}
          />
        }
        right={data.selectedEncounter.patientUserName}
      />
      <MeetingInfo
        patientEncounter={data.selectedEncounter}
        readOnly={readOnly}
        onMeetingCancelled={() => {
          data.refreshAppointments();
        }}
      />
    </Card>
  );
}

import { s1Date } from "@sprint1/pkg/src/date";
import { ErrorWithData } from "@sprint1/pkg/src/error/errorWithData";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useReSchedule } from "api/client/cronofy/reSchedule";
import { useSchedule } from "api/client/cronofy/schedule";
import { useGetPatientEncounter } from "api/client/patientEncounter/getPatientEncounter";
import { userFilters } from "api/helpers/searchUsers.helper";
import { searchDoctors } from "api/client/user/searchDoctors";
import { CronofyNotification } from "api/types/cronofyNotification";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useFormatName, UseFormatNameType } from "common/useFormatName";
import { AvailabilityPicker } from "components/availabilityPicker";
import { Footer } from "components/Footer";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useState } from "react";
import { routes } from "routes/routes.config";
import { useAppRoutes } from "routes/useRoutes";
import { getPatientsWithInjuries } from "api/client/user/getPatientsWithInjuries";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";

export function ScheduleAppointment() {
  const data = useData();
  const format = useFormatName();
  const { translate } = useTranslation();
  if (data.isLoading) {
    return <ViewPortLoading />;
  }

  const title =
    data.qs.type === "reSchedule" ? translate("__reScheduleAppointment") : translate("__scheduleAppointment");
  return (
    <>
      <PageLayout>
        <PageTitleSimple title={title} left={title} />
        {data.qs.type === "reSchedule" && data.getEncounterApi.patientEncounter && (
          <div className="text-center">
            <div className="d-inline-block alert alert-info ">
              {translate("__youAreReschedulingAnAppointment")}
              <div className="text-start">
                <div>
                  <span className="mx-1 fw-bold">{translate("__doctor")}</span>
                  <span className="mx-1 ">{data.getEncounterApi.patientEncounter.providerLastName}</span>
                </div>
                <div>
                  <span className="mx-1 fw-bold">{translate("__patient")}</span>
                  <span className="mx-1 ">
                    {format.formatName(
                      data.getEncounterApi.patientEncounter.patientLastName,
                      data.getEncounterApi.patientEncounter.patientFirstName
                    )}
                  </span>
                </div>
                <div>
                  <span className="mx-1 fw-bold">{translate("__selectedTime")}</span>
                  <span className="mx-1 ">
                    {s1Date.format.tryFormatDateTime(data.getEncounterApi.patientEncounter.startTime)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row d-flex justify-content-center ">
          <div className="col-md-8  ">
            <div className="row g-2 d-flex justify-content-center ">
              {data.doctorsOptions.length > 0 && (
                <div className="col-md-4">
                  <Field name="doctor" isRequired>
                    <Label>{translate("__chooseDoctor")}</Label>
                    <ReSelectField
                      onChange={(payload) => {
                        data.setSelectedDoctor(payload.selectedValue);
                      }}
                      value={data.selectedDoctor}
                      options={data.doctorsOptions}
                      isClearable
                    />
                  </Field>
                </div>
              )}
            </div>

            <div className="row g-2 d-flex justify-content-center">
              <div className="col-md-4">
                <Field name="ignoreWorkingHours" isCheckOrRadio>
                  <S1InputField
                    type="checkbox"
                    value={data.ignoreWorkingHours}
                    onChange={(e) => {
                      data.setIgnoreWorkingHours(e.target.checked);
                    }}
                  />
                  <Label>{translate("__ignoreWorkingHours")}</Label>
                </Field>
              </div>
            </div>

            <div className="d-flex justify-content-center ">
              <AvailabilityPicker
                key={`${data.selectedDoctor?.id}-${data.ignoreWorkingHours}`}
                overrideAvailability={data.ignoreWorkingHours}
                provider={data.selectedDoctor}
                onTimePicked={(e) => {
                  data.setSelectedSlot(e);
                }}
              />
            </div>
          </div>
        </div>
      </PageLayout>
      <Footer
        variant="backAndSave"
        saveButtonProps={{
          text: title,
          onClick: data.onScheduleAppointment,
          showSpinner: data.scheduleApi.isRunning || data.rescheduleApi.isRunning,
          disabled: data.scheduleApi.isRunning || data.rescheduleApi.isRunning,
        }}
      />
    </>
  );
}

function useData() {
  const { translate } = useTranslation();
  const format = useFormatName();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [qs, setQs] = useState<QueryStringParserReturnType>({ type: "invalid" });
  const [ignoreWorkingHours, setIgnoreWorkingHours] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<MdOrthoUser>();

  const [patientOptions, setPatientOptions] = useState<OptionType<string>[]>([]);
  const [doctorsOptions, setDoctors] = useState<OptionType<MdOrthoUser>[]>([]); //We need the MDOrthoUser object to pass it to Cronofy component

  const [selectedSlot, setSelectedSlot] = useState<CronofyNotification>();
  const scheduleApi = useSchedule();
  const rescheduleApi = useReSchedule();
  const getEncounterApi = useGetPatientEncounter();
  const appRoutes = useAppRoutes();

  useRunOnMount(() => {
    async function load() {
      const qs = routes.careGivers.scheduleAppointment.getQueryStrings();
      setQs(qs);
      const options = await getPatientsAndDoctors({ format });
      setPatientOptions(options.patientOptions);
      setDoctors(options.doctorsOptions);

      if (qs.type === "schedule") {
      } else if (qs.type === "reSchedule") {
        setIsLoading(true);
        const { data } = await getEncounterApi.getPatientEncounter({ patientEncounterId: qs.patientEncounterId });
        const matchingProvider = options.doctorsOptions.find((u) => u.value?.id === data.providerId);
        if (matchingProvider) {
          setSelectedDoctor(matchingProvider.value);
        }
        setIsLoading(false);
      } else {
        toast.error({ type: "BadUrl" });
      }
    }

    load();
  });

  async function onScheduleAppointment() {
    toast.clear();
    if (qs.type === "schedule") {
      await schedule();
    } else if (qs.type === "reSchedule") {
      await reschedule();
    } else {
      //do nothing. We should not be here.
    }
    appRoutes.go(routes.careGivers.schedule);
  }

  async function schedule() {
    if (qs.type !== "schedule") {
      //We shouldn't be here.
      return;
    }
    if (!selectedSlot) {
      toast.error(translate("__pleaseSelectATime"));
      return;
    }
    await scheduleApi.schedule({
      request: {
        injuryId: qs.injuryId,
        notification: selectedSlot,
      },
    });
  }

  async function reschedule() {
    if (!selectedSlot) {
      toast.error(translate("__pleaseSelectATime"));
      return;
    }
    const encounter = getEncounterApi.patientEncounter;
    if (!encounter) {
      throw new ErrorWithData("Trying to reschedule before patientEncounter fetched", { url: window.location.href });
    }
    await rescheduleApi.reSchedule({
      request: {
        notification: selectedSlot,
        patientEncounterId: encounter.id,
      },
    });
  }

  return {
    isLoading,
    qs,
    selectedDoctor: selectedDoctor,
    setSelectedDoctor: setSelectedDoctor,
    doctorsOptions,
    patientOptions,

    ignoreWorkingHours,
    setIgnoreWorkingHours,

    selectedSlot,
    setSelectedSlot,
    onScheduleAppointment,

    getEncounterApi,
    scheduleApi,
    rescheduleApi,
  };
}

async function getPatientsAndDoctors({ format }: { format: UseFormatNameType }) {
  const doctorsPromise = searchDoctors({ request: userFilters.sortByFirstAndLastName() });
  const patientsPromise = getPatientsWithInjuries();
  const { data: patients } = await patientsPromise;
  const { data: allDoctors } = await doctorsPromise;

  const patientOptions = patients.map((p) => ({
    label: `${format.formatName(p.lastName, p.firstName)}`,
    value: p.id,
  }));

  const doctorsWithCalendars = allDoctors.results.filter((d) => d.cronofySubscriptionId);
  const doctorsOptions = doctorsWithCalendars.map((d) => ({
    label: format.formatDoctorName(d.lastName ?? ""),
    value: d,
  }));

  return { patientOptions, doctorsOptions };
}

type QueryStringParserReturnType = ReturnType<typeof routes.careGivers.scheduleAppointment.getQueryStrings>;

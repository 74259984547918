import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { PageLayout } from "../components/PageLayout";
import { Section } from "../components/Section";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { PatientMedicalHistory } from "api/types/patientMedicalHistory";
import { PatientMedicalHistoryData } from "api/types/patientMedicalHistoryData";
const name = {
  medicalHistoryData: nameof<PatientMedicalHistoryData>,
  medicalHistory: nameof<PatientMedicalHistory>,
};

export function EmergencyContact() {
  const { translate } = useTranslation();
  return (
    <PageLayout>
      <Section titleKey="__emergencyContact">
        <Field name={`${name.medicalHistory("data")}.${name.medicalHistoryData("emergencyContactName")}`}>
          <Label>{translate("__name")}</Label>
          <S1InputFormikField />
        </Field>
        <Field name={`${name.medicalHistory("data")}.${name.medicalHistoryData("emergencyContactPhone")}`}>
          <Label>{translate("__phone")}</Label>
          <S1InputFormikField />
        </Field>
      </Section>
    </PageLayout>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { FilterAndSortResponse } from "@sprint1/pkg/src/api/common/types/filterAndSortResponse";
import { MdOrthoMessageThread } from "api/types/mdOrthoMessageThread";
import { SearchThreadsRequest } from "api/types/searchThreadsRequest";

export function searchThreads(arg: SearchThreadsArgType) {
  //__queryString__
  return fetchJson<FilterAndSortResponse<MdOrthoMessageThread>>(`/api/MessageThread/Search`, {
    method: "POST",
    body: arg.request,
  });
}

export function useSearchThreads(arg?: UseSearchThreadsArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => searchThreads(arg) : undefined,
  });

  const searchThreadsCb = useCallback(
    (arg: SearchThreadsArgType) => {
      return run(() => searchThreads(arg));
    },
    [run]
  );
  return {
    ...rest,
    searchThreads: searchThreadsCb,
    threads: rest?.fetchJsonResponse?.data,
  };
}

export interface SearchThreadsArgType {
  request: SearchThreadsRequest;
}

export type UseSearchThreadsArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & SearchThreadsArgType);


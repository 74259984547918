import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useFiltersAndSort } from "@sprint1/pkg/src/table/useFiltersAndSort";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { SecondaryButton } from "components/SecondaryButton";
import { S1Table } from "@sprint1/pkg/src/table/Components";
import { Link } from "react-router-dom";
import { useAppRoutes } from "routes/useRoutes";
import { useEffect } from "react";
import { routes } from "routes/routes.config";
import { useSearchTenants } from "api/client/tenant/searchTenants";
import { MdOrthoTenant } from "api/types/mdOrthoTenant";
import { Loading } from "@sprint1/pkg/src/loading";
import { tryFormatDate } from "@sprint1/pkg/src/date/tryFormatDate";
import { BooleanFilter } from "@sprint1/pkg/src/table/filters/BooleanFilter";
import { TextFilter } from "@sprint1/pkg/src/table/filters/TextFilter";
import { ActionMenuSimple } from "@sprint1/pkg/src/actionMenu/Simple";
import { SavingsReportModal, useSavingsReportModal } from "./SavingsReportModal";
import { LinkButton } from "@sprint1/pkg/src/button/LinkButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

const name = nameof<MdOrthoTenant>;

export function ClientsList() {
  const { translate } = useTranslation();
  const { go } = useAppRoutes();
  const data = useData();
  const savingsReportModal = useSavingsReportModal();

  return (
    <PageLayout>
      <PageTitleSimple
        title={translate("__clients")}
        left={translate("__clients")}
        right={
          <>
            <Loading inline show={data.filtersAndSort.isDebouncing || data.searchTenantsApi.isRunning} />
            <LinkButton
              rightIcon={faDownload}
              onClick={() => {
                savingsReportModal.showModal();
              }}
            >
              {translate("__downloadReports")}
            </LinkButton>
            <SecondaryButton
              onClick={() => {
                go(routes.admin.clients.edit.url);
              }}
            >
              {translate("__addNewClient")}
            </SecondaryButton>
          </>
        }
      />
      <SavingsReportModal {...savingsReportModal} />
      <Table data={data} />
    </PageLayout>
  );
}

function useData() {
  const filtersAndSort = useFiltersAndSort({
    filterFields: [{ field: name("active"), value: "true" }],
    sortFields: [{ field: name("name"), ascending: true }],
  });
  const searchTenantsApi = useSearchTenants();

  const { searchTenants } = searchTenantsApi;
  useEffect(() => {
    searchTenants({ request: filtersAndSort.filtersDebounced });
  }, [filtersAndSort.filtersDebounced, searchTenants]);

  return { searchTenantsApi, filtersAndSort };
}
type UseDateReturnType = ReturnType<typeof useData>;

function Table({ data }: { data: UseDateReturnType }) {
  const { translate } = useTranslation();

  const { filtersAndSort } = data;
  const tenants = data.searchTenantsApi.tenants;

  return (
    <>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th
              width="25%"
              label={translate("__name")}
              filter={<TextFilter name={name("name")} filtersAndSort={filtersAndSort} />}
            />

            <S1Table.Th
              width="10rem"
              label={translate("__status")}
              filter={
                <BooleanFilter
                  name={name("active")}
                  filtersAndSort={filtersAndSort}
                  trueLabel={translate("__active")}
                  falseLabel={translate("__inactive")}
                />
              }
            />
            <S1Table.Th label={translate("__parent")} />
            <S1Table.Th label={translate("__activeSince")} />
            <S1Table.Th label={translate("__numberOfEmployees")} />
            <S1Table.Th label={translate("__lastUpload")} />
            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {tenants &&
            tenants.results.map((tenant, index) => {
              return (
                <tr key={`tr-${index}-${tenant.name}-${tenant.id}`}>
                  <td>
                    {tenant.name}
                    {tenant.isTest && <span className="ms-2 badge rounded-pill text-bg-primary">test</span>}
                  </td>
                  <td>{`${tenant.active ? translate("__active") : translate("__inactive")}`}</td>
                  <td>{tenant.parentName}</td>
                  <td>{`${tryFormatDate(tenant.activeDate)}`}</td>
                  <td>{`${tenant.numberOfUsers}`}</td>
                  <td>{`${tryFormatDate(tenant.activeDate)}`}</td>
                  <td>
                    <ActionMenuSimple>
                      <ActionMenuSimple.MenuItem as={Link} to={routes.admin.clients.edit.urlFn(tenant.id)}>
                        {translate("__editClient")}
                      </ActionMenuSimple.MenuItem>
                      <ActionMenuSimple.MenuItem as={Link} to={routes.admin.users.list.get(tenant.id, tenant.name)}>
                        {translate("__addEditUsers")}
                      </ActionMenuSimple.MenuItem>
                      <ActionMenuSimple.MenuItem
                        as={Link}
                        to={routes.admin.clients.uploadEmployees.getUrl(tenant.id, tenant.name)}
                      >
                        {translate("__uploadEmployees")}
                      </ActionMenuSimple.MenuItem>
                    </ActionMenuSimple>
                  </td>
                </tr>
              );
            })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );
}

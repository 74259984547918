import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { LinkButton } from "components/LinkButton";
import { ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import { UseDataReturnType } from "../../useData";
import { EncounterPreview } from "../preview";

export function EncounterDetailsModal({
  footerRight,
  onHide,
  patientEncounter,
  injury,
  bodyParts,
  questionnaireResponse,
  encounterViewModel,
  diagnoses,
}: {
  footerRight: ReactNode;
  onHide: () => any;
  injury: UseDataReturnType["injury"];
  bodyParts: UseDataReturnType["bodyParts"];
  questionnaireResponse: UseDataReturnType["injuryQuestionnaire"];
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
  diagnoses: UseDataReturnType["diagnoses"];
}) {
  const { translate } = useTranslation();
  return (
    <Modal show={true} onHide={onHide} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>{translate("__visitSummary")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EncounterPreview
          injury={injury}
          bodyParts={bodyParts}
          questionnaireResponse={questionnaireResponse}
          patientEncounter={patientEncounter}
          encounterViewModel={encounterViewModel}
          diagnoses={diagnoses}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div>
            <LinkButton variant="close" onClick={onHide} />
          </div>
          <div>{footerRight}</div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export enum OrderType {
    None = 0, 
    MRI = 1, 
    CT = 2, 
    XRay = 3, 
    EMG = 4, 
    UltraSound = 5, 
    BoneScan = 6, 
    Labs = 7, 
    PhysicalTherapy = 8, 
    Other = 20   
}

import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { createFilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest.helper";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { MdOrthoWorkersComp } from "api/types/mdOrthoWorkersComp";

export const MdOrthoWorkersCompFilters = {
  sortByInjuryDate() {
    const request: FilterAndSortRequest = createFilterAndSortRequest({
      filterFields: [],
      sortFields: [{ field: name("dateOfInjury"), ascending: false }],
    });

    return request;
  },
};

const name = nameof<MdOrthoWorkersComp>;

import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";

import { routes } from "routes/routes.config";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useAppRoutes } from "routes/useRoutes";

import { getPaymentStatus } from "api/client/payment/getPaymentStatus";
import { ScheduleAppointmentStatus } from "api/types/scheduleAppointmentStatus";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";

export function PaymentSuccess() {
  const toast = useToast();
  const { go } = useAppRoutes();
  const { translate } = useTranslation();

  useRunOnMount(() => {
    async function load() {
      const paymentIntentId = getQueryStringValue("payment_intent");
      if (!paymentIntentId) {
        toast.error({ type: "BadUrl", delay: true });
        go(routes.patient.dashboard);
        return;
      }

      const status = await getStatus(paymentIntentId);
      if (status === ScheduleAppointmentStatus.AppointmentScheduled) {
        toast.success(translate("__yourAppointmentBookedCheckEmailOrPhone"), true);
        go(routes.patient.dashboard);
        return;
      }
      toast.error({ msg: translate("__yourPaymentFailedPleaseTryAgain"), delay: true });
      go(routes.patient.dashboard);
      return;
    }
    load();
  });

  return <ViewPortLoading />;
}

async function getStatus(paymentId: string): Promise<ScheduleAppointmentStatus> {
  const { data: status } = await getPaymentStatus({ stripePaymentId: paymentId });
  if (status === ScheduleAppointmentStatus.AppointmentScheduled) {
    return ScheduleAppointmentStatus.AppointmentScheduled;
  }

  //Wait for 30 seconds
  await new Promise((resolve) => setTimeout(resolve, 30 * 1000));

  if (status === ScheduleAppointmentStatus.RequiredPayment) {
    const { data: status1 } = await getPaymentStatus({ stripePaymentId: paymentId });
    return status1;
  }

  return ScheduleAppointmentStatus.RequiredPayment;
}

import { UseDataReturnType } from "../../useData";

import { Imaging } from "./Imaging";
import { Diagnosis } from "./Diagnosis";
import { Prescription } from "./Prescription";
import { Equipment } from "./Equipment";

import { PhysicalTherapy } from "./PhysicalTherapy";

export function DiagnosisAndTreatmentContainer({ data }: { data: UseDataReturnType }) {
  return (
    <>
      <Diagnosis data={data} />
      <Imaging data={data} />
      <Prescription data={data} />
      <Equipment data={data} />
      <PhysicalTherapy data={data} />
    </>
  );
}

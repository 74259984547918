import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UploadButton } from "@sprint1/pkg/src/upload/UploadButton";
import { uploadToS3Async } from "common/uploadToS3";
import { CtaButton } from "components/CtaButton";
import { LinkButton } from "components/LinkButton";
import { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { UseDataReturnType } from ".";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { TextAreaField } from "@sprint1/pkg/src/form/textArea/Field";
import invariant from "tiny-invariant";
import { useListMessages } from "api/client/message/listMessages";
import { Loading } from "@sprint1/pkg/src/loading";
import { useReplyToMessage } from "api/client/message/replyToMessage";
import { markAsRead } from "api/client/messageThread/markAsRead";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { formatDate } from "./formatDate";
import { MdOrthoMessage } from "api/types/mdOrthoMessage";
import { MdOrthoMessageThread } from "api/types/mdOrthoMessageThread";
import { s1Date } from "@sprint1/pkg/src/date";
import { useFormatName } from "common/useFormatName";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";
import { NewAttachment } from "api/types/newAttachment";
import { MessageDirection } from "api/types/messageDirection";

export function ViewMessagesModal({ data }: { data: UseDataReturnType }) {
  invariant(data.selectedThread, "To view this modal, we expect a selected thread");
  const { translate } = useTranslation();
  const modalData = useData({ data });

  return (
    <Modal
      size="lg"
      centered
      show={!!data.selectedThread}
      onHide={() => {
        data.setShowMessagesModal(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!modalData.messages ? <Loading /> : <Messages thread={data.selectedThread} messages={modalData.messages} />}
        <Field name="message" isRequired className="mt-4">
          <Label>{translate("__message")}</Label>
          <TextAreaField
            value={modalData.message}
            autoFocus
            onChange={(e) => modalData.setMessage(e.target.value)}
            rows={2}
          />
        </Field>
        <UploadButton
          uploadFileAsync={async ({ file }) => {
            const { url } = await uploadToS3Async(file);
            modalData.setFileName(file.name);
            modalData.setFileUrl(url);
          }}
        />
        {modalData.fileUrl && modalData.fileName && (
          <div className="mt-3">
            <a href={modalData.fileUrl} target="__blank">
              {modalData.fileName}
            </a>
            <button
              className="btn btn-sm btn-link ms-1"
              onClick={() => {
                modalData.setFileUrl(undefined);
                modalData.setFileName("");
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div>
            <LinkButton
              variant="cancel"
              onClick={() => {
                data.setShowMessagesModal(false);
              }}
            />
          </div>
          <div>
            {data.selectedThread?.patientId && (
              <Link to={routes.careGivers.patientsDetails.url(data.selectedThread.patientId)} target="__blank">
                {translate("__viewPreviousVisitsOrders")}
              </Link>
            )}
            <CtaButton
              className="ms-2"
              text={translate("__sendMessage")}
              showSpinner={modalData.replyToMessageApi.isRunning}
              showSuccess={modalData.replyToMessageApi.isSuccess}
              disabled={modalData.replyToMessageApi.isRunning}
              onClick={async () => {
                invariant(data.selectedThread, "To view this modal, we expect a selected thread");
                const attachments: NewAttachment[] = modalData.fileUrl ? [{ temporaryPath: modalData.fileUrl }] : [];
                await modalData.replyToMessageApi.replyToMessage({
                  request: {
                    threadId: data.selectedThread.id,
                    message: {
                      body: modalData.message,
                      attachments: attachments,
                    },
                  },
                });
                data.updateThreadModifiedOn(data.selectedThread);
                data.setShowMessagesModal(false);
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function useData({ data }: { data: UseDataReturnType }) {
  const [message, setMessage] = useState("");

  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState("");

  const { messages, listMessages } = useListMessages();
  const replyToMessageApi = useReplyToMessage();

  useRunOnMount(() => {
    async function load() {
      if (data.selectedThread) {
        await listMessages({ threadId: data.selectedThread.id });
        if (!data.selectedThread.read) {
          await markAsRead({ request: { threadId: data.selectedThread.id } });
          data.toggleThreadAsRead(data.selectedThread);
        }
      }
    }
    load();
  });

  return {
    message,
    setMessage,

    fileUrl,
    setFileUrl,

    fileName,
    setFileName,
    messages,
    listMessages,
    replyToMessageApi,
  };
}

function Messages({ messages, thread }: { messages: MdOrthoMessage[]; thread: MdOrthoMessageThread }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const format = useFormatName();

  const { translate } = useTranslation();

  useRunOnMount(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  });

  return (
    <div style={{ maxHeight: "50vh", overflowY: "scroll" }} ref={containerRef}>
      {messages.map((msg, index) => {
        const uiConfig = getUiConfig(msg);
        return (
          <div key={`${msg.body}-${index}`} className={`${uiConfig.alignment} my-2 `}>
            <div className={`d-inline-block ${uiConfig.textBgColor} p-2 rounded`}>
              <div>{msg.body}</div>
              {msg.data.attachments.map((a) => {
                return (
                  <a key={a.s3Url} href={a.s3Url} target="__blank" className={`${uiConfig.textColor} d-block`}>
                    {translate("__attachment")}
                  </a>
                );
              })}
              <div className=" fs-8">
                <span>{format.formatName(msg.fromUserLastName, msg.fromUserFirstName)}</span>
                <span className="ms-2" title={s1Date.format.toDateTimeString(msg.createdOn)}>
                  {formatDate(msg.createdOn)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface UiConfig {
  alignment: "text-start" | "text-end";
  textBgColor: "text-bg-secondary" | "text-bg-dark";
  textColor: "text-dark" | "text-light";
}
function getUiConfig(message: MdOrthoMessage): UiConfig {
  const left: UiConfig = { alignment: "text-start", textBgColor: "text-bg-secondary", textColor: "text-dark" };
  const right: UiConfig = { alignment: "text-end", textBgColor: "text-bg-dark", textColor: "text-light" };

  if (message.direction === MessageDirection.From) {
    return right;
  }

  return left;
}

import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { BodyPart } from "api/types/bodyPart";
import { GetInjuryQuestionnaireResponse } from "api/types/getInjuryQuestionnaireResponse";
import { PatientInjury } from "api/types/patientInjury";

import { PatientInjuryQuestionnaire } from "api/types/patientInjuryQuestionnaire";
import { PatientQuestion } from "api/types/patientQuestion";
import { QuestionType } from "api/types/questionType";

import { InjuryInfo } from "../../common/InjuryInfo";

export function IntakeForm({
  injury,
  questionnaireResponse,
  bodyParts,
  compact,
}: {
  injury: PatientInjury;
  questionnaireResponse: GetInjuryQuestionnaireResponse;
  bodyParts: BodyPart[];
  compact?: boolean;
}) {
  const { translate } = useTranslation();
  const topSpaceing = compact ? "mt-2" : "mt-3";
  return (
    <div>
      <InjuryInfo bodyParts={bodyParts} injury={injury} bodyPartMargin="" />

      {questionnaireResponse.questionnaire.questions.map((q, index) => {
        return (
          <div key={`question-${q.id}-${index}`} className={topSpaceing}>
            <div className="fw-bold">{q.questionText}</div>
            {getResponse(q, questionnaireResponse.patientResponses, translate)}
          </div>
        );
      })}

      <div className={topSpaceing}>
        <div className="fw-bold">{translate("__descriptionOfWCInjury")}</div>
        <div>{injury.explanation ?? ""}</div>
      </div>

      <div className={topSpaceing}>
        <div className="fw-bold">{translate("__additionalDetailsFromPatients")}</div>
        <div>{questionnaireResponse.patientResponses.additionalDetails ?? ""}</div>
      </div>
    </div>
  );
}

function getResponse(question: PatientQuestion, questionnaire: PatientInjuryQuestionnaire, translate: TranslateFnType) {
  const matchingResponse = questionnaire.data.responses.find((r) => r.patientQuestionId === question.id);
  if (matchingResponse) {
    if (question.type === QuestionType.FreeForm && matchingResponse.text) {
      return matchingResponse.text;
    } else if (matchingResponse.choices.length > 0) {
      //Is this a choice based question
      const matchingChoice = question.choices.filter((c) => matchingResponse.choices.includes(c.id));
      if (matchingChoice) {
        return matchingChoice.map((c) => c.choiceText).join(", ");
      }
    }
  }
  return translate("__noAnswer");
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ReplyToMessageRequest } from "api/types/replyToMessageRequest";

export function replyToMessage(arg: ReplyToMessageArgType) {
  //__queryString__
  return fetchJson<void>(`/api/Message/Reply`, {
    method: "POST",
    body: arg.request,
  });
}

export function useReplyToMessage(arg?: UseReplyToMessageArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => replyToMessage(arg) : undefined,
  });

  const replyToMessageCb = useCallback(
    (arg: ReplyToMessageArgType) => {
      return run(() => replyToMessage(arg));
    },
    [run]
  );
  return {
    ...rest,
    replyToMessage: replyToMessageCb,
    
  };
}

export interface ReplyToMessageArgType {
  request: ReplyToMessageRequest;
}

export type UseReplyToMessageArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ReplyToMessageArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { CreateInjuryRequest } from "api/types/createInjuryRequest";

export function createInjury(arg: CreateInjuryArgType) {
  //__queryString__
  return fetchJson<string>(`/api/Injury/CreateInjury`, {
    method: "POST",
    body: arg.request,
  });
}

export function useCreateInjury(arg?: UseCreateInjuryArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => createInjury(arg) : undefined,
  });

  const createInjuryCb = useCallback(
    (arg: CreateInjuryArgType) => {
      return run(() => createInjury(arg));
    },
    [run]
  );
  return {
    ...rest,
    createInjury: createInjuryCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface CreateInjuryArgType {
  request: CreateInjuryRequest;
}

export type UseCreateInjuryArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CreateInjuryArgType);


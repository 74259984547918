import { PageContainer } from "@sprint1/pkg/src/page/Container";
import { PageTitleSimple } from "@sprint1/pkg/src/page/title/Simple";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";
import { useSetDocumentTitle } from "components/useSetDocumentTitle";

export interface PageLayoutType {
  titleKey?: string;
  displayTitle?: boolean;
  children: ReactNode;
}

export function PageLayout({
  titleKey,
  displayTitle = false,
  children,
}: PageLayoutType) {
  const { translate } = useTranslation();
  const pageTitle = titleKey
    ? translate("__documentTitle", { pageTitle: translate(titleKey) })
    : undefined;
  useSetDocumentTitle(pageTitle);

  return (
    <div className="my-4">
      <>
        {displayTitle && titleKey ? (
          <PageTitleSimple>{translate(titleKey)}</PageTitleSimple>
        ) : null}{" "}
      </>
      <PageContainer>{children}</PageContainer>
    </div>
  );
}

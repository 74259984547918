import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { CrumbType } from "components/Breadcrumb";

export function getBreadCrumbs(): CrumbType[] {
  const crumbs: CrumbType[] = ["clients"];
  const clientId = getQueryStringValue("clientId");
  if (clientId) {
    crumbs.push({ page: "users", clientId: clientId, clientName: "" });
  }
  return crumbs;
}

import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Loading } from "@sprint1/pkg/src/loading";
import { PatientMedicalHistory } from "api/types/patientMedicalHistory";
import { Genders } from "common/genders";
import { ReactNode } from "react";
import { MedicalHistoryConfiguration } from "api/types/medicalHistoryConfiguration";
import { dateOfBirth } from "@sprint1/pkg/src/date/dateOfBirth";
import { PatientMedicalHistoryData } from "api/types/patientMedicalHistoryData";
import { MdOrthoUser } from "api/types/mdOrthoUser";
export function PatientRecord({
  medicalHistory,
  medicalHistoryConfig,
  patient,
}: {
  medicalHistory?: PatientMedicalHistory;
  medicalHistoryConfig?: MedicalHistoryConfiguration;
  patient?: MdOrthoUser;
}) {
  const { translate } = useTranslation();

  return (
    <>
      {!medicalHistory || !medicalHistoryConfig || !patient ? (
        <Loading />
      ) : (
        <>
          <Profile medicalHistory={medicalHistory.data} patient={patient} />
          <PharmacyInfo medicalHistory={medicalHistory.data} patient={patient} />
          <EmergencyContact medicalHistory={medicalHistory.data} />
          <Attribute
            title={translate("__medicalHistory")}
            attribute={medicalHistory.data.diseases}
            options={medicalHistoryConfig.diseases}
          />

          <Attribute
            title={translate("__surgicalHistory")}
            attribute={medicalHistory.data.surgicalHistories}
            options={medicalHistoryConfig.surgicalHistories}
          />

          <Attribute
            title={translate("__medications")}
            attribute={medicalHistory.data.medications}
            options={medicalHistoryConfig.medications}
          />
          <Attribute
            title={translate("__allergies")}
            attribute={medicalHistory.data.allergies}
            options={medicalHistoryConfig.allergies}
          />
        </>
      )}
    </>
  );
}

function Attribute({
  title,
  attribute,
  options,
}: {
  title: string;
  attribute: PatientMedicalHistory["data"]["diseases"];
  options: MedicalHistoryConfiguration["diseases"];
}) {
  return (
    <Section title={title}>
      <div>
        {attribute.values.map((id, index) => {
          const matchingOption = options.find((o) => o.id === id);
          return <div key={`d-${id}-${index}`}>{matchingOption?.name} </div>;
        })}
      </div>
      {attribute.other && <div>{attribute.other}</div>}
    </Section>
  );
}

function EmergencyContact({ medicalHistory }: { medicalHistory: PatientMedicalHistoryData }) {
  const { translate } = useTranslation();

  if (!medicalHistory.emergencyContactName && !medicalHistory.emergencyContactPhone) {
    return null;
  }
  return (
    <Section title={translate("__emergencyContact")}>
      <div>{medicalHistory.emergencyContactName}</div>
      <div>{medicalHistory.emergencyContactPhone}</div>
    </Section>
  );
}

function Profile({ medicalHistory, patient }: { medicalHistory: PatientMedicalHistoryData; patient: MdOrthoUser }) {
  const { translate } = useTranslation();
  return (
    <Section>
      {patient.dateOfBirth && (
        <div>
          <span>{dateOfBirth.tryFormat(patient.dateOfBirth)}</span>
          <span className="ms-2 fst-italic">
            (
            {translate("__format_noOfYearsOld", {
              years: dateOfBirth.calculateAge(patient.dateOfBirth),
            })}
            )
          </span>
        </div>
      )}
      <div>{getGender(medicalHistory.gender, translate)}</div>
      <div>{medicalHistory.smoke ? translate("__smoker") : translate("__nonSmoker")}</div>
      <div>{medicalHistory.alcohol ? translate("__drinker") : translate("__nonDrinker")}</div>
    </Section>
  );
}

function PharmacyInfo({
  medicalHistory,
  patient,
}: {
  medicalHistory: PatientMedicalHistoryData;
  patient: MdOrthoUser;
}) {
  const { translate } = useTranslation();
  return (
    <Section title={translate("__pharmacy")}>
      <div>
        <span>{translate("__name")}:</span>
        <span className="ms-2">{medicalHistory.pharmacy?.name}</span>
      </div>
      <div>
        <span>{translate("__address")}:</span>
        <span className="ms-2">{medicalHistory.pharmacy?.address}</span>
      </div>
      <div>
        <span>{translate("__phone")}:</span>
        <span className="ms-2">{medicalHistory.pharmacy?.phoneNumber}</span>
      </div>
    </Section>
  );
}

function Section({ title, children }: { title?: string; children: ReactNode }) {
  return (
    <div
      className="my-2 pb-2 "
      style={{
        borderStyle: "none none dotted none",
        borderColor: "var(--bs-gray-200)",
      }}
    >
      {title && <div className="fw-bolder">{title}</div>}
      {children}
    </div>
  );
}
function getGender(gender: string | undefined, translate: TranslateFnType) {
  switch (gender?.toLocaleLowerCase()) {
    case Genders.Male.toLocaleLowerCase():
      return translate("__male");
    case Genders.Female.toLocaleLowerCase():
      return translate("__female");
    default:
      return gender;
  }
}

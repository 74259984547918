import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import React, { useState, useEffect } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { routes } from "routes/routes.config";
import { prepare } from "api/client/payment/prepare";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { ScheduleRequest } from "api/types/scheduleRequest";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { PageLayout } from "../components/PageLayout";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { CtaButton } from "components/CtaButton";
import Accordion from "react-bootstrap/Accordion";

export function Pay() {
  const [clientSecret, setClientSecret] = useState<string>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [scheduleRequest, setScheduleRequest] = useState<ScheduleRequest>();

  const toast = useToast();
  useRunOnMount(() => {
    async function load() {
      const qs = getQueryStringValue("data");
      const scheduleRequest = qs ? (JSON.parse(qs) as ScheduleRequest) : undefined;
      if (!scheduleRequest?.injuryId || !scheduleRequest?.notification) {
        toast.error({ type: "BadUrl" });
        return;
      }
      setScheduleRequest(scheduleRequest);
      const prepareResponse = await preparePayment(scheduleRequest);
      setClientSecret(prepareResponse.paymentIntentResponse.clientSecret);
      setStripePromise(prepareResponse.stripePromise);
    }
    load();
  });

  if (!clientSecret || !stripePromise || !scheduleRequest) return <ViewPortLoading />;

  return (
    <div>
      <script src="https://js.stripe.com/v3/"></script>
      <PageLayout titleKey="__payment">
        <div className="row justify-content-md-center">
          <div className="col-md-4 ">
            {clientSecret && stripePromise && (
              <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                <CheckoutForm scheduleRequest={scheduleRequest} />
              </Elements>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
}

function CheckoutForm({ scheduleRequest }: { scheduleRequest: ScheduleRequest }) {
  const stripe = useStripe();
  const elements = useElements();
  const { translate } = useTranslation();

  const [errorCode, setErrorCode] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function load() {
      if (!stripe) {
        return;
      }
    }
    load();
  }, [stripe]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${routes.patient.selfPay.paymentSuccess}`,
      },
    });

    if (error?.code) {
      let errorCode = `code: ${error.code}`;
      if (error?.decline_code) {
        errorCode = `${errorCode} decline_code: ${error.decline_code}`;
      }
      setErrorCode(errorCode);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="text-center my-2 mb-5">
        <div className="fs-4">{translate("__youWillBeCharged")}</div>
        <div className="fs-2">$150</div>
        <div className="my-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{translate("__rescheduleCancellationPolicy")}</Accordion.Header>
              <Accordion.Body className="text-start ">{translate("__cancellationPolicyDescription")}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={{ layout: "tabs" }} />

        <div className="d-grid gap-2 my-3">
          <CtaButton type="submit" disabled={isLoading || !stripe || !elements} showSpinner={isLoading}>
            {translate("__payNow")}
          </CtaButton>
        </div>

        {errorCode && <div className="text-danger text-center">{translate("__paymentError", { code: errorCode })}</div>}
      </form>
    </div>
  );
}

async function preparePayment(request: ScheduleRequest) {
  const response = await prepare({ request });
  const stripePromise = loadStripe(response.data.publishableKey);
  return { paymentIntentResponse: response.data, stripePromise };
}

import { Header, HeaderType } from "@sprint1/pkg/src/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons/faBell";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { useUser } from "@sprint1/pkg/src/auth/user/context";
import { getMainLinks, useAdditionalLinks } from "./link";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { getUnreadMessageCount } from "api/client/messageThread/getUnreadMessageCount";
import { useEffect, useRef, useState } from "react";
import { useLocation, Location } from "react-router-dom";
import { useAppUser } from "common/useAppUser";

export interface AppHeaderType {
  containerType?: HeaderType["containerType"];
}
export function AppHeader({ containerType }: AppHeaderType) {
  const { translate } = useTranslation();
  const { user, hasRole } = useUser();
  const appUser = useAppUser();
  const { additionalLinks } = useAdditionalLinks();
  const mainLinks = getMainLinks(translate, hasRole, appUser, user);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const location = useLocation();
  const prevLocationRef = useRef<Location>();
  const isFetchingRef = useRef<boolean>(false);

  useRunOnMount(() => {
    async function load() {
      const count = await getUnreadMessageCountWrapper(isLoggedIn());
      setUnreadMessageCount(count);
    }
    load();
  });

  const isLoggedIn = appUser.isLoggedIn;
  useEffect(() => {
    async function load() {
      if (prevLocationRef.current?.pathname === "/messages") {
        if (!isFetchingRef.current) {
          //Whey are we checking isFetchingRef here?
          //Location change gets triggers multiple times when user leaves a route. We just want to fire only once.
          try {
            isFetchingRef.current = true;
            const count = await getUnreadMessageCountWrapper(isLoggedIn());
            setUnreadMessageCount(count);
          } finally {
            isFetchingRef.current = false;
          }
        }
      } else {
        prevLocationRef.current = location;
      }
    }
    load();
  }, [isLoggedIn, location]);

  return (
    <Header
      logoUrl="/public/logo.jpg"
      mainLinks={mainLinks}
      CallToAction={
        <Nav.Link as={NavLink} to={"/messages"} title={translate("__messages")}>
          <div className="position-relative">
            <FontAwesomeIcon className="" icon={faBell} />
            {unreadMessageCount > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle" />
            )}
          </div>
        </Nav.Link>
      }
      additionalRouterLinks={additionalLinks}
      containerType={containerType}
    />
  );
}

async function getUnreadMessageCountWrapper(isUserLoggedIn: boolean) {
  if (isUserLoggedIn) {
    const { data } = await getUnreadMessageCount();
    return data;
  }
  return 0;
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { PatientInjuryQuestionnaire } from "api/types/patientInjuryQuestionnaire";

export function saveQuestionnaire(arg: SaveQuestionnaireArgType) {
  //__queryString__
  return fetchJson<void>(`/api/Injury/Questionnaire`, {
    method: "POST",
    body: arg.request,
  });
}

export function useSaveQuestionnaire(arg?: UseSaveQuestionnaireArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => saveQuestionnaire(arg) : undefined,
  });

  const saveQuestionnaireCb = useCallback(
    (arg: SaveQuestionnaireArgType) => {
      return run(() => saveQuestionnaire(arg));
    },
    [run]
  );
  return {
    ...rest,
    saveQuestionnaire: saveQuestionnaireCb,
    
  };
}

export interface SaveQuestionnaireArgType {
  request: PatientInjuryQuestionnaire;
}

export type UseSaveQuestionnaireArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & SaveQuestionnaireArgType);


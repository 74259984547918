import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ScheduleResponse } from "api/types/scheduleResponse";
import { ScheduleRequest } from "api/types/scheduleRequest";

export function schedule(arg: ScheduleArgType) {
  //__queryString__
  return fetchJson<ScheduleResponse>(`/api/Cronofy/Schedule`, {
    method: "POST",
    body: arg.request,
  });
}

export function useSchedule(arg?: UseScheduleArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => schedule(arg) : undefined,
  });

  const scheduleCb = useCallback(
    (arg: ScheduleArgType) => {
      return run(() => schedule(arg));
    },
    [run]
  );
  return {
    ...rest,
    schedule: scheduleCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface ScheduleArgType {
  request: ScheduleRequest;
}

export type UseScheduleArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ScheduleArgType);


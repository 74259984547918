import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { CreateUserResponse } from "@sprint1/pkg/src/api/auth/types/createUserResponse";
import { MdOrthoUser } from "api/types/mdOrthoUser";

export function createUser(arg: CreateUserArgType) {
  //__queryString__
  return fetchJson<CreateUserResponse>(`/api/User/Create`, {
    method: "POST",
    body: arg.mdOrthoUser,
  });
}

export function useCreateUser(arg?: UseCreateUserArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => createUser(arg) : undefined,
  });

  const createUserCb = useCallback(
    (arg: CreateUserArgType) => {
      return run(() => createUser(arg));
    },
    [run]
  );
  return {
    ...rest,
    createUser: createUserCb,
    data: rest?.fetchJsonResponse?.data,
  };
}

export interface CreateUserArgType {
  mdOrthoUser: MdOrthoUser;
}

export type UseCreateUserArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CreateUserArgType);


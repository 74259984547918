import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";

export function ProviderInfo({
  encounter,
  showLogo = true,
  showSignature,
}: {
  encounter: MdOrthoPatientEncounter;
  showLogo?: boolean;
  showSignature?: boolean;
}) {
  return (
    <div className="d-flex justify-content-between pb-2 border-bottom">
      <div className="d-flex justify-content-end flex-column">
        {showLogo && (
          <img src="/public/logo.jpg" alt="logo" style={{ width: "10rem", height: "5rem", objectFit: "scale-down" }} />
        )}
      </div>
      <div className="text-end">
        {showSignature && encounter.providerDigitalSignatureUrl && (
          <div style={{ minHeight: "5rem" }}>
            <img
              src={encounter.providerDigitalSignatureUrl}
              alt={"signature"}
              style={{
                width: "20rem",
                height: "5rem",
                objectFit: "scale-down",
              }}
            />
          </div>
        )}

        <div>
          {encounter.providerPrefix && <span className="">{encounter.providerPrefix}</span>}
          {encounter.providerFirstName && <span className="ms-1">{encounter.providerFirstName}</span>}
          {encounter.providerMiddleName && <span className="ms-1">{encounter.providerMiddleName}</span>}
          {encounter.providerLastName && <span className="ms-1">{encounter.providerLastName}</span>}
          {encounter.providerSuffix && <span className="ms-1">{encounter.providerSuffix}</span>}
          <span className="mx-1">-</span>
          <span className="mx-1">DEA# {encounter.providerLicenseNumber}</span>
        </div>
        <div>
          <span className="mx-1">NPI: {encounter.providerNpiNumber}</span>
        </div>
        <div className="pt-2">485 Hillsdale Dr., Suite 341 Charlottesville, VA 22901</div>
        <div>
          <span className="mx-1">Phone: (434) 290-2321</span>
          <span className="mx-1">&#8226;</span>
          <span className="mx-1">Fax: 434 202 8580</span>
        </div>
      </div>
    </div>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { UserHeader } from "api/types/userHeader";

export function getPatientsWithInjuries(arg: GetPatientsWithInjuriesArgType = {}) {
  //__queryString__
  return fetchJson<UserHeader[]>(`/api/User/PatientsWithInjuries`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetPatientsWithInjuries(arg?: UseGetPatientsWithInjuriesArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getPatientsWithInjuries(arg) : undefined,
  });

  const getPatientsWithInjuriesCb = useCallback(
    (arg: GetPatientsWithInjuriesArgType = {}) => {
      return run(() => getPatientsWithInjuries(arg));
    },
    [run]
  );
  return {
    ...rest,
    getPatientsWithInjuries: getPatientsWithInjuriesCb,
    patientsWithInjuries: rest?.fetchJsonResponse?.data,
  };
}

export interface GetPatientsWithInjuriesArgType {
  //__ArgTypeBody__
}

export type UseGetPatientsWithInjuriesArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetPatientsWithInjuriesArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { MdOrthoPatientResult } from "api/types/mdOrthoPatientResult";

export function getPatientResults(arg: GetPatientResultsArgType) {
  const qs = convertToQueryString( {patientId: arg.patientId })
  return fetchJson<MdOrthoPatientResult[]>(`/api/patient/result/Get${qs}`, {
    method: "GET",
    body: undefined,
  });
}

export function useGetPatientResults(arg?: UseGetPatientResultsArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => getPatientResults(arg) : undefined,
  });

  const getPatientResultsCb = useCallback(
    (arg: GetPatientResultsArgType) => {
      return run(() => getPatientResults(arg));
    },
    [run]
  );
  return {
    ...rest,
    getPatientResults: getPatientResultsCb,
    patientResults: rest?.fetchJsonResponse?.data,
  };
}

export interface GetPatientResultsArgType {
  patientId: string;
}

export type UseGetPatientResultsArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & GetPatientResultsArgType);


import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";




export function cancelPatientEncounter(arg: CancelPatientEncounterArgType) {
  const qs = convertToQueryString( {patientEncounterId: arg.patientEncounterId })
  return fetchJson<void>(`/api/patient/encounter/Cancel${qs}`, {
    method: "DELETE",
    body: undefined,
  });
}

export function useCancelPatientEncounter(arg?: UseCancelPatientEncounterArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => cancelPatientEncounter(arg) : undefined,
  });

  const cancelPatientEncounterCb = useCallback(
    (arg: CancelPatientEncounterArgType) => {
      return run(() => cancelPatientEncounter(arg));
    },
    [run]
  );
  return {
    ...rest,
    cancelPatientEncounter: cancelPatientEncounterCb,
    
  };
}

export interface CancelPatientEncounterArgType {
  patientEncounterId: string;
}

export type UseCancelPatientEncounterArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & CancelPatientEncounterArgType);


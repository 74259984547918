import { AndOrOperator } from "@sprint1/pkg/src/api/common/types/andOrOperator";
import { ComparisonType } from "@sprint1/pkg/src/api/common/types/comparisonType";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { getEndOfToday } from "@sprint1/pkg/src/date/getEndOf";
import { getStartOfToday } from "@sprint1/pkg/src/date/getStartOf";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { useGetBodyParts } from "api/client/body/getBodyParts";
import { useSearchCareTeams } from "api/client/careTeam/searchCareTeams";
import { useGetInjury } from "api/client/injury/getInjury";
import { useListPatientEncounters } from "api/client/patientEncounter/listPatientEncounters";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { PatientEncounter } from "api/types/patientEncounter";
import { useAppUser } from "common/useAppUser";
import { useCallback, useEffect, useState } from "react";

export function useData() {
  const appUser = useAppUser();
  const [startDate, setStartDate] = useState(() => getInitialDates().startDate);
  const [endDate, setEndDate] = useState(() => getInitialDates().endDate);
  //TODO Change this to encounter
  const [selectedEncounter, setSelectedEncounter] = useState<MdOrthoPatientEncounter | undefined>();

  const { bodyParts } = useGetBodyParts({ runOnMount: true });
  const { careTeams } = useSearchCareTeams({ runOnMount: true, request: { filterFields: [], sortFields: [] } });
  const [selectedDoctorId, setSelectedDoctorId] = useState(() => (appUser.isDoctor ? appUser.user?.id : undefined));

  const { patientEncounters, listPatientEncounters, isRunning: isAppointmentLoading } = useListPatientEncounters();

  const { injury, getInjury } = useGetInjury({ runOnMount: false });

  const refreshAppointments = useCallback(async () => {
    const { data } = await listPatientEncounters({
      request: createFilters(selectedDoctorId, startDate, endDate),
    });
    if (data?.results?.length > 0) {
      setSelectedEncounter(data.results[0]);
    } else {
      setSelectedEncounter(undefined);
    }
  }, [endDate, listPatientEncounters, selectedDoctorId, startDate]);

  useEffect(() => {
    refreshAppointments();
  }, [refreshAppointments]);

  useEffect(() => {
    if (selectedEncounter?.patientInjuryId) {
      getInjury({ injuryId: selectedEncounter.patientInjuryId });
    }
  }, [getInjury, selectedEncounter]);

  return {
    patientEncounters,
    startDate,
    endDate,
    setStartDate,
    setEndDate,

    selectedEncounter,
    setSelectedEncounter,
    isAppointmentLoading,

    injury,
    bodyParts,

    careTeams,

    selectedDoctorId,
    setSelectedDoctorId,

    refreshAppointments,
  };
}
export type UseDataReturnType = ReturnType<typeof useData>;

function createFilters(selectedDoctorId?: string, startDate?: Date, endDate?: Date) {
  const tmpStartDate = startDate ?? getStartOfToday();
  const tmpEndDate = endDate ?? getEndOfToday();
  const name = nameof<PatientEncounter>;

  const request: FilterAndSortRequest = {
    filterFields: [
      {
        field: name("startTime"),
        value: tmpStartDate.toISOString(),
        comparisonType: ComparisonType.GreaterThanOrEqual,
        andOrOperator: AndOrOperator.And,
      },
      {
        field: name("startTime"),
        value: tmpEndDate.toISOString(),
        comparisonType: ComparisonType.LessThanOrEqual,
        andOrOperator: AndOrOperator.And,
      },
      {
        field: name("cancelled"),
        value: "false",
        comparisonType: ComparisonType.Equals,
        andOrOperator: AndOrOperator.And,
      },
    ],
    sortFields: [{ field: name("startTime"), ascending: true }],
  };

  if (selectedDoctorId) {
    request.filterFields.push({
      field: name("providerId"),
      value: selectedDoctorId,
      comparisonType: ComparisonType.Equals,
      andOrOperator: AndOrOperator.And,
    });
  }

  return request;
}

function getInitialDates() {
  const startDate = getStartOfToday();
  const endDate = getEndOfToday();

  return { startDate, endDate };
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UploadButtonType, UploadButton } from "@sprint1/pkg/src/upload/UploadButton";
import React from "react";
import { uploadToS3Async } from "./uploadToS3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons/faTrashCan";

export interface UploadedFileType {
  name: string;
  tmpUrl: string;
  key: string;
}
export interface UploadFilesType extends Partial<UploadButtonType> {
  onChange: (payload: { files: UploadedFileType[] }) => void;
  allowMultipleFiles?: boolean;
}

export function UploadFiles(props: UploadFilesType) {
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFileType[]>([]);
  const { translate } = useTranslation();

  const fileInputProps = { multiple: props.allowMultipleFiles, ...(props.fileInput ?? {}) };
  return (
    <>
      <UploadButton
        fileInput={fileInputProps}
        text={fileInputProps.multiple ? translate("__selectFiles") : translate("__selectAFile")}
        {...props}
        uploadFileAsync={async ({ files }) => {
          for (const file of files) {
            const { url, key } = await uploadToS3Async(file);
            setUploadedFiles((prevFiles) => {
              if (props.allowMultipleFiles) {
                const newFiles = [...prevFiles, { name: file.name, tmpUrl: url, key: key }];
                props.onChange({ files: newFiles });
                return newFiles;
              } else {
                const newFiles = [{ name: file.name, tmpUrl: url, key: key }];
                props.onChange({ files: newFiles });
                return newFiles;
              }
            });
          }
        }}
      />
      {uploadedFiles.length > 0 && (
        <div>
          {uploadedFiles.map((f, i) => {
            return (
              <div key={`${f.name}-${f.tmpUrl}-${i}`} className="my-1">
                <a href={f.tmpUrl} target="_blank" rel="noreferrer">
                  {f.name}
                </a>
                <button
                  type="button"
                  className=" btn btn-sm btn-link"
                  onClick={() => {
                    setUploadedFiles((prevFiles) => {
                      const newFiles = prevFiles.filter((pf) => !(f.name === pf.name && f.tmpUrl === pf.tmpUrl));
                      props.onChange({ files: newFiles });
                      return newFiles;
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

import { useCallback } from "react";

import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";
import { useFetchJsonAsync } from "@sprint1/pkg/src/fetch/useFetchJsonAsync";
//__convertToQueryString__ import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";


import { ToggleArchivedRequest } from "api/types/toggleArchivedRequest";

export function toggleArchived(arg: ToggleArchivedArgType) {
  //__queryString__
  return fetchJson<void>(`/api/MessageThread/ToggleArchived`, {
    method: "POST",
    body: arg.request,
  });
}

export function useToggleArchived(arg?: UseToggleArchivedArgType) {
  const { run, ...rest } = useFetchJsonAsync({
    runOnMount: arg?.runOnMount,
    asyncFn: arg?.runOnMount ? () => toggleArchived(arg) : undefined,
  });

  const toggleArchivedCb = useCallback(
    (arg: ToggleArchivedArgType) => {
      return run(() => toggleArchived(arg));
    },
    [run]
  );
  return {
    ...rest,
    toggleArchived: toggleArchivedCb,
    
  };
}

export interface ToggleArchivedArgType {
  request: ToggleArchivedRequest;
}

export type UseToggleArchivedArgType =
  | { runOnMount: false }
  | ({ runOnMount: true } & ToggleArchivedArgType);


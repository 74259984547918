import { useCallback, useEffect, useRef } from "react";
import * as CronofyElements from "cronofy-elements";
import { DateTimePickerResponse } from "api/types/dateTimePickerResponse";
import { CronofyNotification } from "api/types/cronofyNotification";
import "./styles.css";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useDateTimePicker } from "api/client/cronofy/dateTimePicker";
export interface AvailabilityPickerType {
  onTimePicked: (notification: CronofyNotification) => any;
  provider?: MdOrthoUser;
  overrideAvailability?: boolean;
}
export function AvailabilityPicker({ onTimePicked, provider, overrideAvailability = false }: AvailabilityPickerType) {
  const elementRef = useRef(null);
  const { dateTimePickerApi, onTimePickedCb } = useData(onTimePicked);

  const { dateTimePicker } = dateTimePickerApi;
  useEffect(() => {
    async function load() {
      const { data } = await dateTimePicker({
        request: { doctorId: provider?.id, overrideAvailability: overrideAvailability },
      });
      let options = getDefaultOptions(data, onTimePickedCb);
      if (!elementRef.current) {
        elementRef.current = CronofyElements.DateTimePicker(options);
      } else {
        //@ts-ignore
        elementRef.current.update(options);
      }
    }
    load();
  }, [dateTimePicker, onTimePickedCb, overrideAvailability, provider?.id]);

  return <div id="cronofy-date-time-picker" />;
}

function useData(onTimePicked: AvailabilityPickerType["onTimePicked"]) {
  const dateTimePickerApi = useDateTimePicker({ runOnMount: false });
  //why do we need this ref? so we don't have to update the control every time the onTimePicked prop changes
  const onTimePickedRef = useRef(onTimePicked);
  onTimePickedRef.current = onTimePicked;
  const onTimePickedCb = useCallback((notification: CronofyNotification) => {
    if (onTimePickedRef.current) {
      onTimePickedRef.current(notification);
    }
  }, []);

  return { dateTimePickerApi, onTimePickedCb };
}

function getDefaultOptions(
  dateTimePickerResponse: DateTimePickerResponse,
  onTimePicked: (notification: CronofyNotification) => void
) {
  const options: Partial<DateTimePickerResponse> & {
    target_id: string;
    styles: {};
    callback: ({ notification }: { notification: CronofyNotification }) => any;
    config: {
      mode: "no_confirm";
    };
  } = {
    target_id: "cronofy-date-time-picker",
    styles: {
      prefix: "mdo-cronofy-dtm",
    },
    callback: ({ notification }: { notification: CronofyNotification }) => {
      onTimePicked(notification);
    },
    config: {
      mode: "no_confirm",
    },
  } as const;

  const final = { ...dateTimePickerResponse, ...options };
  return final;
}

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useFormatName } from "common/useFormatName";
import { Link } from "react-router-dom";
import { routes } from "routes/routes.config";
import { AccessLevelBadge } from "../common/AccessLevelBadge";

export function PatientInfo({ patient }: { patient: MdOrthoUser }) {
  const { translate } = useTranslation();
  const format = useFormatName();

  return (
    <div className="my-2 d-flex justify-content-between">
      <div>
        <div className="fs-5 fw-bold d-flex align-items-center">
          {format.formatName(patient.lastName, patient.firstName)}
          <AccessLevelBadge accessLevel={patient.accessLevel} className="ms-2" />
        </div>
        {patient.phoneNumber && (
          <a className="fw-bold" href={`tel:${patient.phoneNumber}`}>
            {patient.phoneNumber}
          </a>
        )}
        {patient.email && (
          <a className="fw-bold ms-2" href={`mailto:${patient.email}`}>
            {patient.email}
          </a>
        )}
      </div>
      <div>
        <Link to={routes.patient.selectBodyPart.url().fullUrl} className="btn btn-outline-primary btn-sm">
          {translate("__scheduleAppointment")}
        </Link>
        <div className="text-end px-2">{patient.tenantName}</div>
      </div>
    </div>
  );
}

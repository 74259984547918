import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ReactNode } from "react";

export function SubSection({
  titleKey,
  children = null,
  fontSize = "fs-6",
}: {
  titleKey: string;
  fontSize?: string;
  //TODO Change it to not null
  children?: ReactNode;
}) {
  const { translate } = useTranslation();
  return (
    <>
      <div className={`mb-1 pb-1 ${fontSize} border-bottom border-dark border-2`}>{translate(titleKey)}</div>
      {children}
    </>
  );
}

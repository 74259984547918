import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { ConfirmModal } from "@sprint1/pkg/src/modal/ConfirmModal";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useAvailabilityRules } from "api/client/cronofy/availabilityRules";
import { useDisconnectCalendar } from "api/client/cronofy/disconnectCalendar";
import { searchUsers } from "api/client/user/searchUsers";
import { userFilters } from "api/helpers/searchUsers.helper";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useAppUser } from "common/useAppUser";
import { CtaButton } from "components/CtaButton";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useState } from "react";
import { routes } from "routes/routes.config";

import * as CronofyElements from "cronofy-elements";
import React from "react";

export function Settings() {
  const data = useData();
  const { translate } = useTranslation();

  return (
    <PageLayout>
      <PageTitleSimple title={translate("__settings")} left={translate("__settings")} />
      <ConfirmModal
        variant="yesNo"
        show={data.showConfirm}
        content={translate("__areYouSureYouWantToUnlinkYourCalendar?")}
        noButton={{
          onClick() {
            data.setShowConfirm(false);
          },
        }}
        yesButton={{
          onClick() {
            data.setShowConfirm(false);
            data.disconnect();
          },
        }}
      />
      {!data.user && <ViewPortLoading />}
      {data.user && (
        <div>
          {!data.user.cronofySubscriptionId && (
            <div>
              <span>{translate("__calendarIsNotSetup")}</span>
              <a
                className="ms-2 btn btn-sm btn-outline-primary"
                href={routes.initiateCalendarConnectUrl()}
                rel="noreferrer"
              >
                {translate("__linkYourCalendar")}
              </a>
            </div>
          )}
          {data.user.cronofySubscriptionId && (
            <div>
              <div>
                <span>{translate("__calendarSetupProperly")}</span>

                <span className="ms-2">Set your working hours below.</span>

                <CtaButton
                  type="button"
                  size="small"
                  disabled={data.loading}
                  showSpinner={data.loading}
                  className="ms-2 btn btn-sm btn-outline-primary"
                  onClick={() => {
                    data.setShowConfirm(true);
                  }}
                >
                  {translate("__unlinkYourCalendar")}
                </CtaButton>
              </div>
              <div className="my-3 w-75">
                <WorkingHours key={data.user.cronofySubscriptionId} />
              </div>
            </div>
          )}
        </div>
      )}
    </PageLayout>
  );
}

function useData() {
  const appUser = useAppUser();
  const disconnectApi = useDisconnectCalendar();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<MdOrthoUser>();
  const [showConfirm, setShowConfirm] = useState(false);

  useRunOnMount(() => {
    async function load() {
      if (appUser.user?.id) {
        const { data } = await searchUsers({ request: userFilters.searchUser(appUser.user.id) });
        if (data.results.length > 0) {
          setLoading(false);
          setUser(data.results[0]);
        }
      } else {
        //this should not happen, we redirect if user is not logged in
      }
    }
    load();
  });

  async function disconnect() {
    if (!appUser?.user?.id) {
      //We will never get here.
      return;
    }
    setLoading(true);
    await disconnectApi.disconnectCalendar();
    const { data } = await searchUsers({ request: userFilters.searchUser(appUser.user.id) });
    if (data.results.length > 0) {
      setUser(data.results[0]);
    }
    setLoading(false);
  }

  return { user, disconnect, loading, showConfirm, setShowConfirm };
}

function WorkingHours() {
  const data = useWorkingHoursData();
  const [element, setElement] = useState(null);

  React.useEffect(() => {
    if (!data.availabilityRulesApi.data) {
      return;
    }

    if (!element) {
      const response = CronofyElements.AvailabilityRules({
        target_id: "cronofy-availability-rules",
        ...data.availabilityRulesApi.data,
      });
      setElement(response);
    }
  }, [data.availabilityRulesApi.data, element]);

  return <div id="cronofy-availability-rules" />;
}

function useWorkingHoursData() {
  const availabilityRulesApi = useAvailabilityRules({ runOnMount: true });

  return { availabilityRulesApi };
}

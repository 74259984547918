import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { OrderType } from "api/types/orderType";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { UseDataReturnType } from "../../useData";
import { Order } from "api/types/order";

export function ImagingSection({
  patientEncounter,
  encounterViewModel,
}: {
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
}) {
  const { translate } = useTranslation();

  const vm = getOrderViewModel(patientEncounter.data.orders, encounterViewModel);
  return (
    <div className="ms-3 ">
      <div className="fw-bold">{translate("__orders")}</div>
      {vm.length === 0 && <div>{translate("__none")}</div>}
      {vm.map((i, index) => {
        return (
          <div className="ms-3 mt-2" key={`imaging-${i.type}-${index}`}>
            <div className="fw-bold">{i.type}</div>
            <div className="fw-bold mt-1">{translate("__instructions")}</div>
            <pre>{i.instructions}</pre>
          </div>
        );
      })}
    </div>
  );
}

function getOrderViewModel(orders: Order[], encounterViewModel: UseDataReturnType["encounterViewModel"]) {
  function getType(type?: OrderType) {
    if (type !== undefined) {
      return encounterViewModel?.orderOptions[type];
    } else {
      return "";
    }
  }

  const nonEmptyImagingOrders = orders.filter(
    (i) => isDefined(i.type) || isDefined(i.instructions) || isDefined(i.other)
  );

  return nonEmptyImagingOrders.map((i) => {
    return {
      type: i.type === OrderType.Other ? i.other : getType(i.type),
      instructions: i.instructions,
    };
  });
}

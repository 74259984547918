import { ReactNode } from "react";
import { useSetDocumentTitle } from "./useSetDocumentTitle";

export interface PageLayoutType {
  children: ReactNode;
  breadcrumb?: ReactNode;
  containerType?: "container" | "container-fluid";
}

export function PageLayout({ breadcrumb = null, children, containerType }: PageLayoutType) {
  return (
    <div className={` ${containerType ?? ""} mt-3 p-2`}>
      {breadcrumb}
      <div className={`bg-white rounded py-2 px-4`}>{children}</div>
    </div>
  );
}

export interface PageTitleSimpleType {
  title?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  compact?: boolean;
}

export function PageTitleSimple({ title, left, right, compact }: PageTitleSimpleType) {
  useSetDocumentTitle(title);
  const tmpLeft = left ?? title;
  return (
    <div className={`${compact ? "" : "my-3"}`}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {tmpLeft && typeof tmpLeft === "string" && <Text title={tmpLeft} />}
          {tmpLeft && typeof tmpLeft !== "string" && <>{tmpLeft}</>}
        </div>
        <div className="d-flex align-items-center">{right}</div>
      </div>
      <div className={`border-bottom border-dark border-2 ${compact ? "mt-1" : "mt-2"} w-100`}></div>
    </div>
  );
}

function Text({ title }: { title: ReactNode }) {
  return <h1 className="text-primary text-uppercase fs-5">{title}</h1>;
}

function SubText({ subTitle }: { subTitle: string }) {
  return <div className="px-1 text-muted">{subTitle}</div>;
}

PageTitleSimple.Text = Text;
PageTitleSimple.SubText = SubText;

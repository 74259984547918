export enum RolesEnum {
  Patient = "Patient",
  Administrator = "Administrator",
  Doctor = "MD Ortho Doctor",
  MDOrthoNurse = "MD Ortho Nurse",
  OnsiteNurse = "Site Nurse",
}

export const roles = {
  enum: RolesEnum,
  hasRole(role: RolesEnum, roles: string[]): boolean {
    return roles.includes(role);
  },
};

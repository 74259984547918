import { useReducer } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { UploadButton } from "@sprint1/pkg/src/upload/UploadButton";
import { uploadToS3Async } from "common/uploadToS3";
import { useImportUsers } from "api/client/user/importUsers";
import { ImportUsersResponse } from "api/types/importUsersResponse";

import { S1Table } from "@sprint1/pkg/src/table/Components";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { UserType } from "api/types/userType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { s1Date } from "@sprint1/pkg/src/date";
import { PrimaryButton } from "@sprint1/pkg/src/button/Primary";

export function UploadEmployees() {
  const toast = useToast();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const importUsersApi = useImportUsers();

  const { translate } = useTranslation();
  useRunOnMount(() => {
    async function load() {
      const clientId = getQueryStringValue("clientId");
      const clientName = getQueryStringValue("clientName");
      if (!(clientId && clientName)) {
        toast.error({ type: "BadUrl" });
      } else {
        dispatch({ type: "set-client-info", value: { clientId, clientName } });
      }
    }
    load();
  });

  if (state.client) {
    return (
      <PageLayout>
        <PageTitleSimple
          title={translate("__uploadEmployees")}
          left={translate("__uploadEmployees")}
          right={<div>{state.client.name}</div>}
        />
        <div>
          <div>
            Please upload a CSV file with employee details. Make sure it matches the{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1WOEFROuJfIdnu-uaMgMeS9jixjRVD0l8ao7IXiDtYeY"
              target="__blank"
            >
              template
            </a>
            .
          </div>
          <div className="my-3">
            <UploadButton
              fileInput={{ accept: ".csv" }}
              text={translate("__selectAFile")}
              uploadFileAsync={async ({ file }) => {
                const { url } = await uploadToS3Async(file);
                dispatch({ type: "file-uploaded", value: { fileName: file.name, fileUrl: url } });

                const { data } = await importUsersApi.importUsers({
                  request: {
                    tenantId: state.client!.id,
                    temporaryFileLocation: state.uploadedFile!.fileUrl,
                    isTestRun: true,
                  },
                });
                dispatch({ type: "import-users", value: { importUsers: data } });
              }}
            />

            <div className="my-3">
              {state.uploadedFile && (
                <>
                  <PrimaryButton
                    className="btn btn-primary"
                    onClick={async () => {
                      dispatch({ type: "set-uploading-progress", value: { uploading: true } });
                      const { data } = await importUsersApi.importUsers({
                        request: {
                          tenantId: state.client!.id,
                          temporaryFileLocation: state.uploadedFile!.fileUrl,
                          isTestRun: false,
                        },
                      });
                      dispatch({ type: "import-users", value: { importUsers: data } });
                      dispatch({ type: "set-uploading-progress", value: { uploading: false } });
                    }}
                    loading={state.uploading}
                    disabled={state.uploading}
                  >
                    {translate("__AddValidUsers")}
                  </PrimaryButton>
                </>
              )}
            </div>

            {state.importUsers && (
              <>
                <div className="my-3" />
                <Employees employees={state.importUsers} />
              </>
            )}
          </div>
        </div>
      </PageLayout>
    );
  }

  return <ViewPortLoading />;
}
function Employees({ employees }: { employees: ImportUsersResponse[] }) {
  const { translate } = useTranslation();

  const stats = {
    totalUsers: employees.length,
    valid: employees.filter((employee) => employee.isValid).length,
    invalid: employees.filter((employee) => !employee.isValid).length,
    new: employees.filter((employee) => employee.isExistingUser === false).length,
    existing: employees.filter((employee) => employee.isExistingUser === true).length,
    success: employees.filter((employee) => employee.userCreateDateTime).length,
    error: employees.filter((employee) => employee.error).length,
    employees: employees.filter((employee) => employee.type === UserType.Employee).length,
    adultDependant: employees.filter((employee) => employee.type === UserType.Dependent).length,
    childDependant: employees.filter((employee) => employee.type === UserType.ChildDependent).length,
    unknownUserTypes: employees.filter((employee) => employee.type === UserType.Unknown).length,
  };
  return (
    <>
      <div>
        <div className="py-3">
          <div>{`Total Users: ${stats.totalUsers}`}</div>
          <div>
            <span className="me-2">{`Employees: ${stats.employees} `}</span>
            <span className="me-2">{`Dependant: ${stats.adultDependant} `}</span>
            <span className="me-2">{`Child: ${stats.childDependant} `}</span>
            <span className="me-2 text-danger">{`Unknown: ${stats.unknownUserTypes}`}</span>
            <span className="me-2">{`Total: ${
              stats.employees + stats.adultDependant + stats.childDependant + stats.unknownUserTypes
            }`}</span>
          </div>
          <div>
            <span className="me-2">{`Valid: ${stats.valid} `}</span>
            <span className="me-2 text-danger">{`Invalid: ${stats.invalid} `}</span>
            <span className="me-2">{`Total: ${stats.valid + stats.invalid}`}</span>
          </div>
          <div>
            <span className="me-2">{`New: ${stats.new} `}</span>
            <span className="me-2">{`Existing: ${stats.existing} `}</span>
            <span className="me-2 text-success">{`Success: ${stats.success} `}</span>
            <span className="me-2 text-danger">{`Error: ${stats.error} `}</span>
            <span className="me-2">{`Total: ${stats.new + stats.existing}`}</span>
          </div>
        </div>
      </div>
      <S1Table.Table>
        <S1Table.THead>
          <tr>
            <S1Table.Th label={"Status"} />
            <S1Table.Th width="10rem" label={"Notes"} />

            <S1Table.Th label={translate("__type")} />
            <S1Table.Th label={translate("__employeeId")} />

            <S1Table.Th width="10rem" label={translate("__firstName")} />
            <S1Table.Th label={translate("__lastName")} />
            <S1Table.Th label={translate("__dob")} />
            <S1Table.Th label={translate("__phone")} />
            <S1Table.Th label={translate("__email")} />

            <S1Table.Th label={translate("__active")} />
            <S1Table.Th label={translate("__employeeAccessLevel")} />
            <S1Table.Th label={""} />
            <S1Table.Th />
          </tr>
        </S1Table.THead>
        <S1Table.TBody>
          {employees.map((employee, index) => {
            const importUser = employee.importUserRaw;
            const userType = getUserType(employee);
            const errors = getErrors(employee);
            const status = getStatus(employee);
            return (
              <tr key={`tr-${index}-${importUser.employeeId}-${importUser.lastName}`}>
                <td title={employee.error ?? s1Date.format.tryFormatTime(employee.userCreateDateTime)}>
                  <FontAwesomeIcon icon={faCircle} className={`${status.className}`} />{" "}
                  <span className="ms-2">{status.text}</span>
                </td>
                <td>{errors.tooltip.map((t) => translate(t)).join(". ")}</td>

                <td className={userType.className}>{userType.text}</td>
                <td>{importUser.employeeId}</td>
                <td>{importUser.firstName}</td>
                <td>{importUser.lastName}</td>
                <td>{importUser.dateOfBirth}</td>
                <td>{importUser.phone}</td>
                <td>{importUser.email}</td>
                <td>{importUser.active}</td>
                <td>{importUser.benefitLevel}</td>
              </tr>
            );
          })}
        </S1Table.TBody>
      </S1Table.Table>
    </>
  );

  function getStatus(importUser: ImportUsersResponse): { text: string; className: string } {
    const response = { text: "", className: "" };

    response.text = importUser.error
      ? "Error"
      : importUser.userCreateDateTime
      ? "Success"
      : importUser.isValid
      ? "AlreadyIn"
      : "Invalid";
    response.className = importUser.error
      ? "text-danger"
      : importUser.userCreateDateTime
      ? "text-success"
      : importUser.isValid
      ? "text-info"
      : "text-warning";
    return response;
  }
  function getUserType(importUser: ImportUsersResponse): { text: string; className: string } {
    const response = {
      text: EnumHelper.toString({ value: importUser.type, enumObj: UserType }),
      className: importUser.type === UserType.Unknown ? "text-danger" : "",
    };
    return response;
  }

  function getErrors(importUser: ImportUsersResponse): { text?: string; tooltip: string[] } {
    const errors = importUser.validation;
    const errorStrings = [
      ...errors.firstName,
      ...errors.lastName,
      ...errors.employeeId,
      ...errors.dateOfBirth,
      ...errors.email,
      ...errors.phone,
      ...errors.active,
      ...errors.accessLevel,
    ];

    return { tooltip: errorStrings, text: errorStrings.length > 0 ? "Error" : "" };
  }
}

type State = {
  pageLoaded: boolean;
  client?: {
    id: string;
    name: string;
  };
  uploadedFile?: {
    fileName: string;
    fileUrl: string;
  };
  importUsers?: ImportUsersResponse[];
  uploading: boolean;
};

type ReducerAction =
  | { type: "start-file-upload"; value: {} }
  | { type: "set-client-info"; value: { clientId: string; clientName: string } }
  | { type: "file-uploaded"; value: { fileName: string; fileUrl: string } }
  | { type: "import-users"; value: { importUsers: ImportUsersResponse[] } }
  | { type: "set-uploading-progress"; value: { uploading: boolean } };

const initialState: State = { pageLoaded: false, uploading: false };

function stateReducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case "set-client-info":
      return {
        pageLoaded: true,
        client: {
          id: action.value.clientId,
          name: action.value.clientName,
        },
        uploading: false,
      };
    case "start-file-upload":
      return {
        ...state,
        uploading: true,
        uploadedFile: undefined,
      };
    case "file-uploaded":
      return {
        ...state,
        uploadedFile: { fileName: action.value.fileName, fileUrl: action.value.fileUrl },
      };

    case "import-users":
      return {
        ...state,
        importUsers: action.value.importUsers,
      };

    case "set-uploading-progress":
      return {
        ...state,
        uploading: action.value.uploading,
      };

    default:
      throw new Error("Unknown action");
  }
}
